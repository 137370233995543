import moment from "moment";
import { Button } from "@progress/kendo-react-buttons";
import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import BaseComponent from "../BaseComponent";
import FXCard from "../Common/FXCard/FXCard";
import FilterCombobox from "../Common/Form/FilterCombobox";
import { IWOAllocationCardProps } from "./interfaces";
import CardManagement from "./CardManagement";
import formStyles from "./card.module.scss";
import React from "react";
import { showSomeError } from "../../helpers/helpers";
import {
  PropsSQLTKGetAvailableWOsResponse,
  SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation,
  SQL_TK_GetAvailableWOs_Response,
} from "../../core/api/generated/conterra";
import api from "../../core/api/api";

interface state {
  sumPercentage: number;
  canSaveAllocation: boolean;
  invalidMessage: Array<string> | undefined;
  workOrders: SQL_TK_GetAvailableWOs_Response[];
  processing: boolean;
}

const MESSAGES = {
  invalidPercent: "The Total Percentage must be equal to 100%",
  invalidWOS: "Select Work Orders for all rows",
};

class WOAllocationCard extends BaseComponent<IWOAllocationCardProps, state> {
  allocation: SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation[] =
    JSON.parse(JSON.stringify(this.props.allocation));
  formValid: boolean = true;
  workOrders: SQL_TK_GetAvailableWOs_Response[] = [];

  constructor(props: IWOAllocationCardProps) {
    super(props);

    this.state = {
      sumPercentage: this.GetSumPercentage(),
      canSaveAllocation: true,
      invalidMessage: undefined,
      workOrders: [],
      processing: false,
    };
  }

  componentDidMount() {
    if (this.allocation.length === 0) this.AddAllocation();
    this.LoadData();
  }

  render() {
    let message = this.state.invalidMessage;

    return (
      <FXCard
        title={this.props.title || "Allocation"}
        onClose={this.Close}
        initialWidth={600}
        initialHeight={350}
        originalPaddings={true}
      >
        <div className={formStyles.FormWrapper}>
          <div style={{ flex: 1, overflow: "auto" }}>
            <div className={`${formStyles.Row} ${formStyles.HeadRow}`}>
              <div
                style={{
                  flex: 1,
                  paddingRight: 8,
                  alignItems: "center",
                }}
              >
                Work Order
              </div>
              <div
                style={{
                  width: "65px",
                  flex: "0 0 auto",
                  paddingLeft: 10,
                  boxSizing: "border-box",
                }}
              >
                <span>{this.state.sumPercentage}</span>
                <span> %</span>
              </div>
              <div style={{ width: 32, textAlign: "right" }}></div>
            </div>
            {this.allocation.map((item, i) => {
              const value =
                this.workOrders.find((wo) => wo.id === item.workOrderId) ||
                null;
              return (
                <div className={formStyles.Row} key={item.workOrderId || i}>
                  <div style={{ flex: 1, paddingRight: 8 }}>
                    <FilterCombobox
                      value={value}
                      placeholder="Work Order"
                      data={this.state.workOrders}
                      onChange={this.OnChangeCombobox}
                      className={formStyles.FormField}
                      dataAttr={i}
                      dataItemKey={"id"}
                      textField={"name"}
                      loading={this.state.processing}
                      groupMode="modern"
                      groupField={PropsSQLTKGetAvailableWOsResponse.groupName}
                    />
                  </div>
                  <div
                    style={{
                      width: "65px",
                      flex: "0 0 auto",
                    }}
                  >
                    <NumericTextBox
                      min={0}
                      max={100}
                      value={item.percentage}
                      className={formStyles.FormField}
                      onChange={(e: NumericTextBoxChangeEvent) => {
                        this.OnPercentageChange(e.value || 0, i);
                      }}
                    />
                  </div>
                  <div style={{ width: 32, textAlign: "right" }}>
                    <Button
                      icon="minus"
                      fillMode="flat"
                      title="Delete"
                      style={{ marginBottom: 5 }}
                      onClick={() => this.RemoveAllocation(i)}
                    />
                  </div>
                </div>
              );
            })}
            <div className={formStyles.Row} key={"newRow"}>
              <Button
                icon="plus"
                fillMode="flat"
                onClick={this.AddAllocation}
                style={{ marginLeft: "auto" }}
              />
            </div>
          </div>
          <div className={`${formStyles.FormFooter} k-action-buttons`}>
            <Button
              icon="plus"
              fillMode="flat"
              onClick={this.NewDispatch}
              style={{ marginRight: "auto" }}
            >
              New Dispatch
            </Button>
            <div style={{ flex: 1 }}>
              {!!message && (
                <span className={formStyles.InvalidMessage}>
                  {message.map((message, i) => {
                    return <span key={i}>{message}</span>;
                  })}
                </span>
              )}
            </div>
            <Button onClick={this.Close}>Cancel</Button>
            <Button
              onClick={this.SaveAllocation}
              themeColor="primary"
              disabled={!this.state.canSaveAllocation}
            >
              Save
            </Button>
          </div>
        </div>
      </FXCard>
    );
  }

  GetAvailableWorkOrders = () => {
    return this.workOrders.filter(
      (wo) =>
        this.allocation.findIndex(
          (allocation) => allocation.workOrderId === wo.id
        ) === -1
    );
  };

  ValidateAllocation = () => {
    let sum = this.GetSumPercentage();
    let allWOSFilled =
      this.allocation.findIndex((item) => !item.workOrderId) === -1;
    let message = [];
    if (sum !== 100 && this.allocation.length)
      message.push(MESSAGES.invalidPercent);
    if (!allWOSFilled) message.push(MESSAGES.invalidWOS);
    this.formValid = !message.length;
    this.setState({
      canSaveAllocation: !message.length,
      invalidMessage: message.length ? message : undefined,
      sumPercentage: sum,
      workOrders: this.GetAvailableWorkOrders(),
    });
  };

  GetSumPercentage = () => {
    return this.allocation.reduce((currentValue, item) => {
      return currentValue + item.percentage;
    }, 0);
  };

  OnPercentageChange = (value: number, i: number) => {
    let item = this.allocation[i];
    if (item) {
      item.percentage = value;
      if (this.allocation.length === 2) {
        let anotherItemIndex = i === 0 ? 1 : 0;
        let anotherItem = this.allocation[anotherItemIndex];
        anotherItem.percentage = 100 - value;
      }
      this.ValidateAllocation();
    }
  };

  OnChangeCombobox = (
    value: SQL_TK_GetAvailableWOs_Response | null,
    i: number
  ) => {
    const item = this.allocation[i];
    if (item) {
      item.workOrderId = value?.id || 0;
      item.workOrderName = value?.name || "";
      item.workOrderCode = value?.code || "";
      item.workOrderNumber = value?.number || "";
      this.ValidateAllocation();
    }
  };

  NewDispatch = () => {
    CardManagement.OpenDispatchCard({
      newDispatch: true,
      crewLead: {
        id: this.props.employeeId,
        name: this.props.employeeName,
      },
      date: moment(this.props.date).toDate(),
      afterSave: this.LoadData,
    });
  };

  SaveAllocation = async () => {
    try {
      await this.LoadData();
      this.props.onResult(this.allocation, this.workOrders);
      this.Close();
    } catch (e) {
      showSomeError(e);
    }
  };

  AddAllocation = () => {
    let workOrders = this.state.workOrders;
    let wo = workOrders.length === 1 ? workOrders[0] : undefined;
    let percentage =
      this.allocation.length === 0
        ? 100
        : this.allocation.length === 1
        ? 100 - this.allocation[0].percentage
        : 0;
    this.allocation.push({
      percentage: percentage,
      rowNumber: 0,
      workOrderCode: wo?.code || "",
      workOrderId: wo ? +wo.id : 0,
      workOrderName: wo?.name || "",
      workOrderNumber: wo?.number || "",
    });
    this.ValidateAllocation();
  };

  RemoveAllocation = (index: number) => {
    this.allocation.splice(index, 1);
    this.ValidateAllocation();
  };

  Close = () => {
    this.props.onClose();
    this.props.finally!();
  };

  LoadData = async () => {
    try {
      this.setState({ processing: true });
      const { tcId } = this.props;
      this.workOrders = await api.sql.tkGetAvailableWOs({ TCId: tcId });
      this.setState({ workOrders: this.GetAvailableWorkOrders() });
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ processing: false });
    }
  };
}

export default WOAllocationCard;
