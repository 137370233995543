import { CardsStackRef } from "../Common/FXCard/Cards";
import FXCard from "../Common/FXCard/FXCard";

import { IInitialTabSettings, tabId } from "../Tabs/interfaces";
import AddCustomerCard from "./AddCustomerCard";
import AddCustomerJobCard from "./AddCustomerJobCard";
import AddTCCard from "./AddTCCard";
import AddWOCard from "./AddWOCard";
import BPCard from "./BPCard";
import BPHistoryChartCard from "./BPHistoryChartCard";
import ChangeDocumentKindCard from "./ChangeDocumentKindCard";
import DispatchCard from "./DispatchCard";
import EasyBudgetCard from "./EasyBudgetCard";
import GBPCard from "./GBPCard/GBPCard";
import ClmReview from "./ModalClmReview";
import TCHistoryCard from "./ModalTCHistory";
import TCMapCard from "./ModalTCMap";
import RecordHistory from "./RecordHistory";
import SiteCard from "./SiteCard";
import UpdateBudgetProgressCard from "./UpdateBudgetProgressCard";
import UploadDocumentsCard from "./UploadDocumentsCard";
import WOAllocationCard from "./WoAllocationCard";
import {
  IAddCustomerCardProps,
  IAddCustomerJobCardProps,
  IAddTCCardProps,
  IAddWOChecklistCardProps,
  IFXCardProps,
  IGBPCardProps,
  IInboxCardProps,
  IOpenLocationCardProps,
  IOpenSiteCardProps,
  IOpenSolicitTKRequest,
  ITCCardWithoutContextProps,
  ITCReasonCardProps,
  IWOAllocationCardProps,
  StartCheckClosureCardProps,
} from "./interfaces";
import SolicitRequestCard from "./SoliciteRequestCard";
import MilestoneHistory from "./MilestoneHistory";
import LocationCard from "./LocationCard";
import TCReasonCard from "./TCReasonCard";
import PayrollCheckStatusCard from "./PayrollCheckStatusCard";
import { IComboboxItem, simpleObject } from "../../helpers/interfaces";
import TCRequestsActionsCard from "./TCRequestsActionsCard";
import StartCheckClosureCard from "./StartCheckClosureCard";
import ChangePasswordCard from "./ChangePasswordCard";
import InventoryBalance from "../../Pages/InventoryDashboard/InventoryBalance";
import CLMResourceCard from "./CLMResourceCard";
import { IResource } from "../../Pages/CLM/interfaces";
import AddWOChecklistCard from "./AddWOChecklistCard";
import QuickLaunchCard from "./QuickLaunchCard";
import InboxCard from "./InboxCard";
import TimeCardsComparisonCard from "./TimeCardsComparison";
import AssignMaterialItemsToBPCard from "./AssignMaterialItemsToBPCard";
import {
  SQL_Map_GetIncompleteWOSites_Response,
  SQL_TK_GetRequestAllocation_Response,
  SQL_TK_GetRequestData_Response,
} from "../../core/api/generated/conterra";
import AddBOMCard from "./AddBOMCard";
import BOMCard from "./BomCard";
import MaterialItemCard from "./MaterialItemCard";
import ChangeWHAreaCard from "./ChangeWHAreaForMIs";
import IncompleteWOMapFiltersCard from "./IncompleteWOMapFiltersCard";
import TCCardWithoutContext from "./TCCard/TCCardWithoutContext";
import AddMICard from "./AddMICard";

class CardManagement {
  NewWO(buildPlanId?: number, onFinish?: any) {
    CardsStackRef.showCard(AddWOCard, { buildPlanId, afterSave: onFinish });
  }

  OpenQuickLaunchCard = () => {
    CardsStackRef.showCard(QuickLaunchCard, {});
  };

  OpenDispatchCard(params: {
    newDispatch: boolean;
    buildPlanId?: number;
    woId?: number;
    dsId?: number;
    crewLead?: { id: number; name: string };
    date?: Date;
    afterSave?: any;
  }) {
    CardsStackRef.showCard(DispatchCard, params);
  }

  OpenInboxCard(params: IInboxCardProps) {
    CardsStackRef.showCard(InboxCard, params);
  }

  OpenTimeCardsComparisonCard(params: { ids: number[] }) {
    CardsStackRef.showCard(TimeCardsComparisonCard, params);
  }

  OpenWOChecklistCard(params: IAddWOChecklistCardProps) {
    CardsStackRef.showCard(AddWOChecklistCard, params);
  }

  OpenSolicitTKRequestCard(params: IOpenSolicitTKRequest) {
    CardsStackRef.showCard(SolicitRequestCard, params);
  }

  ShowMaterialBalance(materialId: number, materialName: string) {
    let balanceCard = function (props: any) {
      return (
        <FXCard title={"Balance for " + materialName} onClose={props.finally}>
          <InventoryBalance {...props} isModalCard={true} />
        </FXCard>
      );
    };
    CardsStackRef.showCard(balanceCard, { materialId, isActive: true });
  }

  OpenBPCard(
    buildPlanId: number,
    onFinish?: any,
    initialTab?: tabId | IInitialTabSettings
  ) {
    CardsStackRef.showCard(BPCard, {
      Id: buildPlanId,
      finally: onFinish,
      initialTab,
    });
  }

  OpenTCCard(props: ITCCardWithoutContextProps) {
    CardsStackRef.showCard(TCCardWithoutContext, props);
  }

  OpenClmReviewInterface(buildPlanId: number, workOrderId?: number) {
    CardsStackRef.showCard(ClmReview, {
      buildPlanId,
      workOrderId: workOrderId ? +workOrderId : undefined,
    });
  }

  OpenRecordHistoryCard(id: any, title?: string) {
    CardsStackRef.showCard(RecordHistory, {
      id,
      title,
    });
  }

  OpenMilestoneHistoryCard(bpid: number, msid: number, title?: string) {
    CardsStackRef.showCard(MilestoneHistory, {
      bpid,
      msid,
      title,
    });
  }

  OpenTCMapCard(tcId: number, refreshTC: () => void) {
    CardsStackRef.showCard(TCMapCard, {
      tcId,
      refreshTC,
    });
  }

  OpenTCHistoryCard(tcId: number) {
    CardsStackRef.showCard(TCHistoryCard, { tcId });
  }

  OpenResourceCard(record?: IResource, onFinish?: any) {
    CardsStackRef.showCard(CLMResourceCard, { record, finally: onFinish });
  }

  OpenBPHistoryChartCard(
    buildPlanId: number,
    buildPlanName: string,
    onFinish?: any
  ) {
    CardsStackRef.showCard(BPHistoryChartCard, {
      buildPlanId,
      buildPlanName,
      finally: onFinish,
    });
  }

  WOAllocationCard(cardProps: IWOAllocationCardProps) {
    CardsStackRef.showCard(WOAllocationCard, cardProps);
  }

  OpenStartCheckClosureCard(cardProps: StartCheckClosureCardProps) {
    CardsStackRef.showCard(StartCheckClosureCard, cardProps);
  }

  OpenGBPCard(cardProps?: IGBPCardProps) {
    CardsStackRef.showCard(GBPCard, cardProps || {});
  }

  OpenTCReasonCard(cardProps: ITCReasonCardProps) {
    CardsStackRef.showCard(TCReasonCard, cardProps);
  }

  OpenSiteCard(cardProps: IOpenSiteCardProps) {
    CardsStackRef.showCard(SiteCard, cardProps);
  }

  OpenLocationCard(cardProps: IOpenLocationCardProps) {
    CardsStackRef.showCard(LocationCard, cardProps);
  }

  OpenAddCustomerCard(cardProps: IAddCustomerCardProps) {
    CardsStackRef.showCard(AddCustomerCard, cardProps);
  }

  OpenAddCustomerJobCard(cardProps: IAddCustomerJobCardProps) {
    CardsStackRef.showCard(AddCustomerJobCard, cardProps);
  }

  OpenAddTCCard(cardProps: IAddTCCardProps) {
    CardsStackRef.showCard(AddTCCard, cardProps);
  }

  UploadDocumentsCard(
    isBuildPlanSourceObjectId: boolean,
    sourceId?: number,
    onFinish?: any,
    documentId?: number
  ) {
    CardsStackRef.showCard(UploadDocumentsCard, {
      sourceId,
      afterSave: onFinish,
      documentId,
      isBuildPlanSourceObjectId,
    });
  }

  OpenChangeKindCard(
    documentId: number,
    kindId: number | null,
    onFinish?: any
  ) {
    CardsStackRef.showCard(ChangeDocumentKindCard, {
      onFinish,
      documentId,
      kindId,
    });
  }

  OpenPayrollCheckStatusCard(
    rowData: simpleObject,
    refresh: () => void,
    onSetDate: (
      checkId: number,
      refreshCardFunc: (rowData: simpleObject) => void
    ) => void
  ) {
    CardsStackRef.showCard(PayrollCheckStatusCard, {
      rowData,
      refresh,
      onSetDate,
    });
  }

  OpenTKRequestsActionsCard(
    rowData: SQL_TK_GetRequestData_Response,
    allocation: SQL_TK_GetRequestAllocation_Response[],
    refresh: () => void
  ) {
    CardsStackRef.showCard(TCRequestsActionsCard, {
      rowData,
      allocation,
      refresh,
    });
  }

  UpdateBudgetProgressCard(
    buildPlanId: number,
    currentProgress: number,
    afterSave: () => void
  ) {
    CardsStackRef.showCard(UpdateBudgetProgressCard, {
      buildPlanId,
      currentProgress,
      afterSave,
    });
  }

  OpenAssignMaterialItemsToBPCard = (
    refreshGrid: () => void,
    bpId?: number
  ) => {
    CardsStackRef.showCard(AssignMaterialItemsToBPCard, {
      bpId,
      refreshGrid,
    });
  };

  OpenNewBOMCard = (afterCreate: () => void, bpId?: number) => {
    CardsStackRef.showCard(AddBOMCard, {
      afterCreate,
      bpId,
    });
  };

  OpenNewMICard = (afterCreate: () => void, buildPlanId?: number) => {
    CardsStackRef.showCard(AddMICard, {
      afterCreate,
      buildPlanId,
    });
  };

  OpenMaterialItemCard = (materialItemId: number) => {
    CardsStackRef.showCard(MaterialItemCard, {
      materialItemId,
    });
  };

  OpenChangeWHAreaForMIsCard = (
    materialItemsIds: number[],
    refreshGrid: () => void
  ) => {
    CardsStackRef.showCard(ChangeWHAreaCard, {
      materialItemsIds,
      refreshGrid,
    });
  };

  OpenBOMCard = (bomId: number, refreshGrid?: () => any) => {
    CardsStackRef.showCard(BOMCard, {
      bomId,
      refreshGrid,
    });
  };

  OpenIncompleteWOSitesFiltersCard = (
    sites: SQL_Map_GetIncompleteWOSites_Response[],
    filter: (filters: { [key: string]: IComboboxItem | null }) => void,
    filters?: { [key: string]: IComboboxItem | null }
  ) => {
    CardsStackRef.showCard(IncompleteWOMapFiltersCard, {
      sites,
      filter,
      filters,
    });
  };

  EasyBudgetCard(buildPlanId: number, afterSave: () => void) {
    CardsStackRef.showCard(EasyBudgetCard, {
      buildPlanId,
      afterSave,
    });
  }

  OpenChangePasswordCard = (props: IFXCardProps) => {
    CardsStackRef.showCard(ChangePasswordCard, props);
  };
}

export default new CardManagement();
