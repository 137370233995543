import { costTypeCode, IAdjustedTimeLineItem } from "./interfaces";
import moment from "moment";
import {
  formatHoursDuration,
  ResetSecondsDateString,
} from "../../../helpers/helpers";
import {
  SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation,
  SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentTimeLines,
  SQL_DB_TK_GetTCAdjustmentInfo_Response_TEAllocation,
  SQL_DB_TK_GetTCAdjustmentInfo_Response_TETimeLines,
} from "../../../core/api/generated/conterra";

export const costTypeToColor: {
    [key in costTypeCode]: string;
} = {
    // AUTHSTOP: '#FF86AB', // '#ff007f', // pink
    UNAUTHSTOP: "#e80000", // '#E5383B', // red

    LUNCH: "#FF8200", // orange
    WLUNCH: "#f7bb7d", // soft orange
    HRLYNW: "aquamarine",
    SNW: "aquamarine",
    ONSITE: "#08b100", // green
    OFFICE: "#6D00C4", // violet
    INTDRIVE: "#B4EB65", // yellowgreen
    DRIVE: "yellow", // yellow
    EMPTY: "#e0e0e0", // grey
    OTHER: "#2196f3", // $fxPrimary
    DST: "#ffe505", // beteween yellow and orange
};

export const SortByDate = (timeA: string, timeB: string) =>
    moment(timeA).isAfter(moment(timeB)) ? 1 : -1;

export const getDurationSeconds = (start: string, finish: string) => {
    const [, startTime] = start.split("T");
    const [, finishTime] = finish.split("T");
    const [startHoursStr, startMinutesStr] = startTime.split(":");
    const [finishHoursStr, finishMinutesStr] = finishTime.split(":");
    let diffHours = +finishHoursStr - +startHoursStr;
    let diffMinutes = +finishMinutesStr - +startMinutesStr;
    if (diffMinutes < 0) {
        diffHours -= 1;
        const finishMinutes = +finishMinutesStr + 60;
        diffMinutes = finishMinutes - +startMinutesStr;
    }
    return (diffHours * 3600) + (diffMinutes * 60)
};

export const GetPreparedAdjustedTimeline = (
    timeEntries:
        | SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentTimeLines[]
        | SQL_DB_TK_GetTCAdjustmentInfo_Response_TETimeLines[],
    allocation:
        | SQL_DB_TK_GetTCAdjustmentInfo_Response_TEAllocation[]
        | SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation[]
): {
    timeline: IAdjustedTimeLineItem[];
    totalClockedHours: number;
    totalApprovedHours: number;
} => {
    let totalClockedHours = 0;
    let totalApprovedHours = 0;
    const timeline: IAdjustedTimeLineItem[] = [];
    timeEntries.sort((itemA: any, itemB: any) =>
        SortByDate(itemA.Start, itemB.Start)
    );

    for (let i = 0; i < timeEntries.length; i++) {
        const te = timeEntries[i];
        const {rowNumber, costTypeCode, costTypeColor} = te;
        const isLunch = costTypeCode === "LUNCH";
        const approvedDuration = isLunch ? 0 : te.approvedDuration;
        const approvedDurationString = isLunch
            ? "00:00"
            : formatHoursDuration(approvedDuration);
        totalClockedHours += te.actualDuration;
        totalApprovedHours += approvedDuration;
        const start = ResetSecondsDateString(te.start);
        const finish = ResetSecondsDateString(te.finish);

        timeline.push({
            ...te,
            start,
            finish,
            approvedDuration,
            approvedDurationString,
            color: costTypeColor || costTypeToColor.EMPTY,
            sortNumber: i + 1,
            actualDurationString: formatHoursDuration(te.actualDuration),
            startFormatted: moment(start).format("LT"),
            finishFormatted: moment(finish).format("LT"),
            manualAllocation: allocation.filter(
                (detail) => detail.rowNumber === rowNumber
            ),
        });
    }

    return {
        timeline,
        totalClockedHours,
        totalApprovedHours,
    };
};
