import stylesDashboard from "../Components/Dashboard/dashboard.module.scss";
import { tabId } from "../Components/Tabs/interfaces";
import TCTabsNew from "../Components/TabsNew/TCTabsNew";
import React from "react";
import { useRefresher } from "../core/tools/Hooks";

interface props {
  tcId: number;
  initialTab?: tabId;
}

const TCTabsPage = ({ tcId, initialTab }: props) => {
  const refresh = useRefresher();
  return (
    <div
      className={stylesDashboard.Container}
      style={{
        boxSizing: "border-box",
        paddingBottom: 0,
      }}
    >
      <TCTabsNew
        timeCardId={tcId}
        initialTab={initialTab}
        refreshTC={refresh}
        reloadDependency={refresh.value}
      />
    </div>
  );
};

export default TCTabsPage;
