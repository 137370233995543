import React, { useCallback, useEffect, useRef } from "react";
import DashboardGrid from "../../Components/Dashboard/Grid";
import { IColumnSetting } from "../Dashboard/interfaces";
import { simpleObject } from "../../helpers/interfaces";
import { getSQLData } from "../../helpers/queries";
import { TRenderToolbar } from "../TabsNew/interfaces";
import { useLatestNotEmptyValue } from "../../core/tools/Hooks";

interface IProps {
  tcId?: number;
  isActive: boolean;
  pageId?: string;
  renderToolbar?: TRenderToolbar;
  reloadDependency?: unknown;
  refreshTC?: () => void;
}

const TCHistory = (props: IProps) => {
  const { tcId, isActive, reloadDependency, renderToolbar } = props;
  const currentTcId = useLatestNotEmptyValue(tcId);
  const gridRefreshRef = useRef<() => void>(() => {});
  const columnsRef = useRef<IColumnSetting[]>([
    {
      field: "Date",
      title: "Date",
      type: "date",
      gridWidth: 65,
      format: {
        type: "datetime",
      },
    },
    {
      field: "Employee",
      title: "User Name",
      type: "string",
      gridWidth: 90,
      filterable: false,
      format: {
        fieldId: "EmployeeId",
        type: "link",
        refName: "Employees",
      },
    },
    {
      field: "Event",
      title: "Event",
      type: "string",
      gridWidth: 80,
      format: {
        type: "string",
        fieldId: "Event",
      },
    },
    {
      field: "Comment",
      title: "Comment",
      columnMenu: false,
      type: "string",
      format: {
        type: "string",
        customFormat: true,
        getCustomFormat: (dataItem) => {
          return (
            <div style={{ whiteSpace: "pre-line" }}>{dataItem.Comment}</div>
          );
        },
      },
    },
    {
      field: "Description",
      title: "Description",
      columnMenu: false,
      type: "string",
      format: {
        type: "string",
      },
    },
    {
      field: "Source",
      title: "Source",
      type: "string",
      gridWidth: 100,
      columnMenu: false,
    },
  ]);

  const loadData = useCallback(
    async (filters: simpleObject) => {
      const result = await getSQLData({
        spName: "TK_GetTimeCardHistory",
        params: { TCId: currentTcId, ...filters },
      });
      return result[0];
    },
    [currentTcId]
  );
  const lastAppliedDependency = useRef<unknown>(reloadDependency);
  useEffect(() => {
    if (isActive && lastAppliedDependency.current !== reloadDependency) {
      lastAppliedDependency.current = reloadDependency;
      gridRefreshRef.current?.();
    }
  }, [reloadDependency, isActive]);

  const initRefresh = useCallback((refresh: any) => {
    gridRefreshRef.current = refresh;
  }, []);

  const getRowKey = useCallback(
    (dataItem: simpleObject) => dataItem.RowNum,
    []
  );

  if (!currentTcId) return null;

  return (
    <DashboardGrid
      isActive={isActive}
      columnsSettings={columnsRef.current}
      getRowKey={getRowKey}
      getData={loadData}
      initRefresh={initRefresh}
      excelFileName={"TCHistory(" + tcId + ").xlsx"}
      toolbar={{
        renderToolbar,
      }}
      columnMenu={{
        filters: true,
        columns: false,
      }}
    />
  );
};

export default TCHistory;
