import {
  PropsSQLDBCalendarResourcesResponseResources,
  PropsSQLDBCalendarWOCertificateInfoResponse,
  SQL_DB_Calendar_WOCertificateInfo_Response,
} from "../../../core/api/generated/conterra";
import React, { useCallback, useMemo, useState } from "react";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDetailRowProps,
  GridRowProps,
} from "@progress/kendo-react-grid";
import styles from "./dispatchcard.module.scss";
import { IResourceItem } from "./interfaces";

const WOCertificatesTable = (props: {
  certificates: SQL_DB_Calendar_WOCertificateInfo_Response[];
  crew: IResourceItem[];
  crewLead?: IResourceItem;
}) => {
  const { certificates, crew, crewLead } = props;
  const [collapsedState, setCollapsedState] = useState<number[]>([]);

  const gridData = useMemo(() => {
    if (!certificates.length) return null;
    return certificates.map((x) => {
      const resources: { id: number; name: string }[] = [];
      const hasCertificate = (resource: IResourceItem, certId: number) => {
        const hascert =
          resource.employeeCertificates.findIndex(
            (r) => r.certificateId === certId
          ) > -1;
        if (hascert)
          resources.push({
            id: resource.id,
            name: resource.name,
          });
      };
      if (crewLead) hasCertificate(crewLead, x.certificateId);
      crew.forEach((r) => {
        hasCertificate(r, x.certificateId);
      });
      return {
        ...x,
        expanded: !collapsedState.includes(x.certificateId),
        resources,
      };
    });
  }, [certificates, crew, crewLead, collapsedState]);

  const renderDetails = useCallback((detailsGridProps: GridDetailRowProps) => {
    const resources = detailsGridProps.dataItem.resources;
    if (!resources.length) return null;
    return (
      <ul className={styles.ResoursesList}>
        {resources.map((r: { id: number; name: string }) => (
          <li className={styles.ResoursesListRow} key={r.id}>
            {r.name}
          </li>
        ))}
      </ul>
    );
  }, []);

  const renderGridRow = useCallback(
    (
      row: React.ReactElement<
        HTMLTableRowElement,
        string | React.JSXElementConstructor<any>
      >,
      gridRowProps: GridRowProps
    ) => {
      const dataItem = gridRowProps.dataItem;
      const { children } = gridRowProps;
      const additionalClassName = !dataItem.resources?.length
        ? styles.NotShowBtn
        : "";
      return React.cloneElement(
        row,
        {
          ...row.props,
          className: `${row.props.className} ${additionalClassName}`,
        },
        children
      );
    },
    []
  );

  if (!gridData) return null;
  return (
    <Grid
      className={``}
      style={{ marginTop: 12 }}
      data={gridData}
      // scrollable={"none"}
      filterable={false}
      rowHeight={30}
      detail={renderDetails}
      expandField="expanded"
      scrollable={"scrollable"}
      onExpandChange={(event) => {
        const certificateId = event.dataItem.certificateId;
        const collapsed = !event.value;
        setCollapsedState((state) => {
          if (!collapsed) return state.filter((x) => x !== certificateId);
          else return [...state, certificateId];
        });
      }}
      dataItemKey={PropsSQLDBCalendarResourcesResponseResources.id}
      rowRender={renderGridRow}
    >
      <GridColumn
        title={"Certificate Type"}
        field={PropsSQLDBCalendarWOCertificateInfoResponse.certificateName}
      />
      <GridColumn
        title={"Min. of Crew Req"}
        className={styles.NumberColumn}
        field={PropsSQLDBCalendarWOCertificateInfoResponse.minOfCrewReq}
        width={120}
      />
      <GridColumn
        title={"Current Crew have Cert"}
        field={"resources"}
        width={140}
        className={styles.NumberColumn}
        cell={(props: GridCellProps) => {
          const { minOfCrewReq, resources } = props.dataItem;
          const isRed = minOfCrewReq > resources.length;
          return (
            <td
              className={props.className}
              colSpan={1}
              style={
                isRed
                  ? {
                      color: "red",
                      fontWeight: 600,
                      background: "rgba(255, 0, 0, 0.1)",
                    }
                  : undefined
              }
            >
              {resources.length}
            </td>
          );
        }}
      />
    </Grid>
  );
};

export default WOCertificatesTable;
