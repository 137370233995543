import { Card } from "@progress/kendo-react-layout";
import {
  clockOutType,
  IActivity,
  IConnector,
  IMapPeriodItem,
  IRelatedLocation,
  ISiteToMap,
  ITCInfo,
  ITE,
  ITimeCard,
  ITimecardSite,
  ITimeLineRowRenderItem,
  ITrack,
  IWO,
  stopType,
  teEvent,
} from "./interfaces";
import styles from "./livemap.module.scss";
import moment from "moment";

import React, { useEffect, useRef, useState } from "react";
import { getSQLData } from "../../helpers/queries";
import WoRow from "./WORow";
import { formatHoursDuration, showSomeError } from "../../helpers/helpers";
import TERow from "./TERow";
import { getPeriodIdForFilter } from "./helpers";
import Loader from "../../Components/Common/Loader";
import {
  ITotalDurationProps,
  TStateData,
  TStateItem,
} from "../../Components/TC/Map/interfaces";
import {
  costTypeToColor,
  getDurationSeconds,
} from "../../Components/TC/Map/helpers";
import { Button } from "@progress/kendo-react-buttons";
import debounce from "lodash.debounce";
import PinToggle from "./PinToggle";
import WOsListContainer from "./WoList";

import StateCodes from "./StateCodes";
import TotalDurations from "../../Components/TC/TotalDurations";
import { useBooleanState } from "../../core/tools/Hooks";
import EmptyRowWithPeriodToggle from "./EmptyRowWithPeriodToggle";
import { DetailsActivityControl } from "../../Components/Map/DetailsActivityControl";
import Mileages from "./Mileages/Mileages";
import { IDrawMileage, ITCMileage } from "./Mileages/interfaces";
import { formatTimeDiff } from "../../core/tools/formats";

const teEventToStopType: {
  [key in teEvent]: stopType | "IN" | "OUT" | "LUNCH";
} = {
  STOP: "S",
  DRIVE: "D",
  CLOCKIN: "IN",
  CLOCKOUT: "OUT",
  "FORCE CLOCKOUT": "OUT",
  "REMOTE CLOCKOUT": "OUT",
  "NO GPS": "N",
  LUNCH: "LUNCH",
  NA: "N",
};

interface IProps {
  map: any;
  isTCMap: boolean;
  refreshKey: number;
  ShowStateAllocation: boolean;
  data: ITimeCard;
  isPinned: boolean;
  pinnedColor: string | null;
  isSelected: boolean;
  selectItem: (id: number) => void;
  unSelectItem: (id: number) => void;
  clearListIdFromMap: (listId: number) => void;
  draw: (
    listId: number,
    periods: IMapPeriodItem[],
    connectors: IConnector[],
    trackSites: ISiteToMap[]
  ) => void;
  onTogglePin: (listId: number) => void;
  switchItem: (listId: number) => void;
  switchPeriod: (
    filteredPeriods: Set<string> | null,
    listId: number,
    clockInId: number
  ) => void;
  onTrackItemMouseEvent: (
    e: React.MouseEvent<HTMLDivElement>,
    filteredPeriods: Set<string> | null,
    clockInId: number
  ) => void;
  onDispatchMouseEvent: (e: React.MouseEvent<HTMLSpanElement>) => void;
  onLoadDrawTrack?: (listId: number, clockInId: number) => void;
}

interface IDetail {
  clockInId: number;
  timeEntries: ITimeLineRowRenderItem[];
}

function TimeCard(props: IProps) {
  const [, forceUpdate] = useState(0);
  const {
    isSelected,
    data,
    refreshKey,
    isTCMap,
    isPinned,
    pinnedColor,
    ShowStateAllocation,
    clearListIdFromMap,
  } = props;
  const isLoading = useBooleanState(false);
  const [details, setDetails] = useState<IDetail[] | null>(null);
  const [originalTimeLineTotals, setOriginalTimeLineTotals] =
    useState<ITotalDurationProps>();
  const [stateCodes, setStateCodes] = useState<TStateData | null>(null);
  const [workOrders, setWorkOrders] = useState<IWO[]>([]);
  const [drawMileages, setDrawMileages] = useState<IDrawMileage[]>([]);
  const [waivedLunches, setWaivedLunches] = useState<string[]>([]);
  const tcInfoRef = useRef<ITCInfo | null>(null);
  const filteredPeriodsRef = useRef<{
    [clockInId: number]: Set<string> | null;
  }>({});
  const detailsActivityRef = useRef<IActivity[] | null>(null);
  const sliderControlRef = useRef(DetailsActivityControl({ map: props.map }));

  useEffect(() => {
    if (!isSelected && !details) return;
    if (isSelected && !details) {
      loadDetails();
    } else if (details) {
      toggleSlider(isSelected);
      props.switchItem(data.ListId);
    }
  }, [isSelected]);

  useEffect(() => {
    if (!isPinned && !details) return;
    if (isPinned && !details) {
      loadDetails();
    } else if (details) {
      toggleSlider(isPinned);
      props.switchItem(data.ListId);
    }
  }, [isPinned]);

  useEffect(() => {
    // external refresh from TK Dashboard
    if (props.isTCMap) {
      clearListIdFromMap(data.ListId);
      toggleSlider(false);
      loadDetails();
    }
  }, [refreshKey]);

  const toggleSlider = (show: boolean) => {
    if (show) {
      const points = detailsActivityRef.current;
      if (!points?.length) return;
      sliderControlRef.current.initSlider(points, tcInfoRef.current!.Employee);
    } else {
      sliderControlRef.current.destroySlider();
    }
  };

  const loadDetails = () => {
    const TCID = props.data.TimeCardId;
    if (!TCID) return;
    isLoading.setTrue();
    getSQLData({
      spName: "TK_GetTCMapData",
      params: { TCID },
    })
      .then((result) => {
        const info = result[0][0] as ITCInfo;
        tcInfoRef.current = info;
        const timeEntries = result[1] as ITE[];
        const detailsActivity = result[2] as IActivity[];
        const visitedSites = result[3] as IRelatedLocation[];
        const workOrders = result[4] as IWO[];
        const tcSites = result[5] as ITimecardSite[];
        const mileages = result[6] as ITCMileage[];

        const details: IDetail[] = [];
        const mapPeriods: IMapPeriodItem[] = [];
        const roadMapStates: TStateData = [];
        let newStateRow: TStateItem | null = null;
        let stopNumber = 1;
        const clockIns: number[] = [];
        let lastPrevTEPoint: ITE | undefined = undefined; // for connectors
        let lastPrevTrack: ITrack[] | undefined = undefined; // for connectors
        const connectors: IConnector[] = [];

        const waivedLunches: string[] = [];
        for (let i = 0; i < timeEntries.length; i++) {
          const te = timeEntries[i];
          const Type = teEventToStopType[te.Event];
          const isClockOut = Type === "OUT";
          const isClockIn = Type === "IN";
          const isClockInOut = isClockIn || isClockOut;
          const isLunch = te.Event === "LUNCH";
          const isWaivedLunch = isLunch && te.Finish === te.Start;
          if (isWaivedLunch) {
            waivedLunches.push(moment(te.Start).format("LT"));
            continue;
          }

          if (ShowStateAllocation) {
            if (
              newStateRow &&
              (isClockInOut || te.StateCode !== newStateRow.stateCode)
            ) {
              roadMapStates.push(newStateRow);
              newStateRow = null;
            }
            if (isClockInOut) {
              roadMapStates.push({
                heightMultiplier: 1,
                isClockFlag: true,
              });
            } else {
              if (!newStateRow) {
                newStateRow = {
                  heightMultiplier: 0,
                  stateCode: te.StateCode,
                };
              }
              newStateRow.heightMultiplier += 1;
            }
          }
          if (isClockIn || i === 0) {
            const clockInId = te.Id;
            clockIns.push(clockInId);
            details.push({
              clockInId,
              timeEntries: [],
            });
            filteredPeriodsRef.current[clockInId] = new Set([]);
          }
          const clockInIndex = clockIns.length - 1;
          const ClockInId = clockIns[clockInIndex];
          const detail = details[clockInIndex];

          const SiteName = !isClockInOut
            ? te.SiteTitle || te.LocationTitle || "Unknown"
            : undefined;
          const Number = Type === "S" ? stopNumber++ : undefined;
          const momentStart = moment(te.Start);
          const Start: string | undefined = momentStart.format("LT");
          const momentFinish = moment(te.Finish);
          const Finish = momentFinish.format("LT");
          const Duration = !isClockInOut
            ? formatTimeDiff(getDurationSeconds(te.Start, te.Finish))
            : undefined;

          const next = timeEntries[i + 1];
          const nextType = next ? teEventToStopType[next.Event] : "";
          const isNextClockOut = nextType === "OUT";
          const CostTypeColor = isClockInOut
            ? ""
            : te.CostTypeColor || (isWaivedLunch ? costTypeToColor.WLUNCH : "");

          const CenterCoords =
            (isClockInOut || Type === "S") && te.Lat && te.Lng
              ? [te.Lat, te.Lng]
              : null;
          const Track: ITrack[] = detailsActivity
            .filter((d) => d.TEId === te.Id)
            .map((d) => ({
              coords: [d.Lat, d.Lng],
              time: d.T,
            }));
          const prevMapPeriod = mapPeriods[mapPeriods.length - 1];
          if (
            Type === "S" &&
            prevMapPeriod?.Type === "D" &&
            lastPrevTrack?.length
          ) {
            // for connection details stop track and drive
            Track.unshift(lastPrevTrack[lastPrevTrack.length - 1]);
          }
          detail.timeEntries.push({
            Id: te.Id,
            ClockInId,
            Type,
            Start,
            Finish,
            SiteName,
            Duration,
            IsUnscheduledSite:
              !info.IsHideUnscheduledVisitAlert && te.IsUnscheduledVisit,
            Number,
            ClockOutType: isClockOut ? (te.Event as clockOutType) : undefined,
            CostTypeColor,
            IsApprovedHours: !!te.ApprovedDuration,
            ApprovedDurationString:
              te.ApprovedDuration !== null
                ? formatHoursDuration(te.ApprovedDuration)
                : "",
            ActualDurationString:
              te.ActualDuration !== null
                ? formatHoursDuration(te.ActualDuration)
                : "",
            MainObjectId: te.LocationId || te.SiteId,
            TCTE: te,
            HasCoords: !!CenterCoords || !!Track.length,
            IsLast: !isClockOut
              ? timeEntries.length - 1 === i || (!isClockIn && isNextClockOut)
              : false,
          });

          if (Track.length || CenterCoords) {
            mapPeriods.push({
              Id: te.Id,
              Type,
              Time: isClockInOut ? Start : `${Start} - ${Finish}`,
              ClockOutType: isClockOut ? (te.Event as clockOutType) : undefined,
              CenterCoords,
              Number,
              Track,
              ClockInId,
              CostTypeColor,
              MainObjectId: te.LocationId || te.SiteId,
            });
          }
          // for connectors
          if (
            (lastPrevTEPoint?.Event === "DRIVE" &&
              lastPrevTrack?.length &&
              te.Event === "STOP") ||
            (lastPrevTEPoint?.Event === "STOP" &&
              te.Event === "DRIVE" &&
              Track.length)
          ) {
            const coords: number[][] = [];
            if (lastPrevTEPoint?.Event === "STOP") {
              coords.push([lastPrevTEPoint.Lat, lastPrevTEPoint.Lng]);
            } else {
              coords.push(lastPrevTrack![lastPrevTrack!.length - 1].coords);
            }

            if (te.Event === "STOP") {
              coords.push([te.Lat, te.Lng]);
            } else {
              coords.push(Track[0].coords);
            }
            connectors.push({
              coords,
              startId: lastPrevTEPoint.Id,
              finishId: te.Id,
              clockInId: ClockInId,
              // color,
              dashed: true,
            });
          }
          lastPrevTEPoint = isClockInOut ? undefined : te;
          lastPrevTrack = lastPrevTEPoint ? [...Track] : undefined;
          const currentMapPeriod = mapPeriods[mapPeriods.length - 1];
          if (
            currentMapPeriod?.Type === "D" &&
            prevMapPeriod?.Type === "S" &&
            Track.length
          ) {
            // for connection details stop track and drive
            prevMapPeriod.Track.push(Track[0]);
          }
        }
        setOriginalTimeLineTotals({
          clockedHours: info.ClockedHours,
          approvedHours: info.ApprovedHours,
          lunchHours: info.AuditedLunch || 0,
          totalHours: info.TotalHours,
          totalMileage: info.TotalMileage,
          isOriginal: true,
        });
        if (ShowStateAllocation && newStateRow) {
          roadMapStates.push(newStateRow);
        }
        if (ShowStateAllocation) setStateCodes(roadMapStates);

        const flatTimeEntries: ITimeLineRowRenderItem[] = [];
        for (let item of details) {
          flatTimeEntries.push(...item.timeEntries);
        }

        const drawMileages: IDrawMileage[] = [];
        let lastFinishItemIndex = 0;
        for (let i = 0; i < mileages.length; i++) {
          const ME = mileages[i];
          const startItemIndex = flatTimeEntries.findIndex(
            (te) => ME.StartTEId === te.Id
          );
          const finishItemIndex = flatTimeEntries.findIndex(
            (te) => ME.FinishTEId === te.Id
          );
          const finishItem =
            finishItemIndex > -1 && flatTimeEntries[finishItemIndex];
          const startItem =
            startItemIndex > -1 && flatTimeEntries[startItemIndex];
          if (!startItem || !finishItem) {
            continue;
          }
          if (startItemIndex > lastFinishItemIndex) {
            let emptyRows = 0;
            flatTimeEntries.forEach((orTE, index) => {
              if (
                (i === 0 &&
                  index >= lastFinishItemIndex &&
                  index < startItemIndex) ||
                (index > lastFinishItemIndex && index < startItemIndex)
              ) {
                emptyRows += 1; //?? 1
              }
            });
            drawMileages.push({
              // empty
              StartRows: 0,
              FinishRows: 0,
              CenterRows: emptyRows,
            });
          }

          let centerRows = 0;
          flatTimeEntries.forEach((orTE, index) => {
            if (index > startItemIndex && index < finishItemIndex) {
              centerRows += 1; // ?? 1;
            }
          });

          drawMileages.push({
            Start: startItem.Start,
            Finish: finishItem.Finish,
            StartRows: 1, //startItem.rows,
            FinishRows: 1, //finishItem.rows,
            CenterRows: centerRows,
            me: ME,
          });

          lastFinishItemIndex = finishItemIndex;
        }

        const uniqueSites: { [key: number]: ISiteToMap } = {};
        const sitesToMap: ISiteToMap[] = [];
        for (let site of visitedSites) {
          const IsMainStopObject =
            mapPeriods.findIndex((period) => period.MainObjectId === site.Id) >
            -1;
          const uniqueSite = uniqueSites[site.Id];
          if (uniqueSite) {
            if (!uniqueSite.PeriodsInfo.IsMainStopObject && IsMainStopObject)
              uniqueSite.PeriodsInfo.IsMainStopObject = IsMainStopObject;
            continue;
          }

          const {
            Address,
            Lat,
            Lng,
            Boundaries,
            Radius,
            Id,
            Name,
            Type,
            LocationAbbr,
            LocationColor,
            IsScheduledSite,
            IsUnscheduledSite,
            TEId,
          } = site;
          const newUniqueSite: ISiteToMap = {
            Address,
            Boundaries,
            Lat,
            Lng,
            LocationAbbr,
            LocationColor,
            ObjectId: Id,
            ObjectName: Name,
            ObjectType: Type === "Location" ? "Location" : "Site",
            Radius,
            PeriodsInfo: {
              IsMainStopObject,
              PeriodId: TEId,
            },
            ScheduledInfo: {
              IsScheduledSite,
              IsUnscheduledSite,
              IsRelatedScheduledSite: null,
              DispatchIds: [],
            },
          };
          uniqueSites[Id] = newUniqueSite;
          sitesToMap.push(newUniqueSite);
        }

        for (let workOrder of workOrders) {
          const sites = tcSites.filter(
            ({ MainSiteId }) => MainSiteId === workOrder.SiteId
          );
          for (let site of sites) {
            const {
              MainSiteId,
              Name,
              Lat,
              Lng,
              Radius,
              Boundaries,
              SiteId,
              Address,
            } = site;
            const uniqueSite = uniqueSites[SiteId];
            const IsRelatedScheduledSite = MainSiteId !== SiteId;
            if (uniqueSite) {
              uniqueSite.ScheduledInfo.IsScheduledSite = true;
              if (
                uniqueSite.ScheduledInfo.IsRelatedScheduledSite === true &&
                !IsRelatedScheduledSite
              ) {
                uniqueSite.ScheduledInfo.IsRelatedScheduledSite =
                  IsRelatedScheduledSite;
              }
              uniqueSite.ScheduledInfo.DispatchIds.push(workOrder.DispatchId);
              continue;
            }

            const newUniqueSite: ISiteToMap = {
              Address,
              Boundaries,
              Lat,
              Lng,
              LocationAbbr: null,
              LocationColor: null,
              ObjectId: SiteId,
              ObjectName: Name,
              ObjectType: "Site",
              Radius,
              PeriodsInfo: {
                IsMainStopObject: false,
              },
              ScheduledInfo: {
                IsScheduledSite: true,
                IsUnscheduledSite: false,
                IsRelatedScheduledSite,
                DispatchIds: [workOrder.DispatchId], // or workOrder.WorkOrderId ??
              },
            };
            uniqueSites[SiteId] = newUniqueSite;
            sitesToMap.push(newUniqueSite);
          }
        }
        // const mileageEntries = result[6] as IMileage[];
        detailsActivityRef.current = detailsActivity;
        setWaivedLunches(waivedLunches);
        setDrawMileages(drawMileages);
        setDetails(details);
        setWorkOrders(workOrders);
        toggleSlider(true);
        props.clearListIdFromMap(data.ListId);
        props.draw(data.ListId, mapPeriods, connectors, sitesToMap);
        props.onLoadDrawTrack?.(data.ListId, 0);
      })
      .catch(showSomeError)
      .finally(() => {
        isLoading.setFalse();
      });
  };

  const onClick = () => {
    if (isSelected) props.unSelectItem(data.ListId);
    else props.selectItem(data.ListId);
  };

  const onTogglePin = debounce(() => {
    props.onTogglePin(data.ListId);
  }, 100);

  const onToggleClockIn = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const { clockinid } = e.currentTarget.dataset;
    if (!clockinid) return;
    if (filteredPeriodsRef.current[+clockinid]) {
      filteredPeriodsRef.current[+clockinid] = null;
    } else {
      filteredPeriodsRef.current[+clockinid] = new Set([]);
    }
    forceUpdate(+new Date());
    props.switchPeriod(
      filteredPeriodsRef.current[+clockinid],
      data.ListId,
      +clockinid
    );
  };

  const onToggleTE = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const { clockinid, periodid } = e.currentTarget.dataset;
    if (!clockinid || !periodid) return;
    const filteredPeriods = filteredPeriodsRef.current[+clockinid];
    if (filteredPeriods === null) return;
    const str = getPeriodIdForFilter(periodid, +clockinid);
    const newValue = filteredPeriods.size === 0 || !filteredPeriods.has(str);
    if (newValue) {
      filteredPeriods.add(str);
    } else {
      filteredPeriods.delete(str);
    }
    forceUpdate(+new Date());
    props.switchPeriod(filteredPeriods, data.ListId, +clockinid);
  };

  const onTEMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const dataSet = e.currentTarget?.dataset || {};
    const { clockinid } = dataSet;
    if (!clockinid) return;
    props.onTrackItemMouseEvent(
      e,
      filteredPeriodsRef.current[+clockinid],
      +clockinid
    );
  };

  const doShowDetails = () => isTCMap || isSelected || isPinned;

  const renderDetails = () => {
    if (!doShowDetails() || !details) return null;

    return (
      <div
        className={`${styles.TimeCardDetails} ${
          isTCMap ? styles.TCMapTimeCardDetails : ""
        }`}
      >
        {isTCMap && !!originalTimeLineTotals && (
          <>
            <div style={{ height: 16 }}></div>
            <TotalDurations values={originalTimeLineTotals} />
            <WaivedLunchesComponent waivedLunches={waivedLunches} />
          </>
        )}
        <div style={{ display: "flex", flex: 1, overflow: "auto" }}>
          <div
            className={styles.TimeCardAllPeriods}
            style={ShowStateAllocation ? { paddingLeft: 20 } : undefined}
          >
            {ShowStateAllocation && !!stateCodes && (
              <StateCodes data={stateCodes} />
            )}
            {details.map((detail) => {
              return (
                <div key={detail.clockInId} className={styles.DevicePeriods}>
                  {!!detail.timeEntries.length &&
                    detail.timeEntries.map((te, index, array) => {
                      const filteredPeriods =
                        filteredPeriodsRef.current[te.ClockInId];
                      let action: any = "";
                      const isClockOut = te.Type === "OUT";
                      const isClockIn = te.Type === "IN";
                      const isClockInOut = isClockIn || isClockOut;
                      let clockInOutText = "";
                      const hasOtherTEs = isClockInOut
                        ? array.findIndex(
                            ({ Type }) => Type !== "IN" && Type !== "OUT"
                          ) > -1
                        : false;

                      if (isClockInOut) {
                        if (!hasOtherTEs) {
                          clockInOutText = te.Start;
                          if (isClockOut)
                            clockInOutText += ` ${
                              (te.TCTE?.Event !== "CLOCKOUT" &&
                                te.TCTE?.Event) ||
                              ""
                            }`;
                        }
                      }
                      const hasTEsWithCoords =
                        te.HasCoords ||
                        array.findIndex(({ HasCoords }) => HasCoords) > -1;
                      if (isClockIn) {
                        action = hasTEsWithCoords ? (
                          <Button
                            iconClass={
                              filteredPeriods
                                ? "mdi mdi-toggle-switch"
                                : "mdi mdi-toggle-switch-off"
                            }
                            onClick={onToggleClockIn}
                            rounded={"full"}
                            fillMode={"flat"}
                            themeColor={"light"}
                            size={"large"}
                            className={`${styles.DevicePeriodSwitcher} ${styles.On}`}
                            data-clockinid={te.ClockInId}
                          ></Button>
                        ) : undefined;
                      } else if (
                        !isClockOut &&
                        filteredPeriods &&
                        te.HasCoords
                      ) {
                        const str = getPeriodIdForFilter(te.Id, te.ClockInId);
                        const hasFiltered = filteredPeriods.size > 0;
                        const isOn = !hasFiltered || filteredPeriods.has(str);
                        action = (
                          <Button
                            iconClass={
                              isOn
                                ? "mdi mdi-toggle-switch-outline"
                                : "mdi mdi-toggle-switch-off-outline"
                            }
                            onClick={onToggleTE}
                            rounded={"full"}
                            fillMode={"flat"}
                            themeColor={
                              hasFiltered && isOn ? "primary" : "light"
                            }
                            size={"large"}
                            className={`${styles.DevicePeriodSwitcher} ${
                              hasFiltered && isOn ? styles.On : ""
                            }`}
                            data-clockinid={te.ClockInId}
                            data-periodid={te.Id}
                          ></Button>
                        );
                      }
                      const drawEmptyLineWithToggle = index === 0 && !isClockIn;
                      return (
                        <React.Fragment key={te.Id}>
                          {drawEmptyLineWithToggle && (
                            <EmptyRowWithPeriodToggle
                              key={`${te.ClockInId}toggle`}
                              action={
                                <Button
                                  iconClass={
                                    filteredPeriods
                                      ? "mdi mdi-toggle-switch"
                                      : "mdi mdi-toggle-switch-off"
                                  }
                                  onClick={onToggleClockIn}
                                  rounded={"full"}
                                  fillMode={"flat"}
                                  themeColor={"light"}
                                  size={"large"}
                                  className={`${styles.DevicePeriodSwitcher} ${styles.On}`}
                                  data-clockinid={te.ClockInId}
                                ></Button>
                              }
                              listId={data.ListId}
                              clockInId={te.ClockInId}
                            />
                          )}

                          <TERow
                            key={te.Id}
                            start={
                              !isClockInOut || (te.IsLast && hasTEsWithCoords)
                                ? te.Start
                                : undefined
                            }
                            finish={
                              !isClockInOut && te.IsLast ? te.Finish : undefined
                            }
                            item={te}
                            listId={data.ListId}
                            clockInId={te.ClockInId}
                            onTrackItemMouseEvent={onTEMouseEvent}
                            action={action}
                            clockInOutText={clockInOutText}
                            pinnedColor={pinnedColor}
                            tcTE={te.TCTE}
                            isShownStateAllocation={ShowStateAllocation}
                          ></TERow>
                        </React.Fragment>
                      );
                    })}
                </div>
              );
            })}
          </div>
          <div className={styles.TCMileage} style={{ width: 70 }}>
            <Mileages drawMileages={drawMileages} />
          </div>
        </div>
        {!!workOrders.length && (
          <WOsListContainer>
            {workOrders.map((wo) => (
              <WoRow
                key={wo.DispatchId || wo.WorkOrderId}
                listId={data.ListId}
                id={wo.DispatchId || wo.WorkOrderId}
                name={wo.WorkOrderName}
                wasOnSite={wo.WasOnSite}
                refName={
                  wo.DispatchId ? "FSMDispatchSchedule" : "FSMWorkOrders"
                }
                onDispatchMouseEvent={props.onDispatchMouseEvent}
              />
            ))}
          </WOsListContainer>
        )}
      </div>
    );
  };

  if (props.isTCMap && isLoading.value) return <Loader />;
  if (props.isTCMap) return renderDetails();

  return (
    <div
      id={"item-" + data.ListId} // for scrollTo
      className={styles.DeviceBox}
      onClick={onClick}
    >
      <Card className={styles.DeviceCard}>
        <div className={styles.ListItemInfo}>
          <PinToggle
            isPinned={isPinned}
            color={pinnedColor}
            isLoading={isLoading.value}
            onClick={onTogglePin}
          />
          <div>{data.EmployeeName}</div>
          <span
            className={`${styles.ListItemDuration} ${styles.ListItemFullDuration}`}
          >
            {data.Duration}
          </span>
        </div>

        {renderDetails()}
      </Card>
    </div>
  );
}

const WaivedLunchesComponent = (props: { waivedLunches: string[] }) => {
  const { waivedLunches } = props;
  if (!waivedLunches.length) return null;

  return (
    <div className={styles.WaivedLunchesRow}>
      <span>Waived Lunch{waivedLunches.length > 1 ? "es" : ""}:</span>
      {waivedLunches.map((x) => (
        <span key={x} className={styles.WaivedLunch}>
          {x}
        </span>
      ))}
    </div>
  );
};

export default TimeCard;
