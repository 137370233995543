import { useEffect, useRef, useState } from "react";
import styles from "./sidebar.module.scss";
import SideBarToggleBtn from "./SideBarToggleBtn";

interface IProps {
  mode: "overlay" | "push";
  width: number | string;
  defaultOpened: boolean;
  outsideContent?: JSX.Element;
  insideContent: JSX.Element;
  isRightSide?: boolean;
  btnText?: string;
  btnTextStyle?: React.CSSProperties;
  boxStyle?: React.CSSProperties;
  onToggle?: (openedState: boolean) => void;
  sideBarStyles?: React.CSSProperties;
  additionBtns?: JSX.Element | null;
}

const SideBar = (props: IProps) => {
  const {
    defaultOpened,
    btnText,
    isRightSide,
    width,
    onToggle,
    additionBtns,
    sideBarStyles,
  } = props;
  const [opened, setOpened] = useState(defaultOpened);
  const prevBtnText = useRef(btnText);

  useEffect(() => {
    onToggle?.(opened);
  }, [opened]);

  useEffect(() => {
    if (prevBtnText.current === btnText) return;
    setOpened(false);
    prevBtnText.current = btnText;
  }, [btnText]);

  const renderPanel = () => {
    return (
      <div
        className={`${styles.Sidebar} ${opened ? styles.Opened : ""}`}
        style={{
          ...(sideBarStyles || {}),
          width: opened ? width : "auto",
        }}
      >
        <div className={styles.ChildrenBox}>{props.insideContent}</div>
        <SideBarToggleBtn
          toggle={toggle}
          btnText={btnText || ""}
          opened={opened}
          btnTextStyle={props.btnTextStyle}
          isRightSide={isRightSide}
          additionalBtns={additionBtns}
        />
      </div>
    );
  };

  const toggle = () => {
    setOpened((oldValue) => !oldValue);
  };

  return (
    <div
      className={`${styles.Box} ${isRightSide ? styles.RightSide : ""} ${
        props.mode === "overlay" ? styles.OverlaySidebar : styles.PushSidebar
      }`}
      style={props.boxStyle}
    >
      {isRightSide ? (
        <>
          {props.outsideContent}
          {renderPanel()}
        </>
      ) : (
        <>
          {renderPanel()}
          {props.outsideContent}
        </>
      )}
      {props.mode === "overlay" && opened && (
        <div className={styles.Overlay} onClick={toggle}></div>
      )}
    </div>
  );
};

export default SideBar;
