import React, { useCallback, useEffect, useRef } from "react";

import api from "../../core/api/api";
import DashboardGrid from "../../Components/Dashboard/Grid";
import { SQL_DB_TK_GetTimeCardAudits_Response } from "../../core/api/generated/conterra";
import styles from "./tc.module.scss";
import { TRenderToolbar } from "../TabsNew/interfaces";
import { IColumnSetting } from "../Dashboard/interfaces";
import { useLatestNotEmptyValue } from "../../core/tools/Hooks";

interface IProps {
  tcId?: number;
  isActive: boolean;
  pageId?: string;
  renderToolbar?: TRenderToolbar;
  refreshTC: () => void;
  reloadDependency?: unknown;
}

const TCAuditResults = (props: IProps) => {
  const { tcId, isActive, reloadDependency, renderToolbar } = props;
  const gridRefreshRef = useRef<() => void>(() => {});
  const columnsRef = useRef<IColumnSetting[]>([
    {
      field: "rowNum",
      title: "#",
      type: "string",
      aggregate: "count",
      gridWidth: 40,
      filterable: false,
      columnMenu: false,
    },
    {
      field: "auditRuleName",
      title: "Audit Rule",
      type: "string",
      gridWidth: 200,
      format: {
        type: "string",
        fieldId: "auditRuleId",
      },
    },
    {
      field: "resultText",
      title: "Text",
      type: "string",
      gridWidth: 250,
      format: {
        type: "string",
      },
    },
    {
      field: "adjustment",
      title: "Adjustment",
      type: "number",
      gridWidth: 100,
      format: {
        type: "number",
      },
    },
  ]);

  const currentTcId = useLatestNotEmptyValue(tcId);
  const loadData = useCallback(
    () => api.sql.dbTkGetTimeCardAudits({ tcId: currentTcId }),
    [currentTcId]
  );

  const lastAppliedDependency = useRef<unknown>(reloadDependency);
  useEffect(() => {
    if (isActive && lastAppliedDependency.current !== reloadDependency) {
      lastAppliedDependency.current = reloadDependency;
      gridRefreshRef.current?.();
    }
  }, [reloadDependency, isActive]);

  const initRefresh = useCallback((refresh: () => void) => {
    gridRefreshRef.current = refresh;
  }, []);

  const getRowKey = useCallback(
    (x: SQL_DB_TK_GetTimeCardAudits_Response) => x.rowNum + "",
    []
  );

  const getRowClass = useCallback(
    (x: SQL_DB_TK_GetTimeCardAudits_Response) =>
      x.isImportant ? styles.ImportantAuditResultRow : "",
    []
  );

  if (!currentTcId) return null;

  return (
    <DashboardGrid
      isActive={isActive}
      initRefresh={initRefresh}
      columnsSettings={columnsRef.current}
      getData={loadData}
      getRowKey={getRowKey}
      getRowClass={getRowClass}
      excelFileName="TCAuditResults.xlsx"
      columnMenu={{
        filters: true,
        columns: false,
      }}
      toolbar={{ renderToolbar }}
    />
  );
};

export default TCAuditResults;
