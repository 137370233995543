import React, { useCallback, useEffect } from "react";
import { ITCCardWithLoadingProps } from "../interfaces";
import { useLazyAsync } from "../../../core/tools/Hooks";
import api from "../../../core/api/api";
import TCCard from "./TCCard";

const TCCardWithLoadingData = (props: ITCCardWithLoadingProps) => {
  const { tcId, ...restProps } = props;

  const [getData, { data: timeCards, loading }] = useLazyAsync(
    useCallback(() => api.sql.dbTkReview({ tcId }), [tcId])
  );
  useEffect(getData, [getData]);
  const tcInfo = timeCards?.[0];

  return (
    <TCCard
      isLoading={loading}
      tcInfo={tcInfo}
      reload={getData}
      {...restProps}
    />
  );
};

export default TCCardWithLoadingData;
