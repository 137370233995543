/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AccuracyAuthorization */
export enum AccuracyAuthorization {
  Full = "Full",
  Reduced = "Reduced",
}

export const EnumAccuracyAuthorization = {
  Full: "Full",
  Reduced: "Reduced",
};

/** AuthorizationStatus */
export enum AuthorizationStatus {
  NotDetermined = "NotDetermined",
  Restricted = "Restricted",
  Denied = "Denied",
  Always = "Always",
  WhenInUse = "WhenInUse",
}

export const EnumAuthorizationStatus = {
  NotDetermined: "NotDetermined",
  Restricted: "Restricted",
  Denied: "Denied",
  Always: "Always",
  WhenInUse: "WhenInUse",
};

/** GpsRecordArray */
export interface GpsRecordArray {
  /**
   * DateTime
   * @format date-time
   */
  timestamp: string;
  /**
   * Decimal
   * @format double
   */
  latitude: number;
  /**
   * Decimal
   * @format double
   */
  longitude: number;
  /**
   * Double
   * @format double
   */
  accuracy: number;
}

export const PropsGpsRecordArray = {
  timestamp: "timestamp",
  latitude: "latitude",
  longitude: "longitude",
  accuracy: "accuracy",
} as const;

/** GpsSyncBatchRequest */
export interface GpsSyncBatchRequest {
  /** List`1 */
  location: GpsRecordArray[];
}

export const PropsGpsSyncBatchRequest = {
  location: "location",
} as const;

/** LocationTrackingStatusRequest */
export interface LocationTrackingStatusRequest {
  /**
   * Int64
   * @format int64
   */
  timestamp: number;
  /**
   * Int32
   * @format int32
   */
  isMoving: number;
  /**
   * Int32
   * @format int32
   */
  isGps: number;
  /**
   * Int32
   * @format int32
   */
  isCollectingPoints: number;
  /**
   * Int32
   * @format int32
   */
  isNetwork: number;
  /**
   * Int32
   * @format int32
   */
  isPowerSave: number;
  /**
   * Int32
   * @format int32
   */
  isBackgroundFetchAllowed: number;
  /**
   * Int32
   * @format int32
   */
  didDeviceReboot: number;
  /**
   * Int32
   * @format int32
   */
  didLaunchInBackground: number;
  /**
   * Int32
   * @format int32
   */
  isIgnoringBatteryOptimizations: number;
  /**
   * Int32
   * @format int32
   */
  isBatteryCharging: number;
  authorization: AuthorizationStatus;
  accuracy: AccuracyAuthorization;
  /**
   * Int32
   * @format int32
   */
  notSyncedPoints: number;
  /**
   * Int32
   * @format int32
   */
  batteryLevel: number;
}

export const PropsLocationTrackingStatusRequest = {
  timestamp: "timestamp",
  isMoving: "isMoving",
  isGps: "isGps",
  isCollectingPoints: "isCollectingPoints",
  isNetwork: "isNetwork",
  isPowerSave: "isPowerSave",
  isBackgroundFetchAllowed: "isBackgroundFetchAllowed",
  didDeviceReboot: "didDeviceReboot",
  didLaunchInBackground: "didLaunchInBackground",
  isIgnoringBatteryOptimizations: "isIgnoringBatteryOptimizations",
  isBatteryCharging: "isBatteryCharging",
  authorization: "authorization",
  accuracy: "accuracy",
  notSyncedPoints: "notSyncedPoints",
  batteryLevel: "batteryLevel",
} as const;

/** LocationTrackingStatusSaveRequest */
export interface LocationTrackingStatusSaveRequest {
  /** List`1 */
  data: LocationTrackingStatusRequest[];
}

export const PropsLocationTrackingStatusSaveRequest = {
  data: "data",
} as const;

/** WorkshiftMobileData */
export interface WorkshiftMobileData {
  /**
   * Int64
   * @format int64
   */
  timestamp: number;
  /** Boolean */
  isMoving: boolean;
  /** Boolean */
  isGps: boolean;
  /** Boolean */
  isCollectingPoints: boolean;
  /** Boolean */
  isNetwork: boolean;
  /** Boolean */
  isPowerSave: boolean;
  /** Boolean */
  isBackgroundFetchAllowed: boolean;
  /** Boolean */
  didDeviceReboot: boolean;
  /** Boolean */
  didLaunchInBackground: boolean;
  /** Boolean */
  isIgnoringBatteryOptimizations: boolean;
  /** Boolean */
  isBatteryCharging: boolean;
  /** String */
  authorization: string;
  /** String */
  accuracy: string;
  /**
   * Int32
   * @format int32
   */
  notSyncedPoints: number;
  /**
   * Int32
   * @format int32
   */
  batteryLevel: number;
}

export const PropsWorkshiftMobileData = {
  timestamp: "timestamp",
  isMoving: "isMoving",
  isGps: "isGps",
  isCollectingPoints: "isCollectingPoints",
  isNetwork: "isNetwork",
  isPowerSave: "isPowerSave",
  isBackgroundFetchAllowed: "isBackgroundFetchAllowed",
  didDeviceReboot: "didDeviceReboot",
  didLaunchInBackground: "didLaunchInBackground",
  isIgnoringBatteryOptimizations: "isIgnoringBatteryOptimizations",
  isBatteryCharging: "isBatteryCharging",
  authorization: "authorization",
  accuracy: "accuracy",
  notSyncedPoints: "notSyncedPoints",
  batteryLevel: "batteryLevel",
} as const;

/** WorkshiftMobileDataRequest */
export interface WorkshiftMobileDataRequest {
  /**
   * Int32
   * @format int32
   */
  workshiftId: number;
}

export const PropsWorkshiftMobileDataRequest = {
  workshiftId: "workshiftId",
} as const;

/** WorkshiftMobileDataResponse */
export interface WorkshiftMobileDataResponse {
  /**
   * Int32
   * @format int32
   */
  workshiftId: number;
  /**
   * Int32
   * @format int32
   */
  timeOffset: number;
  /** List`1 */
  data: WorkshiftMobileData[];
}

export const PropsWorkshiftMobileDataResponse = {
  workshiftId: "workshiftId",
  timeOffset: "timeOffset",
  data: "data",
} as const;

import { AxiosRequestConfig, ResponseType } from "axios";
import { BBPromise as BBPromiseInterface } from "../../interfaces";

interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: Record<string | number, any>;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

interface HttpClient {
  request: <T>({ path, type, query, format, body, ...params }: FullRequestParams) => BBPromiseInterface<T>;
}

/**
 * @title FieldClix Time Keeping
 * @version v1
 */

export class Api_gps {
  private readonly http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  gps = {
    /**
 * No description
 *
 * @tags Gps
 * @name LocationTrackingStatus
 * @request POST:/Gps/location-tracking-status
 * @secure

 */
    locationTrackingStatus: (
      query: {
        /** String */
        sas: string;
        /**
         * Int32
         * @format int32
         */
        workshiftId: number;
      },
      data: LocationTrackingStatusSaveRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Gps/location-tracking-status`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
 * No description
 *
 * @tags Gps
 * @name WorkshiftMobileData
 * @request POST:/Gps/mobile-data
 * @secure

 */
    workshiftMobileData: (data: WorkshiftMobileDataRequest, params: RequestParams = {}) =>
      this.http.request<WorkshiftMobileDataResponse>({
        path: `/Gps/mobile-data`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Gps
 * @name ProcessGpsDataForWorkshift
 * @request POST:/Gps/process-gps-data
 * @secure

 */
    processGpsDataForWorkshift: (
      query: {
        /**
         * Int32
         * @format int32
         */
        workshiftId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Gps/process-gps-data`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
 * No description
 *
 * @tags Gps
 * @name GetSasToken
 * @request GET:/Gps/Sas
 * @secure

 */
    getSasToken: (params: RequestParams = {}) =>
      this.http.request<string>({
        path: `/Gps/Sas`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Gps
 * @name SyncBatch
 * @request POST:/Gps/sync-batch
 * @secure

 */
    syncBatch: (
      query: {
        /** String */
        sas: string;
        /**
         * Int32
         * @format int32
         */
        workshiftId: number;
      },
      data: GpsSyncBatchRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Gps/sync-batch`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
