import { Component } from "react";

interface props {
  folder?: string;
  urlParams?: string;
  url?: string;
  id?: string;
  initRef?: any;
}

interface state {}

class WebIframe extends Component<props, state> {
  iframeRef: any;
  _loadIFrameListeners: {
    [key: string]: {
      callbacks: Array<any>;
      loaded: boolean;
      runCallbacks: any;
    };
  } = {};

  render() {
    if (!this.props.folder && !this.props.url) return null;
    let params = this.props.urlParams || "";
    let url =
      this.props.url || `/tools/${this.props.folder}/index.html${params}`;
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <iframe
          id={this.props.id || ""}
          ref={(ref) => {
            if (this.props.initRef) {
              this.props.initRef(ref);
            } else {
              this.initRef(ref);
            }
          }}
          title="Dashboards"
          style={{
            width: "100%",
            height: "100%",
            border: 0,
            display: "block",
          }}
          src={url}
        ></iframe>
      </div>
    );
  }

  initRef = (ref: any) => {
    if (!this.iframeRef && ref) {
      this.iframeRef = ref;
    }
  };
}

export default WebIframe;
