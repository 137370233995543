import React, { useCallback, useEffect, useRef, useState } from "react";

import { BBPromise } from "../interfaces";
import { loadAction, refresherAction } from "./interfaces";

type AsyncFunction<T, P extends unknown[] = unknown[]> = (
  ...params: P
) => BBPromise<T[]>;

type OutputAsyncFunction<T, P extends unknown[]> = [
  (...params: P) => void,
  { data: T[] | null; error: Error | null; loading: boolean },
  (keyField: keyof T, ...params: P) => void
];

export function useRefresher(): refresherAction {
  const [value, setValue] = useState(0);
  const refresh = useCallback(
    () => setValue((value) => value + 1),
    [setValue]
  ) as refresherAction;
  refresh.value = value;
  return refresh;
}

export function useBooleanState(initialState: boolean = false): {
  setFalse: () => void;
  setTrue: () => void;
  toggle: () => void;
  value: boolean;
} {
  const [value, setValue] = useState<boolean>(initialState);
  const setTrue = useCallback((): void => setValue(true), []);
  const setFalse = useCallback((): void => setValue(false), []);
  const toggle = useCallback((): void => setValue((v) => !v), [setValue]);
  const values = useRef({
    setFalse,
    setTrue,
    toggle,
    value,
  });
  values.current.value = value;
  return values.current;
}

export function useLoadAction<T extends unknown[], V, D extends unknown[]>(
  action: (...params: T) => Promise<V>,
  dependencies: D
) {
  const refresh = useRefresher();
  const isInProgressRef = useRef(false);
  const wrappedAction = useCallback(async (...args: T) => {
    if (isInProgressRef.current) {
      return new Promise((_, reject) => {
        reject(new Error("Action is already started"));
      });
    }
    isInProgressRef.current = true;
    refresh();
    try {
      return await action(...args);
    } finally {
      isInProgressRef.current = false;
      refresh();
    }
    // eslint-disable-next-line
  }, dependencies) as loadAction;
  wrappedAction.isProcessing = isInProgressRef.current;
  return wrappedAction;
}

export function useLazyAsync<T, P extends unknown[]>(
  f: AsyncFunction<T, P>
): OutputAsyncFunction<T, P> {
  const [data, setData] = useState<T[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const fn = useCallback(
    (...params: P): void => {
      setLoading(true);
      setError(null);

      const promise = f(...params);

      promise
        .then(setData)
        .catch(setError)
        .finally(() => setLoading(false));
    },
    [f]
  );

  const partialReload = useCallback(
    (keyField: keyof T, ...params: P) => {
      setLoading(true);
      setError(null);

      const promise = f(...params);

      promise
        .then((partialData) =>
          setData((prevData) => {
            const newData: T[] = [...(prevData || [])];
            partialData?.forEach((reloadedItem) => {
              const key = reloadedItem[keyField];
              const index = newData.findIndex((x) => x[keyField] === key);
              if (index > -1) newData[index] = reloadedItem;
            });
            return newData;
          })
        )
        .catch(setError)
        .finally(() => setLoading(false));
    },
    [f]
  );

  return [fn, { loading, error, data }, partialReload];
}

export function useAsync<T, P extends unknown[]>(
  f: AsyncFunction<T, P>,
  ...params: Parameters<typeof f>
): [T[] | null, boolean, Error | null] {
  const [data, setData] = useState<T[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fn = (...args: Parameters<typeof f>): BBPromise<T[]> => {
      setLoading(true);

      const promise = f(...args);

      promise
        .then(setData)
        .catch(setError)
        .finally(() => setLoading(false));

      return promise;
    };

    const promise = fn(...params);

    return () => {
      if (promise.isCancelled() || promise.isResolved() || promise.isRejected())
        return;
      promise.cancel();
    };

    // eslint-disable-next-line
  }, [f, ...params]);

  return [data, loading, error];
}

export function useLatestNotEmptyValue<I>(propValue: I | null | undefined) {
  const valueRef = useRef(propValue);
  if (propValue !== undefined && propValue !== null) {
    valueRef.current = propValue;
  }
  return valueRef.current;
}

export function stopPropagationWrapper(callback: () => unknown) {
  return (event: {
    stopPropagation?: () => void;
    syntheticEvent?: React.MouseEvent<any>;
  }) => {
    if (event.stopPropagation) event.stopPropagation();
    else if (event.syntheticEvent) {
      event.syntheticEvent.stopPropagation();
    }
    callback();
  };
}
