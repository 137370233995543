import { Tooltip } from "@progress/kendo-react-tooltip";
import { Card } from "@progress/kendo-react-layout";
import styles from "./tkToolbar.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import CardManagement from "../../../Components/Cards/CardManagement";

export const CompareControl = (props: {
  compareMode: {
    setFalse: () => void;
    setTrue: () => void;
    toggle: () => void;
    value: boolean;
  };
  ids: number[];
}) => {
  const { ids, compareMode } = props;

  return (
    <Tooltip anchorElement="target" showCallout={false} parentTitle={true}>
      <Card
        className={styles.Card}
        style={compareMode.value ? { width: 180 } : {}}
      >
        <Button
          className={styles.CompareModeBtn}
          themeColor={compareMode.value ? "primary" : "base"}
          togglable={true}
          selected={compareMode.value}
          onClick={compareMode.toggle}
          iconClass={"mdi mdi-swap-horizontal"}
          fillMode={"flat"}
        ></Button>
        {compareMode.value && (
          <Button
            disabled={ids.length < 2}
            fillMode={"flat"}
            onClick={() => {
              CardManagement.OpenTimeCardsComparisonCard({
                ids,
              });
            }}
            className={styles.CompareModeBtn}
          >
            Compare {ids.length} Time Cards
          </Button>
        )}
      </Card>
    </Tooltip>
  );
};
