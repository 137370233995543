import { Button, Toolbar } from "@progress/kendo-react-buttons";
import styles from "./tkToolbar.module.scss";
import React, { useMemo } from "react";
import { INewComboboxItem } from "../../../helpers/interfaces";
import { IDispatchDataFilter, IFilters, TKFilterKey } from "../interfaces";
import CardManagement from "../../../Components/Cards/CardManagement";
import {
  FilterByDispatch,
  FilterByPP,
  FilterByResource,
  FilterByReviewer,
  FilterByState,
} from "./Filters";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { isTKCanCompleteTCs, isTKManager } from "../../../core/tools/roles";
import {
  PropsSQLDBTKReviewRequest,
  SQL_DB_TK_Review_Response,
} from "../../../core/api/generated/conterra";
import { DateRangeFilter } from "./DateRangeFilter";

interface IProps {
  isDateSortAsc: boolean;
  isHideRelatedTCs: boolean;
  toolbarExpandState: boolean;
  filters: IFilters;
  compare: JSX.Element;
  timecardsActions: JSX.Element;
  filteredTimeCards: SQL_DB_TK_Review_Response[];

  handleToggleShowRelatedTCs: () => void;
  handleToggleSortByDate: () => void;

  refreshList(): void;

  onClearFilters(): void;

  onServerFilterChange(
    value: INewComboboxItem | null,
    filterName: TKFilterKey
  ): void;

  onDateFilterChange(value: SelectionRange | null): void;

  onToggleToolbar(): void;
}

const TKListToolbar = (props: IProps) => {
  const {
    toolbarExpandState,
    filters,
    refreshList,
    isDateSortAsc,
    isHideRelatedTCs,
    handleToggleShowRelatedTCs,
    handleToggleSortByDate,
    onClearFilters,
    onDateFilterChange,
    timecardsActions,
    onToggleToolbar,
    onServerFilterChange,
    compare,
    filteredTimeCards,
  } = props;
  const {
    date: filterByDate,
    finishDate: filterByFinishDate,
    resourceId: filterByResourse,
  } = filters;

  const solicitTKRequest = () => {
    CardManagement.OpenSolicitTKRequestCard({ afterSave: refreshList });
  };

  const hasAppliedFilters = useMemo(() => {
    if (!isDateSortAsc || !isHideRelatedTCs) return true;

    for (let filterName in filters) {
      if (filters[filterName as keyof IFilters]) return true;
    }
  }, [filters, isHideRelatedTCs, isDateSortAsc]);

  const dispatchDataParams: IDispatchDataFilter = useMemo(
    () => ({
      resource: filterByResourse,
      date: filterByDate,
      finishDate: filterByFinishDate,
    }),
    [filterByDate, filterByResourse, filterByFinishDate]
  );

  const renderFilters = () => {
    const showTCActionsAsIsAdmin = isTKManager() || isTKCanCompleteTCs();

    return (
      <>
        <DateRangeFilter
          onChange={onDateFilterChange}
          start={filters[PropsSQLDBTKReviewRequest.date] || null}
          end={filters[PropsSQLDBTKReviewRequest.finishDate] || null}
        />
        <FilterByPP
          onChange={onServerFilterChange}
          value={filters[PropsSQLDBTKReviewRequest.periodId]}
          filteredTimeCards={filteredTimeCards}
        />
        <FilterByDispatch
          onChange={onServerFilterChange}
          value={filters[PropsSQLDBTKReviewRequest.dispatchId]}
          params={dispatchDataParams}
          filteredTimeCards={filteredTimeCards}
        />
        <FilterByState
          onChange={onServerFilterChange}
          value={filters[PropsSQLDBTKReviewRequest.reviewStateId]}
        />
        {showTCActionsAsIsAdmin && (
          <FilterByReviewer
            onChange={onServerFilterChange}
            value={filters[PropsSQLDBTKReviewRequest.reviewerId]}
            filteredTimeCards={filteredTimeCards}
          />
        )}
        <FilterByResource
          onChange={onServerFilterChange}
          value={filters[PropsSQLDBTKReviewRequest.resourceId]}
          filteredTimeCards={filteredTimeCards}
        />
      </>
    );
  };

  return (
    <Toolbar style={{ flex: "0 0 auto" }}>
      <div className={styles.ToolbarRow}>
        {timecardsActions}
        {compare}
        <div style={{ flex: 1 }}></div>
        <Button
          iconClass={
            toolbarExpandState ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"
          }
          title={"Toggle filters"}
          onClick={onToggleToolbar}
          fillMode={"flat"}
        ></Button>
        <Button
          iconClass={"mdi mdi-filter-off"}
          title={"Clear Filters"}
          disabled={!hasAppliedFilters}
          onClick={() => {
            if (!isDateSortAsc) handleToggleSortByDate();
            if (!isHideRelatedTCs) handleToggleShowRelatedTCs();
            onClearFilters();
          }}
          fillMode={"flat"}
        ></Button>
        <Button
          iconClass={"mdi mdi-email"}
          title={"Solicit TK Request"}
          onClick={solicitTKRequest}
          fillMode={"flat"}
        ></Button>
        <Button
          icon={"refresh"}
          title={"Refresh"}
          onClick={refreshList}
          fillMode={"flat"}
        ></Button>
      </div>
      <div
        className={styles.ToolbarRow}
        style={!toolbarExpandState ? { display: "none" } : {}}
      >
        {renderFilters()}
        <div style={{ marginLeft: "auto" }}>
          <Button
            icon={!isHideRelatedTCs || isDateSortAsc ? "sort-asc" : "sort-desc"}
            title={"Sort by Date"}
            disabled={!isHideRelatedTCs}
            onClick={handleToggleSortByDate}
            fillMode={"flat"}
          ></Button>
        </div>
        <Button
          iconClass={"mdi mdi-weather-night"}
          title={"Show Related Time Cards"}
          selected={!isHideRelatedTCs}
          togglable={true}
          onClick={handleToggleShowRelatedTCs}
          fillMode={"flat"}
        ></Button>
      </div>
    </Toolbar>
  );
};

export default TKListToolbar;
