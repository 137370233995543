import axios from "axios";
import EventEmitter from "eventemitter2";
import { inject, injectable } from "inversify";
import jwt_decode from "jwt-decode";
import config from "../../config";
import DI_TYPES from "../diTypes";
import type {
  IAuthInfo,
  IAuthProvider,
  IDeviceInfoProvider,
  ILastLogin,
  ILastUserService,
  ILogger,
  ILoginCredentials,
  ISettingsStorage,
  ITokens,
  IUserIdentity,
} from "../interfaces";
import { GetEmptyPromise } from "../tools/helpers";
import { IPromiseActions } from "../tools/interfaces";
import moment from "moment";

const STORAGE_KEY_AUTH_INFO = "FCX_AuthInfo";
const STORAGE_KEY_LAST_LOGINS = "FCX_LastLogins";
const AUTH_STATUS_CHANGED = "AUTH_STATUS_CHANGED";
const SETTINGS_SERVER_CHANGED = "SETTINGS_SERVER_CHANGED";

@injectable()
export default class AuthProvider implements IAuthProvider {
  private _refreshPromise?: IPromiseActions;
  private _authInfo: IAuthInfo | undefined;
  private _roles: string[] = [];
  private _allAuthInfos: IAuthInfo[] = [];
  private _isAuthorized: boolean | undefined = undefined;
  private _eventEmitter = new EventEmitter();

  constructor(
    @inject(DI_TYPES.ILogger) private _logger: ILogger,
    @inject(DI_TYPES.ISettingsStorage) private _storage: ISettingsStorage,
    @inject(DI_TYPES.IDeviceInfoProvider)
    private _deviceInfoProvider: IDeviceInfoProvider,
    @inject(DI_TYPES.ILastLoginService)
    private _lastLoginService: ILastUserService
  ) {
    this._updateFromStorage();
    this._storage.subscribeNotMineChanges(
      STORAGE_KEY_AUTH_INFO,
      this._updateFromStorage
    );

    const lastUserIdentity = this._lastLoginService.getLastUserIdentity();

    if (lastUserIdentity) {
      this.tryUseLogin(lastUserIdentity);
    }
  }

  public getIsAuthorized = () => this._isAuthorized;

  public getUserId = () => this._authInfo?.UserId;

  public getInstanceId = () => this._authInfo?.InstanceId;

  public getUserIdentity = () =>
    this.getIsAuthorized()
      ? "instance=" + this.getInstanceId() + "_user=" + this.getUserId()
      : undefined;

  public getRoles = () => this._roles;

  public getSettingsLastUpdate = () => this._authInfo?.slu;

  public getHeaders = () => {
    return {
      Authorization: `Bearer ${this._authInfo?.authToken}`,
      ...this._getDeviceHeaders(),
    };
  };

  public handleUnauthorized = async () => {
    if (this._refreshPromise) {
      await this._refreshPromise.promise;
    }
    this._refreshPromise = GetEmptyPromise();
    let refreshToken = this._authInfo?.refreshToken;
    try {
      let authInfo = await this._refreshAccessToken();

      this._processNewTokens(authInfo);
    } catch (error: any) {
      if (!error.response && error.code === "ERR_NETWORK") {
        throw error;
      }
      // Do nothing if refresh token was changed, it means another thread/tab/window did it
      if (this._authInfo && refreshToken === this._authInfo.refreshToken) {
        this._deleteAuthInfo(this._authInfo);
      }
    } finally {
      this._refreshPromise.resolve();
    }
  };

  public login = async (credentials: ILoginCredentials) => {
    let axiosInstance = axios.create({
      baseURL: config.API_URL,
      headers: {
        "Content-Type": "application/json",
        ...this._getDeviceHeaders(),
      },
    });
    try {
      let response = await axiosInstance.post<ITokens>("Auth", credentials);
      const authInfo = this._processNewTokens(response.data);
      if (response.status !== 200) {
        this._logger.error(
          "Auth Incorrect: {HttpStatus} {HttpMethod} {HttpUrl} {HttpParams}: " +
            " {HttpStatusText}",
          response.status,
          response.config.method,
          response.config.url,
          JSON.stringify(response.config),
          response.statusText
        );
        return "Invalid Credentials";
      }
      this._saveLastSuccessLogin(credentials, authInfo);
    } catch (error: any) {
      this._logger.errorException(
        error,
        "Auth Error: {HttpMethod} {HttpUrl} {HttpParams}",
        error.config.method,
        error.config.url,
        JSON.stringify(error.config.params)
      );
      return "Invalid Credentials";
    }
  };

  public tryUseLogin = (userIdentity: IUserIdentity) => {
    const userAuthInfo = this._allAuthInfos.find(
      (i) =>
        i.InstanceId.toLowerCase() === userIdentity.InstanceId.toLowerCase() &&
        i.UserId === userIdentity.UserId
    );
    if (userAuthInfo) {
      this._setCurrentAuthInfo(userAuthInfo);
      this._lastLoginService.setLastUserIdentity(userIdentity);
    }
  };

  public getLastLogins(): ILastLogin[] {
    this.updateIsAuthorisedLastLogins();
    const strLastLogins = this._storage.get(STORAGE_KEY_LAST_LOGINS);
    return JSON.parse(strLastLogins!) as ILastLogin[];
  }

  updateIsAuthorisedLastLogins = () => {
    const strLastLogins = this._storage.get(STORAGE_KEY_LAST_LOGINS);
    const lastLogins: ILastLogin[] = strLastLogins
      ? JSON.parse(strLastLogins)
      : [];
    for (let login of lastLogins) {
      login.isAuthorized =
        this._allAuthInfos.findIndex(
          (i) =>
            i.InstanceId.toLowerCase() === login.InstanceId.toLowerCase() &&
            i.UserId === login.UserId
        ) > -1;
    }
    this._storage.set(STORAGE_KEY_LAST_LOGINS, JSON.stringify(lastLogins));
  };

  public logout = () => {
    if (this._authInfo) {
      this._deleteAuthInfo(this._authInfo);
    }
  };

  public subscribeAuthorizationChange = (listener: () => void) => {
    this._eventEmitter.on(AUTH_STATUS_CHANGED, listener);
    return () => this._eventEmitter.off(AUTH_STATUS_CHANGED, listener);
  };

  public subscribeSettingsLastUpdateChange = (listener: () => void) => {
    this._eventEmitter.on(SETTINGS_SERVER_CHANGED, listener);
    return () => this._eventEmitter.off(SETTINGS_SERVER_CHANGED, listener);
  };

  private _setCurrentAuthInfo = (authInfo?: IAuthInfo) => {
    this._authInfo = authInfo;
    this._logger.setAuthInfo(authInfo);
    this._storage.setAuthInfo(authInfo);
    this._isAuthorized = !!this._authInfo;

    if (authInfo) {
      const roles = (jwt_decode(authInfo.authToken) as any).Roles;
      this._roles = roles.split(",");
    }

    this._eventEmitter.emit(AUTH_STATUS_CHANGED);
  };

  private _updateFromStorage = () => {
    const allAuthInfosStr = this._storage.get(STORAGE_KEY_AUTH_INFO);
    // TODO filter by expired
    let authInfosFromStorage: IAuthInfo[] = [];
    if (allAuthInfosStr) {
      // TODO use some library to check type
      try {
        const parsedJSON = JSON.parse(allAuthInfosStr);
        if (!Array.isArray(parsedJSON)) {
          throw new Error("Auth Info from storage is not an Array");
        }
        parsedJSON.forEach((element) => {
          const ownPropNames = Object.getOwnPropertyNames(element);
          const authInfoProps: Array<keyof IAuthInfo> = [
            "UserId",
            "InstanceId",
            "SessionId",
            "Login",
            "authToken",
            "refreshToken",
          ];
          authInfoProps.forEach((propName) => {
            if (!ownPropNames.includes(propName)) {
              throw new Error(
                `Auth Info from storage is not valid, no property "${propName}"`
              );
            }
          });
        });
        authInfosFromStorage = parsedJSON;
      } catch (e) {
        this._logger.fatalException(e);
      }
    }
    this._allAuthInfos = authInfosFromStorage.filter((info) => {
      const decodedAuthToken: Record<string, string | number> = jwt_decode(
        info.authToken
      );
      const decodedRefreshToken: Record<string, string | number> = jwt_decode(
        info.refreshToken
      );

      const expireDateAuthToken = moment
        .utc(Number(decodedAuthToken.exp) * 1000)
        .local();
      const expireDateRefreshToken = moment
        .utc(Number(decodedRefreshToken.exp) * 1000)
        .local();
      const currentDate = moment();

      return (
        currentDate.isBefore(expireDateAuthToken) ||
        currentDate.isBefore(expireDateRefreshToken)
      );
    });

    const newAuthInfo =
      this._authInfo &&
      this._allAuthInfos.find(
        (i) =>
          i.InstanceId.toLowerCase() ===
            this._authInfo?.InstanceId.toLowerCase() &&
          i.UserId.toLowerCase() === this._authInfo.UserId.toLowerCase()
      );
    this._setCurrentAuthInfo(newAuthInfo);
  };

  private _processNewTokens = (tokens: ITokens) => {
    const decoded: any = jwt_decode(tokens.authToken);
    // "slu": 1715780382,
    // "nbf": 1715860936,
    // "exp": 1715862136,
    // "iat": 1715860936
    const newAuthInfo: IAuthInfo = {
      ...tokens,
      UserId: decoded.UserId,
      InstanceId: decoded.InstanceId,
      SessionId: decoded.SessionId,
      Login: decoded.Login,
      slu: decoded.slu,
    };
    this._allAuthInfos = this._allAuthInfos.filter(
      (l) =>
        !(
          l.UserId.toLowerCase() === newAuthInfo.UserId.toLowerCase() &&
          l.InstanceId.toLowerCase() === newAuthInfo.InstanceId.toLowerCase()
        )
    );
    this._allAuthInfos.push(newAuthInfo);
    this._saveAllAuthInfos();
    this._setCurrentAuthInfo(newAuthInfo);

    this._eventEmitter.emit(SETTINGS_SERVER_CHANGED);
    this._logger.setAuthInfo(newAuthInfo);
    this._storage.setAuthInfo(newAuthInfo);

    return newAuthInfo;
  };

  private _saveAllAuthInfos = () => {
    this._storage.set(
      STORAGE_KEY_AUTH_INFO,
      JSON.stringify(this._allAuthInfos)
    );
  };

  private _deleteAuthInfo = (authInfo: IAuthInfo) => {
    this._allAuthInfos = this._allAuthInfos.filter(
      (l) =>
        !(
          l.UserId.toLowerCase() === authInfo.UserId.toLowerCase() &&
          l.InstanceId.toLowerCase() === authInfo.InstanceId.toLowerCase()
        )
    );
    this._saveAllAuthInfos();
    this._setCurrentAuthInfo();
  };

  private _getDeviceHeaders = () => {
    return {
      "FX-UserTimeOffset": this._deviceInfoProvider.getTimeOffset().toString(),
    };
  };

  private _refreshAccessToken = async () => {
    if (!this._authInfo?.refreshToken) {
      throw Error("Refresh Token not found");
    }

    let axiosInstance = axios.create({
      baseURL: config.API_URL,
      headers: {
        "Content-Type": "application/json",
        ...this._getDeviceHeaders(),
      },
    });

    let response = await axiosInstance.post<ITokens>("Auth/refresh", {
      refreshToken: this._authInfo.refreshToken,
    });
    return response.data;
  };

  private _saveLastSuccessLogin = (
    credentials: ILoginCredentials,
    authInfo: IAuthInfo
  ) => {
    const lastLogins = this.getLastLogins();
    const login = lastLogins.find(
      (l) =>
        l.login.toLowerCase() === credentials.login.toLowerCase() &&
        l.InstanceId.toLowerCase() === credentials.instanceId.toLowerCase()
    );

    if (login) {
      login.epoch = new Date().valueOf();
      login.isAuthorized = true;
      this._lastLoginService.setLastUserIdentity(login);
    } else {
      const newLastLogin: ILastLogin = {
        UserId: authInfo.UserId,
        InstanceId: credentials.instanceId,
        login: credentials.login,
        epoch: new Date().valueOf(),
        isAuthorized: true,
      };

      lastLogins.push(newLastLogin);
      this._lastLoginService.setLastUserIdentity(newLastLogin);
    }

    this._storage.set(STORAGE_KEY_LAST_LOGINS, JSON.stringify(lastLogins));
  };
}
