import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { IBuildPlan } from "./interfaces";
import BaseComponent from "../../Components/BaseComponent";
import ClearableInput from "../../Components/Common/Form/ClearableInput";
import { GridRowHeight } from "../../Components/Dashboard/helpers";
import Loader from "../../Components/Common/Loader";
import commonStyles from "../../assets/styles/common.module.scss";
import styles from "./daily.module.scss";
import CardManagement from "../../Components/Cards/CardManagement";
import { canGenerateBP } from "../../core/tools/roles";
import { showSomeError } from "../../helpers/helpers";
import { settingsStorage } from "../../helpers/settings";
import { IListNavigation, ISelectedInfo } from "../VIM/interfaces";

interface props {
  updateSelectedInfo(
    selectedInfo: ISelectedInfo | null,
    navigation: IListNavigation
  ): void;
}

interface state {
  loading: boolean;
  filter: string;
  buildPlans: Array<IBuildPlan>;
  selectedBuildPlan: { Id: number; Name: string } | null;
}

const SELECTED_LOCAL_STORAGE_NAME = "DailyBuildPlans_BPId";

class DailyBuildPlans extends BaseComponent<props, state> {
  buildPlans: Array<IBuildPlan> = [];
  visibleBuildPlans: Array<IBuildPlan> = [];
  selectedBuildPlan: { Id: number; Name: string } | null;
  filter: string = "";

  constructor(props: any) {
    super(props);
    let savedBP = settingsStorage.getForCurrentUser(
      SELECTED_LOCAL_STORAGE_NAME
    );
    this.selectedBuildPlan = savedBP
      ? (JSON.parse(savedBP) as {
          Id: number;
          Name: string;
        })
      : null;
    this.state = {
      loading: false,
      filter: "",
      buildPlans: [],
      selectedBuildPlan: this.selectedBuildPlan,
    };
  }

  componentDidMount() {
    this.LoadBuildPlans();
  }

  render() {
    return (
      <div className={commonStyles.ScreenHeightContainerWithToolbar}>
        {this.state.loading && <Loader />}
        <Toolbar>
          <ClearableInput
            defaultValue={this.state.filter}
            onChange={this.OnFilter}
            clear={this.OnFilter}
            style={{ width: 200 }}
          />
          <ToolbarSpacer />
          {canGenerateBP() && (
            <Button onClick={this.AddNewBuildPlan}>New Build Plan</Button>
          )}
          <Button
            icon="refresh"
            fillMode="flat"
            title="Refresh"
            onClick={this.RefreshBuildPlans}
          ></Button>
        </Toolbar>
        <div className={commonStyles.ScrollableContainer}>
          <Grid
            className={styles.BuildPlansDailyGrid}
            style={{ height: "100%" }}
            rowHeight={GridRowHeight}
            data={this.state.buildPlans.map((bp) => ({
              ...bp,
              selected: bp.Id === this.state.selectedBuildPlan?.Id,
            }))}
            selectedField="selected"
            onRowClick={this.OnSelectBuildPlan}
          >
            <Column field="BuildPlanName" title="Build Plan Number" />
            <Column field="CrewLead" title="Crew Lead" width="150px" />
            <Column
              field="LastActivity"
              title="Last Activity"
              width="100px"
              format="{0:MM/dd/yyyy}"
            />
          </Grid>
        </div>
      </div>
    );
  }

  LoadBuildPlans = async () => {
    try {
      this.setState({ loading: true });
      let response = await this.GetSQLData({ spName: "DB_BuildPlanDaily" });
      let buildPlans = response[0] || [];
      buildPlans.forEach((bp: any) => {
        bp.LastActivity = new Date(bp.LastActivity);
      });
      this.buildPlans = buildPlans;
      this.Filter();
      if (this.selectedBuildPlan) {
        let buildPlan = this.visibleBuildPlans.find(
          (bp: IBuildPlan) => bp.Id === this.selectedBuildPlan?.Id
        );
        this.Select(
          buildPlan
            ? {
                Id: buildPlan.Id,
                Name: buildPlan.BuildPlanName,
              }
            : null
        );
      }
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  Filter = () => {
    let filter = this.filter.toLowerCase();
    let filtered = !filter
      ? this.buildPlans
      : this.buildPlans.filter(
          (bp) =>
            bp.BuildPlanName.toLowerCase().includes(filter) ||
            (bp.CrewLead && bp.CrewLead.toLowerCase().includes(filter))
        );
    this.visibleBuildPlans = filtered;
    this.setState({ buildPlans: filtered });
    const { selectedBuildPlan } = this.state;
    this.props.updateSelectedInfo(
      selectedBuildPlan
        ? {
            ...this.GetDisabledArrows(),
            ...selectedBuildPlan,
          }
        : null,
      {
        selectPrev: this.OnSelectPrev,
        selectNext: this.OnSelectNext,
      }
    );
  };

  RefreshBuildPlans = () => {
    this.LoadBuildPlans();
  };

  OnSelectBuildPlan = ({ dataItem }: { dataItem: IBuildPlan }) => {
    this.Select({ Id: dataItem.Id, Name: dataItem.BuildPlanName });
  };

  Select = (value: { Id: number; Name: string } | null) => {
    this.selectedBuildPlan = value;
    if (!value) {
      settingsStorage.removeForCurrentUser(SELECTED_LOCAL_STORAGE_NAME);
      this.props.updateSelectedInfo(null, {
        selectPrev: this.OnSelectPrev,
        selectNext: this.OnSelectNext,
      });
      this.setState({ selectedBuildPlan: this.selectedBuildPlan });
      return;
    }

    settingsStorage.setForCurrentUser(
      SELECTED_LOCAL_STORAGE_NAME,
      JSON.stringify(value)
    );
    this.props.updateSelectedInfo(
      {
        ...value,
        ...this.GetDisabledArrows(),
      },
      { selectPrev: this.OnSelectPrev, selectNext: this.OnSelectNext }
    );
    this.setState({ selectedBuildPlan: value });
  };

  OnFilter = (e?: any) => {
    let value = e ? e.value : "";
    this.setState({ filter: value || "" });
    this.filter = value || "";
    this.Filter();
  };

  OnSelectNext = () => {
    let selected = this.GetSelectedInfo();
    let nextIndex =
      selected > -1 && this.visibleBuildPlans.length - 1 > selected
        ? selected + 1
        : 0;
    let nextItem = this.visibleBuildPlans[nextIndex];
    this.Select({ Id: nextItem.Id, Name: nextItem.BuildPlanName });
  };

  OnSelectPrev = () => {
    let selected = this.GetSelectedInfo();
    let prevIndex =
      selected > -1 && selected > 0
        ? selected - 1
        : this.visibleBuildPlans.length - 1;
    let prevItem = this.visibleBuildPlans[prevIndex];
    this.Select({ Id: prevItem.Id, Name: prevItem.BuildPlanName });
  };

  GetDisabledArrows = () => {
    const selectedIndex = this.GetSelectedInfo();
    return {
      nextDisabled:
        selectedIndex > -1
          ? selectedIndex === this.visibleBuildPlans.length - 1
          : true,
      prevDisabled: selectedIndex > -1 ? selectedIndex === 0 : true,
    };
  };

  GetSelectedInfo = () => {
    return this.visibleBuildPlans.findIndex(
      (item) => item.Id === this.selectedBuildPlan?.Id
    );
  };

  AddNewBuildPlan = () => {
    CardManagement.OpenGBPCard();
  };
}

export default DailyBuildPlans;
