import { IStatusPanelParams } from "ag-grid-community";

export interface StatusPanel_ReloadRows_Props extends IStatusPanelParams {
  getRows: () => void;
}

export default function StatusPanel_ReloadRows(
  props: StatusPanel_ReloadRows_Props
) {
  const { getRows } = props;
  return (
    <div className="ag-status-name-value link" onClick={() => getRows()}>
      <span
        className="k-icon k-font-icon k-i-refresh k-button-icon"
        role="presentation"
      />
    </div>
  );
}
