import React, {
  JSX,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridRowProps,
} from "@progress/kendo-react-grid";
import styles from "./tkGrid.module.scss";
import { ROW_HEIGHT } from "../helpers";
import throttle from "lodash.throttle";
import {
  useLatestNotEmptyValue,
  useRefresher,
} from "../../../core/tools/Hooks";
import {
  PropsSQLDBTKReviewResponse,
  SQL_DB_TK_Review_Response,
} from "../../../core/api/generated/conterra";

export default function TKReviewGrid(props: {
  selectedTCId?: number;
  timeCards: SQL_DB_TK_Review_Response[];
  renderRow: (tc: SQL_DB_TK_Review_Response) => JSX.Element;
  toolbarExpandState: boolean;
}) {
  const { timeCards, toolbarExpandState, selectedTCId, renderRow } = props;
  const selectedTC = useMemo(
    () => timeCards.find((x) => x.id === selectedTCId),
    [selectedTCId, timeCards]
  );
  const lastSelectedTCId = useLatestNotEmptyValue(selectedTCId);
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const [skip, setSkip] = useState(0);
  const resize = useRefresher();
  const gridRef = React.useRef<any>(null);

  useLayoutEffect(() => {
    resize();
  }, [toolbarExpandState, selectedTC]);

  useEffect(() => {
    setSkip(0);
  }, [timeCards]);

  const onWindowResize = useCallback(
    throttle(() => {
      resize();
    }, 200),
    [resize]
  );

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [onWindowResize]);

  const gridHeight = useMemo(
    () => gridContainerRef.current?.clientHeight || 300,
    [gridContainerRef.current, resize.value]
  );

  const onGridPageChange = useCallback(
    (event: GridPageChangeEvent) => {
      setSkip(event.page.skip);
    },
    [setSkip]
  );

  const renderGridRow = useCallback(
    (row: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps) => {
      const tc = rowProps.dataItem as SQL_DB_TK_Review_Response;
      const { style } = row.props;
      return (
        <tr
          key={row.key}
          id={`tc_${tc.id}`}
          className={`k-table-row k-master-row ${
            lastSelectedTCId === tc.id ? "k-selected" : ""
          }`}
          style={style as React.CSSProperties}
        >
          <td>{renderRow(tc)}</td>
        </tr>
      );
    },
    [renderRow, lastSelectedTCId]
  );

  const pageSize = Math.ceil((gridHeight * 3) / ROW_HEIGHT);
  return (
    <div
      className={styles.OuterContainer}
      ref={gridContainerRef}
      style={{ flex: 1 }}
    >
      <div className={styles.InnerContainer}>
        <Grid
          ref={gridRef}
          className={`${styles.ListGrid}`}
          style={{ height: gridHeight }}
          rowHeight={ROW_HEIGHT}
          data={timeCards}
          pageSize={pageSize}
          total={timeCards.length}
          skip={skip}
          scrollable={"virtual"}
          onPageChange={onGridPageChange}
          dataItemKey={PropsSQLDBTKReviewResponse.id}
          rowRender={renderGridRow}
          resizable={true}
        >
          <Column field={"EmployeeName"}></Column>
        </Grid>
      </div>
    </div>
  );
}
