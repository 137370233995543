import "./core/tools/CustomPromise"; // Setup custom Promise
import "./inversify.config"; // Setup DI Container
import React from "react";
import ReactDOM from "react-dom/client";
import "hammerjs"; // kendo chart need this dependency.
import "@progress/kendo-font-icons/dist/index.css";
import "@mdi/font/css/materialdesignicons.min.css";
import "./assets/icons/foundationIcons/css/foundation-icons.css";
import "./App.scss";
import { App } from "./App";
import "./tools/errorHandlers";

// TODO remove after refactoring?
import "ag-grid-enterprise";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-quartz.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
