import React, { useEffect, useMemo, useState } from "react";
import FXCard from "../Common/FXCard/FXCard";
import formStyles from "./card.module.scss";
import { IFXCardProps } from "./interfaces";
import TCDetailsGrid from "../TC/DetailsGrid";
import { Card } from "@progress/kendo-react-layout";
import { useBooleanState, useLazyAsync } from "../../core/tools/Hooks";
import api from "../../core/api/api";
import LoaderComponent from "../Common/Loader";
import moment from "moment/moment";
import { HorizontalTimeline } from "../TC/HorizontalTimeline";
import { Button, ButtonGroup, Toolbar } from "@progress/kendo-react-buttons";
import TCCardWithLoadingData from "./TCCard/TCCardWithLoadingData";

interface IProps extends IFXCardProps {
  ids: number[];
}

const TimeCardsComparisonCard = (props: IProps) => {
  const { ids, finally: handleClose } = props;
  const timeLineMode = useBooleanState(true);
  const [loadData, result] = useLazyAsync(api.sql.dbTkTimeCardsForCompare);
  useEffect(() => {
    loadData({
      tcIds: ids.join(","),
    });
  }, [ids, loadData]);
  const compareData = useMemo(() => result.data || [], [result]);
  const [selectedTCId, setSelectedTCId] = useState<number>();

  /*useEffect(() => {
        CardsStackRef.closeAllCards();
      }, [selectedTCId]);*/

  const RowsNav = useMemo(() => {
    const currentIndex = compareData.findIndex((x) => x.tcId === selectedTCId);
    const prevTCId = compareData[currentIndex - 1]?.tcId;
    const nextTCId = compareData[currentIndex + 1]?.tcId;
    return {
      selectedTCIndex: currentIndex,
      selectNext: nextTCId ? () => setSelectedTCId(nextTCId) : undefined,
      selectPrev: prevTCId ? () => setSelectedTCId(prevTCId) : undefined,
    };
  }, [compareData, selectedTCId, setSelectedTCId]);

  return (
    <>
      <FXCard
        title={`Comparison of ${ids.length} Time Cards`}
        initialWidth={950}
        initialHeight={900}
        originalPaddings={true}
        onClose={handleClose}
      >
        <div className={formStyles.FormWrapper} style={{ overflow: "auto" }}>
          {result.loading && <LoaderComponent />}
          {!!compareData && (
            <>
              <Toolbar style={{ flex: "0 0 auto" }}>
                <ButtonGroup>
                  <Button
                    togglable={true}
                    selected={timeLineMode.value}
                    onClick={timeLineMode.setTrue}
                  >
                    Timeline
                  </Button>
                  <Button
                    togglable={true}
                    selected={!timeLineMode.value}
                    onClick={timeLineMode.setFalse}
                  >
                    Grid
                  </Button>
                </ButtonGroup>
              </Toolbar>
              {compareData.map((x) => {
                return (
                  <Card
                    key={x.tcId}
                    style={{
                      marginTop: 8,
                      display: "block",
                      overflow: "initial",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 4,
                        padding: 4,
                      }}
                    >
                      <div style={{ fontWeight: 600 }}>
                        {moment(x.date).format("L")} {x.employeeName}
                      </div>
                      <Button
                        fillMode={"flat"}
                        onClick={() => {
                          setSelectedTCId(x.tcId);
                        }}
                      >
                        Open Card
                      </Button>
                    </div>
                    {timeLineMode.value ? (
                      <div style={{ padding: "0 8px" }}>
                        <HorizontalTimeline
                          data={x}
                          style={{
                            marginTop: 25,
                            marginBottom: 25,
                          }}
                        />
                      </div>
                    ) : (
                      <TCDetailsGrid data={x.tcAllocation} />
                    )}
                  </Card>
                );
              })}
            </>
          )}
        </div>
      </FXCard>
      {selectedTCId && (
        <TCCardWithLoadingData
          tcId={selectedTCId}
          handlePrev={RowsNav?.selectPrev}
          handleNext={RowsNav?.selectNext}
          handleClose={() => {
            setSelectedTCId(undefined);
          }}
          initialTabId={"TCAllocation"}
        />
      )}
    </>
  );
};

export default TimeCardsComparisonCard;
