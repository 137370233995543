import styles from "./tkTC.module.scss";
import moment from "moment";
import React from "react";
import { SQL_DB_TK_Review_Response } from "../../../core/api/generated/conterra";
import { formatTimeDiff } from "../../../core/tools/formats";
import { STATE_ID_TO_NAME } from "./helpers";
import { DispatchesChips } from "./DispatchesChips";
import TCChip from "./TCChip";
import { stopPropagationWrapper } from "../../../core/tools/Hooks";
import { tabId } from "../../../Components/Tabs/interfaces";
import { QuickChatHistory } from "./QuickChatHistory";
import { INewComboboxItem } from "../../../helpers/interfaces";

export const TCClockInOut = (props: {
  data: SQL_DB_TK_Review_Response | undefined;
}) => {
  const { data } = props;
  const { firstClockIn, lastClockOut } = data || {};

  return (
    <div className={styles.ClockInOut}>
      {!!firstClockIn && (
        <>
          <span
            className={"mdi mdi-circle-medium"}
            style={{ color: "green" }}
          ></span>
          <span title={"First Clock In"}>
            {moment(firstClockIn).format("LT")}
          </span>
        </>
      )}
      {!!lastClockOut && (
        <>
          <span
            className={"mdi mdi-circle-medium"}
            style={{ color: "red" }}
          ></span>
          <span title={"Last Clock Out"}>
            {moment(lastClockOut).format("LT")}
          </span>
        </>
      )}
    </div>
  );
};

export const TCTimeFormula = (props: {
  data: SQL_DB_TK_Review_Response | undefined;
}) => {
  const { data } = props;
  const { clockedHours, lunchHours, auditedHours, totalHours } = data || {};
  if (!clockedHours && !lunchHours && !auditedHours && !totalHours) return null;
  return (
    <div className={styles.TimeFormula}>
      <span title={"Clocked"}>
        {formatTimeDiff((clockedHours || 0) * 3600)}
      </span>
      <span> - </span>
      <span title={"Lunch"} style={{ color: "orange" }}>
        {formatTimeDiff((lunchHours || 0) * 3600)}
      </span>
      <span> - </span>
      <span style={{ color: "red" }} title={"Audited"}>
        {formatTimeDiff((auditedHours || 0) * 3600)}
      </span>
      <span> = </span>
      <span style={{ color: "green" }} title={"Total"}>
        {formatTimeDiff((totalHours || 0) * 3600)}
      </span>
    </div>
  );
};

export const TCReviewState = (props: { reviewState: string }) => {
  return (
    <div className={styles.Status}>
      <span>{STATE_ID_TO_NAME[props.reviewState!]}</span>
    </div>
  );
};

export const TCReviewer = (props: { reviewerName: string }) => {
  return (
    <div className={`${styles.Reviewer}`}>Reviewer: {props.reviewerName}</div>
  );
};

export const TCChips = (props: {
  data: SQL_DB_TK_Review_Response | undefined;
  setInitialTab: (tabId: tabId) => void;
  setDispatchFilter?(dispatch: INewComboboxItem): void;
}) => {
  const { data, setInitialTab } = props;
  const {
    dispatchInfo,
    reasonName,
    hasPW,
    importantAuditResults,
    activeRequests,
    notConfirmedAttestationCount,
    warnings,
    allocationStatus,
  } = data || {};

  const renderAllocationStatus = () => {
    if (allocationStatus === "Done") {
      return <TCChip themeColor={"success"} text={"Allocation Done"} />;
    }
    if (allocationStatus === "Pending") {
      return <TCChip themeColor={"warning"} text={"Pending Allocation"} />;
    }
  };

  return (
    <>
      {!!dispatchInfo?.length && (
        <DispatchesChips
          dispatches={dispatchInfo}
          onSelectDispatch={props.setDispatchFilter}
        />
      )}
      {!!reasonName && (
        <TCChip themeColor={"info"} text={`Reason: ${reasonName}`} />
      )}
      {renderAllocationStatus()}
      {hasPW && <TCChip themeColor={"info"} text={"PW"} />}
      {!!importantAuditResults && (
        <TCChip
          themeColor={"error"}
          text={`${importantAuditResults} Important Audit${
            importantAuditResults > 1 ? "s" : ""
          }`}
          onClick={stopPropagationWrapper(() => setInitialTab("TCAudits"))}
        />
      )}

      {!!activeRequests && (
        <TCChip
          themeColor={"error"}
          text={`${activeRequests} Active Request${
            activeRequests > 1 ? "s" : ""
          }`}
          onClick={stopPropagationWrapper(() => setInitialTab("TCRequests"))}
        />
      )}

      {!!notConfirmedAttestationCount && (
        <TCChip
          themeColor={"error"}
          text={`${notConfirmedAttestationCount} Not Confirmed Attestation${
            notConfirmedAttestationCount > 1 ? "s" : ""
          }`}
          onClick={stopPropagationWrapper(() =>
            setInitialTab("TCAttestations")
          )}
        />
      )}

      {!!warnings && <TCChip themeColor={"warning"} text={warnings} />}
    </>
  );
};

export const TCChatCloud = (props: {
  tcId?: number;
  commentCount?: number | null;
}) => {
  const { tcId, commentCount } = props;
  if (!tcId || !commentCount) return null;
  return <QuickChatHistory tcId={tcId} count={commentCount} />;
};
