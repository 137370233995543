import React, { useEffect, useRef, useState } from "react";
import { useBooleanState } from "../../core/tools/Hooks";
import { Popup } from "@progress/kendo-react-popup";
import UserInfo from "../../stores/User";
import { ICurrentUserInfo } from "../../stores/interfaces";
import {
  Avatar,
  PanelBar,
  PanelBarItem,
  PanelBarSelectEventArguments,
} from "@progress/kendo-react-layout";
import SwitchDesktopIntegration from "./SwitchDesktopIntegration";
import { pageId } from "../../helpers/interfaces";
import { authProvider } from "../../core/api/api";
import CardManagement from "../../Components/Cards/CardManagement";
import styles from "./Home.module.scss";
import LastLoginItem from "../Auth/LastLoginItem";
import { addParamsToUrl } from "../../helpers/helpers";

const ProfileInfo = (props: { routeTo: (pageId: pageId) => void }) => {
  const anchor = useRef<HTMLDivElement | null>();
  const isPopupShown = useBooleanState(false);
  const [info, setInfo] = useState<ICurrentUserInfo | null>();

  const getUserInfo = async () => {
    const info = await UserInfo.getInfo();
    setInfo(info);
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  // const [getShortCuts, { data, loading }] = useLazyAsync(UserInfo.getInfo)

  useEffect(() => {
    return () => {
      document.removeEventListener("click", hidePopup);
    };
  }, []);

  const hidePopup = (e: any) => {
    if (!document?.getElementById("profile-info-appbar")?.contains(e.target)) {
      isPopupShown.setFalse();
      document.removeEventListener("click", hidePopup);
    }
  };

  const showPopup = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isPopupShown.value) {
      hidePopup(e);
    } else {
      document.addEventListener("click", hidePopup);
      isPopupShown.setTrue();
    }
  };

  const onSelect = (e: PanelBarSelectEventArguments) => {
    const action = e.target.props.action;
    if (!action) return;
    switch (action) {
      case "logout":
        authProvider.logout();
        break;
      case "changePassword":
        CardManagement.OpenChangePasswordCard({});
        break;
      case "openProfile":
        props.routeTo("Profile");
        break;
      case "addUser":
        window.location.href = addParamsToUrl({ showForm: "true" }, "/");
        break;
    }
    isPopupShown.setFalse();
  };

  if (!info) return <></>;

  const lastLogins = authProvider
    .getLastLogins()
    .filter(
      (l) =>
        !(
          +l.UserId === +info.UserId &&
          l.InstanceId.toLowerCase() ===
            authProvider.getInstanceId()?.toLowerCase()
        )
    );

  return (
    <div>
      <div
        ref={(ref) => (anchor.current = ref)}
        onClick={showPopup}
        className={styles.AvatarContainer}
      >
        <Avatar type="text">
          {info.Initials}
          {/*<img src={kendokaAvatar} alt="KendoReact Layout Kendoka Avatar" />*/}
        </Avatar>
      </div>
      <Popup
        id={"profile-info-appbar"}
        anchor={anchor.current}
        show={isPopupShown.value}
        popupClass={styles.appBarPopup}
        className={styles.appBarPopupContainer}
      >
        <PanelBar style={{ width: "100%", borderWidth: 0 }} onSelect={onSelect}>
          <PanelBarItem
            className={styles.panelBarItem}
            iconClass={"mdi mdi-account"}
            title={info.Name}
            action={"openProfile"}
          ></PanelBarItem>
          {info.IsDesktopUser && (
            <PanelBarItem
              expanded={true}
              className={styles.panelBarItemIntegration}
            >
              <SwitchDesktopIntegration
                id={"appBar"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 8,
                  paddingLeft: 12,
                }}
              />
            </PanelBarItem>
          )}
          {!!lastLogins.length && (
            <PanelBarItem
              className={styles.panelBarItem}
              // iconClass="mdi mdi-account-switch-outline"
              title="Last Logins"
              expanded={true}
            >
              {lastLogins.map((user) => (
                <LastLoginItem
                  key={user.epoch}
                  l={user}
                  onSelect={(l) => {
                    if (l.isAuthorized) {
                      authProvider.tryUseLogin(l);
                      return;
                    }
                    window.location.href = addParamsToUrl(
                      {
                        showForm: "true",
                        user: l.UserId,
                        instance: l.InstanceId,
                      },
                      "/"
                    ); //`/${l.InstanceId}/${l.UserId}`; // todo show form
                  }}
                />
              ))}
            </PanelBarItem>
          )}
          <PanelBarItem
            className={styles.panelBarItem}
            icon={"plus"}
            title="Use Different User"
            action={"addUser"}
          />
          <PanelBarItem
            className={styles.panelBarItem}
            iconClass={"mdi mdi-lock-outline"}
            title="Change Password"
            action={"changePassword"}
          />
          <PanelBarItem
            className={`${styles.panelBarItem} ${styles.logoutItem}`}
            iconClass={"mdi mdi-logout"}
            title="Logout"
            action={"logout"}
          />
        </PanelBar>
      </Popup>
    </div>
  );
};

export default ProfileInfo;
