import React, { Component } from "react";
import DailyBuildplans from "./DailyBuildPlans";
import SideBar from "../../Components/Common/SideBar/SideBar";
import DailyTabs from "./DailyTabs";
import { IListNavigation, ISelectedInfo } from "../VIM/interfaces";
import styles from "./daily.module.scss";
import { Button } from "@progress/kendo-react-buttons";

interface state {
  selected: ISelectedInfo | null;
}

class DaylyDashboard extends Component<undefined, state> {
  refList: any = React.createRef();
  refTabs: any = React.createRef();
  listNavigation?: IListNavigation;

  constructor(props: undefined) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  render() {
    const { selected } = this.state;

    return (
      <SideBar
        btnText={`BUILD PLAN: ${selected?.Name}`}
        defaultOpened={true}
        mode="overlay"
        width={420}
        additionBtns={
          selected ? (
            <div>
              <Button
                className={styles.ListArrow}
                icon="arrow-60-up"
                fillMode="flat"
                onClick={(e) => {
                  e.stopPropagation();
                  this.listNavigation?.selectPrev();
                }}
                disabled={selected.prevDisabled}
              ></Button>
              <Button
                className={styles.ListArrow}
                icon="arrow-60-down"
                fillMode="flat"
                onClick={(e) => {
                  e.stopPropagation();
                  this.listNavigation?.selectNext();
                }}
                disabled={selected.nextDisabled}
              ></Button>
            </div>
          ) : null
        }
        insideContent={
          <DailyBuildplans
            ref={this.refList}
            updateSelectedInfo={this.ChangeBuildPlan}
          />
        }
        outsideContent={
          selected ? (
            <DailyTabs ref={this.refTabs} selectedBP={selected}></DailyTabs>
          ) : undefined
        }
      />
    );
  }

  ChangeBuildPlan = (
    selectedInfo: ISelectedInfo | null,
    navigation: IListNavigation
  ) => {
    this.listNavigation = navigation;
    this.setState({ selected: selectedInfo });
  };

  Refresh = () => {
    if (this.refList.current && this.refList.current.RefreshBuildPlans) {
      this.refList.current.RefreshBuildPlans();
    }

    if (this.refTabs.current && this.refTabs.current.RefreshActiveTabs) {
      this.refTabs.current.RefreshActiveTabs();
    }
  };
}

export default DaylyDashboard;
