import { Button } from "@progress/kendo-react-buttons";
import VIMDetails from "../Details/VIMDetails";
import { Component } from "react";
import prettyBytes from "pretty-bytes";
import { IComboboxItem, simpleObject } from "../../../helpers/interfaces";
import {
  IColumnSetting,
  IDashboardChildProps,
  IFilterSetting,
} from "../../../Components/Dashboard/interfaces";
import OpenCardLink from "../../../Components/OpenCardLink";
import BaseComponent from "../../../Components/BaseComponent";
import DashboardGrid from "../../../Components/Dashboard/Grid";
import DesktopActions from "../../../Components/Common/DesktopActions";
import { RunScriptLocal } from "../../../helpers/runscripts";
import { ModalRef } from "../../../Components/Common/Modal/Modal";
import api from "../../../core/api/api";
import config from "../../../config";
import { showSomeError } from "../../../helpers/helpers";

interface IDownloadInfo {
  DocCount: number;
  DocIds: string;
  DocSize: number;
}

interface props {
  isActive: boolean;
}

interface state {
  loading: boolean;
  accessAllow: boolean;
  invoiceId: number | null;
}

const VIM_STAGES: Array<IComboboxItem> = [
  {
    Id: "D",
    Name: "Draft",
  },
  {
    Id: "P",
    Name: "Pending",
  },
  {
    Id: "V",
    Name: "Validated",
  },
  {
    Id: "U",
    Name: "Under Approval",
  },
  {
    Id: "A",
    Name: "Approved",
  },
  {
    Id: "R",
    Name: "Rejected",
  },
  {
    Id: "Q",
    Name: "Cancelled",
  },
  {
    Id: "I",
    Name: "Paid",
  },
];

const FILTERS: Array<IFilterSetting> = [
  {
    id: "filterYear",
    placeholder: "Filter by Year",
    type: "dateRange",
    serverFilter: {
      param: "",
    },
    dateRangeSettings: {
      dateFromParam: "dateFrom",
      dateToParam: "dateTo",
      defaultPeriod: "notApplied",
      allowEmptyValues: true,
    },
    toolbarRow: 1,
  },
  {
    id: "filterVIMStage",
    placeholder: "Filter by Stage",
    type: "combobox",
    values: VIM_STAGES,
    serverFilter: {
      param: "stageId",
    },
    toolbarRow: 1,
  },
];

const COLUMNS: Array<IColumnSetting> = [
  {
    field: "RowNum",
    title: "#",
    type: "number",
    gridWidth: 40,
    filterable: false,
    columnMenu: false,
    aggregate: "count",
    locked: true,
  },
  {
    field: "VendorName",
    title: "Vendor",
    type: "string",
    gridWidth: 200,
    locked: true,
    format: {
      type: "link",
      fieldId: "VendorId",
      refName: "Organizations",
    },
  },
  {
    field: "ClassName",
    title: "Class",
    type: "string",
    gridWidth: 200,
    locked: true,
    format: {
      type: "string",
      fieldId: "ClassId",
    },
  },
  {
    field: "InvoiceNumber",
    title: "Invoice #",
    type: "string",
    locked: true,
  },
  {
    field: "InvoiceDate",
    title: "Date",
    type: "date",
    format: {
      type: "date",
    },
  },
  {
    field: "InvoiceTotal",
    title: "Invoice Total",
    type: "number",
    format: {
      type: "number",
    },
  },
  {
    field: "InvoiceCategoryName",
    title: "Category",
    type: "string",
    gridWidth: 200,
    format: {
      type: "link",
      fieldId: "InvoiceCategoryId",
      refName: "VIMInvoiceCategories",
    },
  },
  {
    field: "PaymentTermName",
    title: "Payment Terms",
    type: "string",
    format: {
      type: "string",
      fieldId: "PaymentTermId",
    },
  },
  {
    field: "DueDate",
    title: "Due Date",
    type: "date",
    format: {
      type: "date",
    },
  },
  {
    field: "InvoiceStage",
    title: "Stage",
    type: "string",
    format: {
      type: "string",
      fieldId: "InvoiceStage",
    },
  },
  {
    field: "PaidDate",
    title: "Paid Date",
    type: "date",
    format: {
      type: "date",
    },
  },
  {
    field: "PaidAmount",
    title: "Paid Amount",
    type: "number",
    format: {
      type: "currency",
    },
  },
  {
    field: "Synced",
    title: "Synced",
    type: "string",
    format: {
      type: "string",
      fieldId: "Synced",
    },
  },
  {
    field: "Source",
    title: "Source",
    type: "string",
    format: {
      type: "link",
      fieldId: "Source",
      customFormat: true,
      getCustomFormat: (dataItem: simpleObject) => {
        let refName = dataItem.SourceRefName;
        let id = dataItem.SourceId;
        let value = dataItem.Source;
        if (!id) return value;
        return <OpenCardLink text={value} dataAttr={id} refName={refName} />;
      },
    },
  },
  {
    title: "Single Allocation Build Plan",
    field: "SingleAllocationBPName",
    type: "string",
    format: {
      type: "link",
      fieldId: "SingleAllocationBPid",
      refName: "FSMBuildPlans",
    },
  },
  {
    title: "Approval Process",
    type: "parent",
    columns: [
      {
        field: "CurrentIteration",
        title: "Current Iteration",
        type: "number",
        format: {
          type: "integer",
        },
      },
      {
        field: "CurrentLevel",
        title: "Current Level",
        type: "number",
        format: {
          type: "integer",
        },
      },
      {
        field: "FirstLevelApprovers",
        title: "First Level Approvers",
        type: "string",
      },
      {
        field: "SecondLevelApprovers",
        title: "Second Level Approvers",
        type: "string",
      },
    ],
  },
  {
    title: "COD",
    type: "parent",
    columns: [
      {
        field: "CODCategoryName",
        title: "Purchase Category",
        type: "string",
        gridWidth: 150,
        format: {
          type: "link",
          fieldId: "CODCategoryId",
          refName: "BusinessPurposes",
        },
      },
      {
        field: "CODRequesterName",
        title: "Requester",
        type: "string",
        gridWidth: 150,
        format: {
          type: "link",
          fieldId: "CODRequesterId",
          refName: "Employees",
        },
      },
      {
        field: "CODCardTypeName",
        title: "Card Type",
        type: "string",
        gridWidth: 150,
        format: {
          type: "link",
          fieldId: "CODCardTypeId",
          refName: "CardTypes",
        },
      },
      {
        field: "CODCardsAndAccountName",
        title: "Card",
        type: "string",
        gridWidth: 150,
        format: {
          type: "link",
          fieldId: "CODCardsAndAccountId",
          refName: "Cards",
        },
      },
    ],
  },
];

class VIMDashboardPage extends BaseComponent<props, state> {
  documentsIds: number[] = [];

  constructor(props: props) {
    super(props);

    this.state = {
      loading: false,
      accessAllow: false,
      invoiceId: null,
    };
  }

  render() {
    return (
      <DashboardGrid
        isActive={this.props.isActive}
        isParentPage={true}
        columnsSettings={COLUMNS}
        filters={FILTERS}
        getData={this.LoadGridData}
        saveSettings={{ prefix: "VIM", saveFilters: true }}
        excelFileName="VIM.xlsx"
        columnMenu={{
          filters: true,
          columns: false,
        }}
        toolbar={{
          actionBtns: this.renderToolbarBtns(),
        }}
        onChangeGridData={this.OnChangeGridData}
        getRowKey={(dataItem: simpleObject) => dataItem.InvoiceId}
        selectSettings={{
          pageId: "VIM_Invoices",
          Component: VIMInvoicesDetails,
          componentProps: {
            onRefresh: this.Refresh,
          },
        }}
        initRefresh={this.InitRefresh}
      />
    );
  }

  renderToolbarBtns = () => {
    return (
      <>
        <Button
          onClick={this.OnDownloadAllDocuments}
          icon="download"
          title="Download All Documents"
        ></Button>
        <DesktopActions
          actions={
            <Button
              onClick={this.AddNewInvoice}
              icon="plus"
              title="New Invoice"
            ></Button>
          }
        />
        {this.state.accessAllow && (
          <DesktopActions
            actions={
              <>
                <Button
                  onClick={this.OpenInvoicesList}
                  icon="hyperlink-open"
                  title="Open Invoices List"
                >
                  Invoices
                </Button>
                <Button
                  onClick={this.OpenCODList}
                  icon="hyperlink-open"
                  title="Open COD List"
                >
                  COD
                </Button>
              </>
            }
          />
        )}
      </>
    );
  };

  LoadGridData = async (filters: simpleObject) => {
    const result = await this.GetSQLData({
      spName: "VIM_Invoices",
      params: filters,
    });
    this.setState({ accessAllow: result[0][0].AccessAllow });
    return result[1];
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };

  OpenInvoicesList = () => {
    RunScriptLocal("VIMInvoices_OpenReference");
  };

  OnChangeGridData = (data: Array<simpleObject>) => {
    this.documentsIds = [];
    for (let row of data) {
      this.documentsIds.push(row.InvoiceId);
    }
  };

  OpenCODList = () => {
    RunScriptLocal("ExpenseCreditCards_OpenReference");
  };

  OnDownloadAllDocuments = async () => {
    try {
      const result: [[IDownloadInfo]] = await this.GetSQLData({
        spName: "GetDocumentsByMultipleSourceIds",
        params: { sourceIds: this.documentsIds.join(",") },
      });
      this.OpenDownloadModal(result[0][0]);
    } catch (e) {}
  };

  OpenDownloadModal = (result: IDownloadInfo) => {
    const bytes = result.DocSize !== null ? prettyBytes(result.DocSize) : 0;
    const maxDocumentsAmount = 350;
    if (result.DocCount > maxDocumentsAmount) {
      ModalRef.showDialog({
        type: "warning",
        title: "Download documents",
        width: 500,
        children: (
          <div>
            <div>
              There are too many documents selected for download. Current
              limitation - <b>{maxDocumentsAmount}</b> documents. Use filters to
              reduce the amount of invoices and try again.
            </div>
            <div style={{ marginTop: 8 }}>
              Total Amount of Documents: <b>{result.DocCount}</b>
            </div>
          </div>
        ),
      });
      return;
    }
    ModalRef.showDialog({
      title: "Download documents",
      children: (
        <div>
          <div>Total Size: {bytes}</div>
          <div>Total Amount of Documents: {result.DocCount}</div>
        </div>
      ),
      buttons: [
        {
          text: "Cancel",
          action: () => {
            ModalRef.hideDialog();
          },
        },
        {
          text: "Download",
          color: "primary",
          action: () => {
            this.DownloadAllDocuments(result.DocIds);
          },
        },
      ],
    });
  };

  DownloadAllDocuments = async (docIds: string) => {
    try {
      const url = await api.documents.getMultiDocZipUrl(
        {
          zipFileName: "VimInvoices",
        },
        docIds.split(",").map((id) => +id)
      );
      const downloadUrl = config.API_URL.replace(/\/+$/, "") + url;
      window.open(downloadUrl);
      ModalRef.hideDialog();
    } catch (e) {
      showSomeError(e);
    } finally {
    }
  };

  AddNewInvoice = () => {
    RunScriptLocal("VIMInvoices_Add");
  };
}

class VIMInvoicesDetails extends Component<IDashboardChildProps, state> {
  render() {
    let id = this.props.rowData?.InvoiceId;
    if (!id) return null;

    return (
      <VIMDetails
        VIMId={id}
        onDelete={this.props.props?.onRefresh}
        onRefresh={this.props.props?.onRefresh}
        unSelectRow={this.props.unSelect}
      />
    );
  }
}

export default VIMDashboardPage;
