import moment from "moment";

export const getDurationTimeInClockType = (
  start: string,
  finish: string
): string => {
  moment(start).utc().format();
  return moment
    .utc()
    .startOf("day")
    .add(moment(finish).diff(moment(start), "minutes"), "minutes")
    .format("HH:mm");
};

const getDurationTimeWithLabel =
  (labelHours: string, labelMinute: string) =>
  (start: string, finish: string) => {
    const duration = moment
      .utc()
      .startOf("day")
      .add(moment(finish).diff(moment(start), "minutes"), "minutes");

    return duration.get("hours") === 0
      ? duration.format(`m[${labelMinute}]`)
      : duration.format(`H[${labelHours}] m[${labelMinute}]`);
  };

export const getDurarionWithLabelHMin = getDurationTimeWithLabel("h", "min");
export const getDurarionWithLabelHM = getDurationTimeWithLabel("h", "m");

export const getLocalTimeMomentFromServerTimeStr = (serverTime: string) => {
  return moment.utc(serverTime).local();
};
