import styles from "./tc.module.scss";
import { Chip } from "@progress/kendo-react-buttons";
import { ITotalDurationProps } from "./Map/interfaces";
import { useCallback } from "react";
import { formatHoursDuration } from "../../core/tools/formats";

const TotalDurations = (props: { values: ITotalDurationProps }) => {
  const {
    clockedHours,
    approvedHours,
    lunchHours,
    totalHours,
    className,
    isOriginal,
    totalMileage,
  } = props.values;
  const renderCell = useCallback(
    (
      title: string,
      text: string,
      type?: "success" | "error" | "info" | "warning"
    ) => {
      return (
        <div className={styles.TotalDurationsCell}>
          <span className={styles.TotalDurationsName}>{title}</span>
          <Chip themeColor={type} text={text} />
        </div>
      );
    },
    []
  );

  return (
    <div className={`${styles.TotalDurations} ${className}`}>
      {renderCell("Clocked", formatHoursDuration(clockedHours))}
      {renderCell("Approved", formatHoursDuration(approvedHours), "success")}
      {renderCell(
        isOriginal ? "Audit Lunch" : "Lunch Deduction",
        lunchHours ? formatHoursDuration(lunchHours) : "",
        lunchHours ? "warning" : undefined
      )}
      {renderCell("Total", formatHoursDuration(totalHours), "success")}
      {typeof totalMileage === "number" &&
        renderCell("Mileage", totalMileage + "", "info")}
    </div>
  );
};

export default TotalDurations;
