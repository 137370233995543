import React, { useMemo } from "react";
import {
  SQL_DB_TK_Review_Response_AdjustedTimeLine,
  SQL_DB_TK_Review_Response_TETimeLine,
} from "../../../core/api/generated/conterra";
import {
  formatTimeDiff,
  ResetSecondsDateString,
} from "../../../helpers/helpers";
import moment from "moment/moment";

export const TETooltipContent = (props: {
  data:
    | SQL_DB_TK_Review_Response_TETimeLine
    | SQL_DB_TK_Review_Response_AdjustedTimeLine;
  originalTe?: SQL_DB_TK_Review_Response_TETimeLine;
  isFlag?: boolean;
}) => {
  const { costTypeName, start, finish, approvedHours, hours } = props.data;
  const { approvedDuration, hoursDuration, startFormatted, finishFormatted } =
    useMemo(() => {
      const startValue = ResetSecondsDateString(start);
      const finishValue = ResetSecondsDateString(finish);
      const startMoment = moment(startValue);
      const finishMoment = moment(finishValue);
      return {
        approvedDuration: formatTimeDiff(approvedHours * 3600),
        hoursDuration: formatTimeDiff(hours * 3600),
        startFormatted: startMoment.format("LT"),
        finishFormatted: finishMoment.format("LT"),
      };
    }, [start, finish, approvedHours, hours]);
  const {
    event: Event,
    location: Location,
    site: Site,
    comments: Comments,
  } = props.originalTe || {};
  return (
    <div>
      {!!props.isFlag ? (
        <div>{startFormatted}</div>
      ) : (
        <div>
          {startFormatted} - {finishFormatted}
        </div>
      )}
      {!!Event && <div>Event: {Event}</div>}
      <div>Cost Type: {costTypeName || ""}</div>
      {!!Location && <div>Location: {Location}</div>}
      {!!Site && <div>Site: {Site}</div>}
      {Site === null && Location === null && <div>Location: Unknown</div>}

      {!props.isFlag && <div>Duration: {hoursDuration}</div>}
      {!props.isFlag && <div>Approved: {approvedDuration}</div>}
      {!!Comments && (
        <div
          style={{
            maxHeight: 160,
            overflow: "auto",
            scrollbarColor: "#999 #555",
            scrollbarWidth: "thin",
          }}
        >
          Comment: {Comments}
        </div>
      )}
    </div>
  );
};
