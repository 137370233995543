import { CustomStatusPanelProps } from "ag-grid-react";
import { useCallback, useEffect, useState } from "react";

export default function StatusPanel_ClearFilters(
  props: CustomStatusPanelProps
) {
  const { api: gridApi } = props;

  const [count, setCount] = useState(0);
  const updateStatusBar = useCallback(
    () => setCount(Object.keys(gridApi.getFilterModel()).length),
    [gridApi]
  );

  useEffect(() => {
    updateStatusBar();
    gridApi.addEventListener("filterChanged", updateStatusBar);

    return () => {
      if (!gridApi.isDestroyed()) {
        gridApi.removeEventListener("filterChanged", updateStatusBar);
      }
    };
  }, [gridApi, updateStatusBar]);
  const clearFilters = useCallback(() => {
    gridApi.setFilterModel(null);
  }, [gridApi]);

  if (!count) return <span></span>;
  // TODO tooltip with column header names
  return (
    <div className="ag-status-name-value link" onClick={clearFilters}>
      <span className="component">Clear {count} Filters</span>
    </div>
  );
}
