import styles from "./sidebar.module.scss";

interface IProps {
  isRightSide?: boolean;
  opened: boolean;
  btnText: string;
  toggle: () => void;
  btnTextStyle?: React.CSSProperties;
  additionalBtns?: JSX.Element | null;
}

const SideBarToggleBtn = (props: IProps) => {
  const { isRightSide, opened, btnText, btnTextStyle, toggle, additionalBtns } =
    props;

  let iconClass = "";
  if (isRightSide)
    iconClass = opened
      ? "k-icon k-font-icon k-i-arrow-chevron-right"
      : "k-icon k-font-icon k-i-arrow-chevron-left";
  else
    iconClass = opened
      ? "k-icon k-font-icon  k-i-arrow-chevron-left"
      : "k-icon k-font-icon k-i-arrow-chevron-right";
  return (
    <div className={`${styles.Btn} ${isRightSide ? styles.RTL : ""}`}>
      <div className={styles.BtnClickable} onClick={toggle}>
        <div className={styles.Arrow}>
          <span className={iconClass}></span>
        </div>
      </div>
      {additionalBtns ? (
        <div className={styles.BtnAdditionalBtns}>{additionalBtns}</div>
      ) : null}

      {btnText && (
        <span
          className={`${styles.BtnText} ${isRightSide ? styles.RTL : ""}`}
          style={{ ...btnTextStyle }}
        >
          {btnText}
        </span>
      )}
    </div>
  );
};

export default SideBarToggleBtn;
