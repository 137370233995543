import { tabId } from "../../Tabs/interfaces";
import React, { useEffect, useMemo, useRef, useState } from "react";
import api from "../../../core/api/api";
import { GetTabId, TABS } from "../../Tabs/helpers";
import { IRenderToolbarProps, ITabSetting, ITabsToolbar } from "../interfaces";
import styles from "../tabsnew.module.scss";
import TabsToolbar from "../TabsToolbar";
import {
  useLatestNotEmptyValue,
  useLazyAsync,
} from "../../../core/tools/Hooks";

export interface ITCTabsNewProps {
  // todo tcId fields from row data or TCId from old dashboard
  timeCardId: number;
  initialTab?: tabId;

  refreshTC(): void;

  // rowdata from TKDashb or tc from TKReview or refresher from card
  reloadDependency: unknown;
}

const TCTabsNew = (props: ITCTabsNewProps) => {
  const { timeCardId, initialTab, refreshTC, reloadDependency } = props;
  const currentTcId = useLatestNotEmptyValue(timeCardId);
  const currentReloadDependency = useLatestNotEmptyValue(reloadDependency);
  const [getTCInfo, { data: tcInfo, loading }] = useLazyAsync(
    api.sql.getInfoTkTimeCards
  );
  useEffect(() => {
    if (currentTcId) getTCInfo({ tcId: currentTcId });
  }, [currentTcId, currentReloadDependency]);
  const tabsList: ITabSetting[] = useMemo(() => {
    const {
      activeRequestsCount,
      importantAuditResults,
      notConfirmedAttestationCount,
    } = tcInfo?.[0] || {};
    return [
      { id: "TCTimelines" },
      { id: "TCAllocation" },
      {
        id: "TCRequests",
        counter: !!activeRequestsCount ? activeRequestsCount : "",
      },
      {
        id: "TCAudits",
        counter: !!importantAuditResults ? importantAuditResults : "",
      },
      {
        id: "TCAttestations",
        counter: !!notConfirmedAttestationCount
          ? notConfirmedAttestationCount
          : "",
      },
      { id: "TCHistory" },
    ];
  }, [tcInfo]);

  const [activeTabId, setActiveTabId] = useState<tabId>(
    GetTabId(initialTab || tabsList[0])
  );

  useEffect(() => {
    if (initialTab) {
      setActiveTabId(GetTabId(initialTab));
    }
  }, [initialTab]);

  const initedTabsRef = useRef<tabId[]>([activeTabId]);
  useEffect(() => {
    if (!initedTabsRef.current.includes(activeTabId)) {
      initedTabsRef.current.push(activeTabId);
    }
  }, [activeTabId]);

  const renderToolbar = (specificSettings: IRenderToolbarProps) => {
    const commonSettings: ITabsToolbar = {
      isLoading: loading,
      tabsList: tabsList,
      activeTabId,
      onChangeTab: setActiveTabId,
    };

    return (
      <TabsToolbar
        {...commonSettings}
        {...specificSettings}
        refresh={refreshTC}
      />
    );
  };

  if (!currentTcId) return null;
  return (
    <>
      {tabsList.map((tab) => {
        const tabId = GetTabId(tab);
        const isActiveTab = tabId === activeTabId;
        const isInited = initedTabsRef.current.includes(tabId);
        if (!isActiveTab && !isInited) return null;

        const { component: TabComponent } = TABS[tabId];
        const tabProps = {
          isActive: isActiveTab,
          tcId: currentTcId,
          renderToolbar,
          refreshTC,
          reloadDependency: currentReloadDependency,
        };

        return (
          <div
            key={tabId}
            className={`${styles.ContentWrap} ${
              !isActiveTab ? styles.HiddenTab : ""
            }`}
          >
            <TabComponent {...tabProps} />
          </div>
        );
      })}
    </>
  );
};

export default TCTabsNew;
