import BaseComponent from "../../../Components/BaseComponent";
import SideBar from "../../../Components/Common/SideBar/SideBar";
import VIMDetails from "../Details/VIMDetails";
import VIMList from "./VIMList";
import Arrows from "./Arrows";
import React from "react";
import { IListNavigation, ISelectedInfo } from "../interfaces";

interface props {
  isActive: boolean;
}

interface state {
  selectedInfo: ISelectedInfo | null;
}

class VIMReview extends BaseComponent<props, state> {
  listRef: any;
  detailsRef: any;
  listNavigation?: IListNavigation;

  constructor(props: props) {
    super(props);

    this.state = {
      selectedInfo: null,
    };
  }

  render() {
    const { selectedInfo } = this.state;
    return (
      <SideBar
        btnText={selectedInfo?.Name}
        defaultOpened={true}
        mode="overlay"
        width={400}
        additionBtns={
          selectedInfo ? (
            <Arrows
              disabledPrev={selectedInfo.prevDisabled}
              disabledNext={selectedInfo.nextDisabled}
              onPrev={() => this.listNavigation?.selectPrev()}
              onNext={() => this.listNavigation?.selectNext()}
            />
          ) : null
        }
        insideContent={
          <VIMList
            isActive={this.props.isActive}
            selected={
              selectedInfo
                ? {
                    Id: selectedInfo.Id,
                    Name: selectedInfo.Name,
                  }
                : null
            }
            onSelect={this.Select}
            onRefresh={this.RefreshDetails}
            ref={(ref) => {
              this.listRef = ref;
            }}
          />
        }
        outsideContent={
          this.props.isActive ? (
            <VIMDetails
              key={"details" + selectedInfo?.Id}
              VIMId={selectedInfo?.Id || null}
              onRefresh={this.ListItemRefresh}
              onDelete={this.OnDeleteInvoice}
              ref={(ref) => {
                this.detailsRef = ref;
              }}
            />
          ) : undefined
        }
      />
    );
  }

  Select = (
    selectedInfo: ISelectedInfo | null,
    navigation: IListNavigation
  ) => {
    this.listNavigation = navigation;
    this.setState({ selectedInfo });
  };

  ListItemRefresh = (VIMId: number) => {
    this.listRef.ExternalRefreshInvoice(VIMId);
  };

  RefreshDetails = () => {
    if (this.detailsRef && this.detailsRef.ExternalRefreshDetails)
      this.detailsRef.ExternalRefreshDetails();
  };

  OnDeleteInvoice = (VIMId: number) => {
    this.listRef.DeleteInvoice(VIMId);
  };

  Refresh = () => {
    this.listRef.RefreshVIMInvoices();
  };
}

export default VIMReview;
