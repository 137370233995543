import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./livemap.module.scss";
import { Popover, Tooltip } from "@progress/kendo-react-tooltip";
import { ITE, ITimeLineRowRenderItem } from "./interfaces";
import throttle from "lodash.throttle";
import { GetDocumentUrl } from "../../helpers/helpers";

interface IProps {
  isShownStateAllocation?: boolean;
  action: any;
  start?: string;
  finish?: string;
  item: ITimeLineRowRenderItem;
  pinnedColor: string | null;
  listId: number;
  clockInId: number;
  onTrackItemMouseEvent: (e: React.MouseEvent<HTMLDivElement>) => void;
  tcTE?: ITE;
  clockInOutText?: string;
}

const TERow = (props: IProps) => {
  const {
    listId,
    clockInId,
    item,
    pinnedColor,
    tcTE,
    start,
    finish,
    clockInOutText,
    onTrackItemMouseEvent,
  } = props;
  const { Id, SiteName, Duration, Type, IsUnscheduledSite, CostTypeColor } =
    item;
  const [isShownPopover, setIsShownPopover] = useState(false);
  const [selfieUrl, setSelfieUrl] = useState("");
  useEffect(() => {
    if (tcTE?.SelfieId) getSelfieUrl(tcTE.SelfieId);
  }, [tcTE]);
  const getSelfieUrl = useCallback(
    async (id: number) => {
      const url = await GetDocumentUrl(id, true, true);
      if (url) setSelfieUrl(url);
    },
    [setSelfieUrl]
  );
  const elementRef = useRef<HTMLDivElement | null>(null);

  const isClockInOut = Type === "OUT" || Type === "IN";

  const renderPopoverContent = () => {
    if (!isClockInOut && tcTE) {
      return (
        <div style={{ maxWidth: 200 }}>
          {IsUnscheduledSite && <b>Unscheduled visit</b>}
          {!!item.ActualDurationString && (
            <div>Duration: {item.ActualDurationString}</div>
          )}
          {!!item.ApprovedDurationString && (
            <div>Approved: {item.ApprovedDurationString}</div>
          )}
          <div>Event: {tcTE.Event}</div>
          {!!tcTE.CostTypeTitle && <div>Cost Type: {tcTE.CostTypeTitle}</div>}
          {!!tcTE.SiteTitle && <div>Site: {tcTE.SiteTitle}</div>}
          {!!tcTE.WorkOrderSiteTitle &&
            tcTE.WorkOrderSiteTitle !== tcTE.SiteTitle && (
              <div>Work Order Site: {tcTE.WorkOrderSiteTitle}</div>
            )}
          {!!tcTE.WorkOrderTitle && (
            <div>Work Order: {tcTE.WorkOrderTitle}</div>
          )}
          {!!tcTE.LocationTitle && <div>Location: {tcTE.LocationTitle}</div>}
        </div>
      );
    }
    if (IsUnscheduledSite) return <div>Unscheduled visit</div>;
  };

  const debouncedMouseEventHandler = useCallback(
    throttle((e: React.MouseEvent<HTMLDivElement>) => {
      if (!isClockInOut && (IsUnscheduledSite || tcTE)) {
        const value = e.type === "mouseover";
        setIsShownPopover(value);
      }
    }, 200),
    [setIsShownPopover]
  );

  let content = "";
  switch (Type) {
    case "B":
      content = "BAD GPS";
      break;
    case "N":
      content = "No GPS";
      break;
    case "S":
      content = SiteName || "";
      break;
    case "OUT":
    case "IN":
      content = clockInOutText || "";
      break;
    case "LUNCH":
      content = "LUNCH";
      break;
  }
  const costTypeBG =
    item.Type === "N"
      ? "repeating-linear-gradient(to bottom," +
        CostTypeColor +
        "," +
        CostTypeColor +
        " 3px,#fff 3px,#fff 6px)"
      : CostTypeColor;

  const renderClockInOutSign = () => {
    if (!isClockInOut) return null;
    if (tcTE?.SelfieId) {
      return (
        <Tooltip
          style={{ width: 270 }}
          parentTitle={true}
          showCallout={false}
          content={() => {
            return (
              <div className={styles.DevicePeriodClockInPhotoBox}>
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  src={selfieUrl}
                  alt={"Selfie"}
                />
              </div>
            );
          }}
        >
          <span
            className={`mdi mdi-account-box-outline ${
              Type === "IN"
                ? styles.DevicePeriodClockInIcon
                : styles.DevicePeriodClockOutIcon
            }`}
            title={tcTE?.SelfieId + ""}
          ></span>
        </Tooltip>
      );
    }
    let text = "";
    if (Type === "OUT") {
      if (tcTE?.Event === "FORCE CLOCKOUT") text = "F";
      else if (tcTE?.Event === "REMOTE CLOCKOUT") text = "R";
    }
    return (
      <span
        className={`${
          Type === "IN"
            ? styles.DevicePeriodClockIn
            : styles.DevicePeriodClockOut
        }`}
      >
        {text}
      </span>
    );
  };

  return (
    <React.Fragment>
      {!!start && <span className={styles.DevicePeriodTime}>{start}</span>}
      <div
        key={Id}
        className={`${styles.TERow} ${
          isClockInOut ? styles.TERowClockType : ""
        }`}
        data-periodid={Id}
        data-clockinid={clockInId}
        data-listid={listId}
        data-eventtype={Type}
        data-mainobjectid={item.MainObjectId}
        onClick={props.onTrackItemMouseEvent}
      >
        <div className={styles.TERowLeft}>
          {props.action}
          {renderClockInOutSign()}
          {!isClockInOut && (
            <span
              className={`${styles.DevicePeriodTELine} ${
                !item.IsApprovedHours ? styles.Thin : ""
              }`}
              style={{ background: costTypeBG }}
            >
              {item.Number !== undefined && (
                <span
                  className={styles.DevicePeriodNumber}
                  style={{
                    background: CostTypeColor,
                    borderColor: pinnedColor || "#555", // ??
                  }}
                >
                  {item.Number}
                </span>
              )}
            </span>
          )}
        </div>
        <div
          ref={elementRef}
          onMouseOver={debouncedMouseEventHandler}
          onMouseOut={debouncedMouseEventHandler}
          className={styles.TERowInfoIcon}
          data-periodid={Id}
          data-clockinid={clockInId}
          data-listid={listId}
          data-eventtype={Type}
          data-mainobjectid={item.MainObjectId}
        >
          <span className={"mdi mdi-information-outline"}></span>
        </div>
        {!!Duration && (
          <span className={styles.ListItemDuration}>{Duration}</span>
        )}
        <span className={styles.DevicePeriodLocation}>
          {IsUnscheduledSite && (
            <span
              className={"mdi mdi-exclamation"}
              style={{ color: "#ff4566" }}
            ></span>
          )}
          <span>{content}</span>
        </span>
        <div
          ref={elementRef}
          onMouseOver={onTrackItemMouseEvent}
          onMouseOut={onTrackItemMouseEvent}
          className={styles.TERowOnHoverLayer}
          data-periodid={Id}
          data-clockinid={clockInId}
          data-listid={listId}
          data-eventtype={Type}
          data-mainobjectid={item.MainObjectId}
        ></div>
      </div>
      {(!!IsUnscheduledSite || !!tcTE) && (
        <Popover
          animate={false}
          show={isShownPopover}
          callout={false}
          anchor={elementRef && elementRef.current}
          margin={
            props.isShownStateAllocation
              ? {
                  horizontal: 28,
                  vertical: 0,
                }
              : undefined
          }
        >
          {renderPopoverContent()}
        </Popover>
      )}
      {!!finish && <span className={styles.DevicePeriodTime}>{finish}</span>}
    </React.Fragment>
  );
};

export default TERow;
