import { CustomToolPanelProps } from "ag-grid-react";
import { useCallback } from "react";
import { PropsSQLDBStatusResponse } from "../../../core/api/generated/conterra";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { settingsStorage } from "../../../helpers/settings";
import { useRefresher } from "../../../core/tools/Hooks";
import ButtonLink from "../../../Components/Common/Buttons/ButtonLink";
import { ColumnState } from "ag-grid-community";

const storageKey = "aggrid-views-statusBD";

function readViews(): IView[] {
  const viewsJSON = settingsStorage.getForCurrentUser(storageKey);
  return viewsJSON ? JSON.parse(viewsJSON) : [];
}

function saveViews(views: IView[]) {
  settingsStorage.setForCurrentUser(storageKey, JSON.stringify(views));
}

interface IView {
  title: string;
  id: string;
  columnStateJSON: string;
  rowGroupColIdsJSON: string;
  pivotMode: boolean | undefined;
}

export interface ViewsToolPanelProps extends CustomToolPanelProps {
  ownerStatsColIds: string[];
}

export default function ViewsToolPanel(props: ViewsToolPanelProps) {
  const { api: gridApi, ownerStatsColIds } = props;

  const pivotByOwner = useCallback(() => {
    gridApi.updateGridOptions({ pivotMode: true });
    gridApi.setValueColumns(ownerStatsColIds);
    gridApi.setRowGroupColumns([PropsSQLDBStatusResponse.owner]);
    gridApi.autoSizeColumns([PropsSQLDBStatusResponse.owner]);
    gridApi.closeToolPanel();
  }, [gridApi, ownerStatsColIds]);

  const resetState = useCallback(() => {
    gridApi.resetColumnState();
    gridApi.updateGridOptions({ pivotMode: false });
    gridApi.closeToolPanel();
  }, [gridApi]);

  const refresher = useRefresher();
  const saveView = useCallback(() => {
    const colState = gridApi.getColumnState();
    const rowGroupColIds = gridApi
      .getRowGroupColumns()
      .map((x) => x.getColId());
    const views = readViews();
    views.push({
      id: new Date().valueOf().toString(),
      columnStateJSON: JSON.stringify(colState),
      rowGroupColIdsJSON: JSON.stringify(rowGroupColIds),
      title: `View #${views.length + 2}`,
      pivotMode: gridApi.getGridOption("pivotMode"),
    });
    saveViews(views);
    refresher();
  }, [gridApi, refresher]);
  const deleteView = useCallback(
    (id: string) => {
      const views = readViews();
      saveViews(views.filter((x) => x.id !== id));
      refresher();
    },
    [refresher]
  );
  const applyView = useCallback(
    (id: string) => {
      const views = readViews();
      const view = views.find((x) => x.id === id);
      if (!view) {
        alert("View description not found");
        return;
      }
      gridApi.applyColumnState({
        state: JSON.parse(view.columnStateJSON) as ColumnState[],
      });
      gridApi.setRowGroupColumns(JSON.parse(view.rowGroupColIdsJSON));
      gridApi.updateGridOptions({ pivotMode: view.pivotMode });
      gridApi.closeToolPanel();
    },
    [gridApi]
  );
  const currentViews = readViews();

  return (
    <>
      <div style={{ padding: 10 }}>
        <Button onClick={() => resetState()}>Default</Button>
      </div>
      <div style={{ padding: 10 }}>
        <Button onClick={() => pivotByOwner()}>Owner Stats</Button>
      </div>
      <div style={{ padding: 10 }}>
        <Button onClick={() => saveView()}>Save View</Button>
      </div>
      {currentViews.map((view) => (
        <div style={{ padding: 10 }} key={view.id}>
          <ButtonGroup>
            <Button onClick={() => applyView(view.id)}>{view.title}</Button>
            <ButtonLink onClick={() => deleteView(view.id)} text={"Delete"} />
          </ButtonGroup>
        </div>
      ))}
    </>
  );
}
