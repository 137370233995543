import FXCard from "../Common/FXCard/FXCard";
import { IFXCardProps } from "./interfaces";
import React, { useEffect, useState } from "react";
import { WindowActionsEvent } from "@progress/kendo-react-dialogs";
import TCMap from "../TC/Map";
import { GetReferenceRecordName } from "../../helpers/queries";

enum windowStage {
  DEFAULT = "DEFAULT",
  FULLSCREEN = "FULLSCREEN",
  MINIMIZED = "MINIMIZED",
}

interface IProps extends IFXCardProps {
  tcId: number;
  refreshTC: () => void;
}

const TCMapCard = (props: IProps) => {
  const { tcId } = props;
  const [title, setTitle] = useState("");
  const [stage, setStage] = useState<string>(windowStage.DEFAULT);

  useEffect(() => {
    const loadCardTitle = async () => {
      const Name = await GetReferenceRecordName(tcId);
      setTitle(Name);
    };
    loadCardTitle();
  }, []);

  const onCardStageChange = (stage: WindowActionsEvent) => {
    setStage(stage.state || windowStage.FULLSCREEN);
  };

  const onClose = () => {
    if (props.finally) props.finally();
  };

  return (
    <FXCard
      title={title || "Loading..."}
      onClose={onClose}
      stage={stage}
      onStageChange={onCardStageChange}
      initialWidth={890}
    >
      <TCMap
        tcId={tcId}
        refreshTC={props.refreshTC}
        modalTCMapWindowStage={stage}
        parentId={"TCMapCard"}
        isActive={true}
      />
    </FXCard>
  );
};

export default TCMapCard;
