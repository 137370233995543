import React from "react";
import Adjustment from "./Adjustment";
import { IAdjustedTimeLineItem } from "../interfaces";
import styles from "./adjustments.module.scss";
import moment from "moment/moment";

const Adjustments = (props: { data: IAdjustedTimeLineItem[] }) => {
  return (
    <>
      <div style={{ height: 5 }}></div>
      {props.data
        .sort((A, B) => (moment(A.start).isBefore(moment(B.start)) ? -1 : 1))
        .map((te, index) => {
          const nextItemStart = props.data[index + 1]?.startFormatted;
          return (
            <React.Fragment key={te.rowNumber + te.start}>
              <small
                key={te.startFormatted + te?.rowNumber}
                className={styles.TETime}
              >
                {te.startFormatted}
              </small>
              <Adjustment key={te.rowNumber + te.start} data={te} />
              {te.finishFormatted !== nextItemStart && (
                <>
                  <small
                    key={te.finishFormatted + te?.rowNumber}
                    className={styles.TETime}
                  >
                    {te.finishFormatted}
                  </small>
                  <div style={{ height: 30 }}></div>
                </>
              )}
            </React.Fragment>
          );
        })}
    </>
  );
};

export default Adjustments;
