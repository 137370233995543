import {
  SQL_DB_TK_Review_Response,
  SQL_DB_TK_Review_Response_DispatchInfo,
  SQL_DB_TK_TimeCardsForCompare_Response,
} from "../../../core/api/generated/conterra";
import styles from "./tkTimeLine.module.scss";
import React, { useMemo } from "react";
import { TimeLineItem } from "./TimeLineItem";
import { TimeLineFlagItem } from "./TimeLineFlagItem";
import { DispatchFlagItem } from "./DispatchFlagItem";

export const HorizontalTimeline = (props: {
  data: SQL_DB_TK_TimeCardsForCompare_Response | SQL_DB_TK_Review_Response;
  style?: React.CSSProperties;
  className?: string;
}) => {
  const {
    className,
    data: { adjustedTimeLine, teTimeLine, dispatchInfo },
  } = props;
  const dispatchesFlags = useMemo(() => {
    const startUnique: {
      [minutes: number]: SQL_DB_TK_Review_Response_DispatchInfo[];
    } = {};
    const finishUnique: {
      [minutes: number]: SQL_DB_TK_Review_Response_DispatchInfo[];
    } = {};

    dispatchInfo.forEach((d) => {
      const { clockOutBeforeMinutes, clockInAfterMinutes } = d;

      if (clockInAfterMinutes !== null) {
        if (!startUnique[clockInAfterMinutes]) {
          startUnique[clockInAfterMinutes] = [];
        }
        startUnique[clockInAfterMinutes].push(d);
      }
      if (clockOutBeforeMinutes !== null) {
        if (!finishUnique[clockOutBeforeMinutes]) {
          finishUnique[clockOutBeforeMinutes] = [];
        }
        finishUnique[clockOutBeforeMinutes].push(d);
      }
    });
    const start: {
      minutes: number;
      dispatches: SQL_DB_TK_Review_Response_DispatchInfo[];
    }[] = [];
    const finish: {
      minutes: number;
      dispatches: SQL_DB_TK_Review_Response_DispatchInfo[];
    }[] = [];
    for (let minutes in startUnique) {
      start.push({ minutes: +minutes, dispatches: startUnique[minutes] });
    }
    for (let minutes in finishUnique) {
      finish.push({ minutes: +minutes, dispatches: finishUnique[minutes] });
    }
    return [start, finish];
  }, dispatchInfo);
  if (!adjustedTimeLine.length && !teTimeLine.length) return <div></div>;
  const [startDispatches, finishDispatches] = dispatchesFlags;
  return (
    <div className={`${styles.Container} ${className}`}>
      <div className={`${styles.TimeLine}`}>
        {!!teTimeLine.length &&
          teTimeLine.map((item, i) => {
            const isClockIn = item.event.indexOf("CLOCKIN") > -1;
            const isClockOut = item.event.indexOf("CLOCKOUT") > -1;
            const isForceClockOut =
              isClockOut && item.event.indexOf("FORCE") > -1;
            const isLunch = item.costTypeCode === "LUNCH";
            const isWaivedLunch = isLunch && item.finish === item.start;

            if (isClockIn || isClockOut || isWaivedLunch) {
              return (
                <TimeLineFlagItem
                  key={item.id}
                  item={item}
                  isClockIn={isClockIn}
                  isClockOut={isClockOut}
                  isForceClockOut={isForceClockOut}
                  isWaivedLunch={isWaivedLunch}
                />
              );
            }

            return (
              <TimeLineItem
                key={item.id}
                data={item}
                originalTe={item}
                index={i}
              />
            );
          })}

        {!!adjustedTimeLine.length &&
          adjustedTimeLine.map((item, i) => (
            <TimeLineItem key={item.id} data={item} index={i} />
          ))}
        {startDispatches.map(({ minutes, dispatches }) => {
          return (
            <DispatchFlagItem
              key={minutes}
              minutes={minutes}
              isStart={true}
              dispatches={dispatches}
            />
          );
        })}
        {finishDispatches.map(({ minutes, dispatches }) => {
          return (
            <DispatchFlagItem
              key={minutes}
              minutes={minutes}
              isStart={false}
              dispatches={dispatches}
            />
          );
        })}
      </div>
      <div className={styles.TimeLineType}>
        {!!teTimeLine.length && <span>GPS</span>}
        {!!adjustedTimeLine.length && <span>Adjusted</span>}
      </div>
    </div>
  );
};
