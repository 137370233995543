import styles from "./tcDetails.module.scss";
import { IColumnSetting } from "../../Dashboard/interfaces";
import {
  SQL_DB_TimeCardTimeDetails_Response,
  SQL_DB_TK_GetTCSummary_Response_Details,
  SQL_DB_TK_TimeCardsForCompare_Response,
  SQL_DB_TK_TimeCardsForCompare_Response_TCAllocation,
} from "../../../core/api/generated/conterra";
import DashboardGrid from "../../Dashboard/Grid";

const DETAILS_COLUMNS: IColumnSetting[] = [
  {
    field: "start",
    title: "Start",
    type: "date",
    filterable: false,
    format: {
      type: "time",
    },
    gridWidth: 75,
  },
  {
    field: "finish",
    title: "Finish",
    type: "date",
    filterable: false,
    format: {
      type: "time",
    },
    gridWidth: 75,
  },
  {
    field: "costTypeName",
    title: "Cost Type",
    type: "string",
    filterable: false,
    format: {
      type: "string",
    },
    gridWidth: 115,
  },
  {
    field: "woName",
    title: "WO",
    type: "string",
    filterable: false,
    format: {
      type: "link",
      fieldId: "woId",
      refName: "FSMWorkOrders",
    },
    gridWidth: 430,
  },
  {
    field: "stateName",
    title: "State",
    type: "string",
    filterable: false,
    format: {
      type: "link",
      fieldId: "stateId",
      refName: "States",
    },
    gridWidth: 90,
  },
  {
    field: "totalHours",
    title: "Hours",
    type: "number",
    filterable: false,
    format: {
      type: "number",
    },
    aggregate: "sum",
    gridWidth: 70,
  },
  {
    field: "regularHours",
    title: "Regular",
    type: "number",
    filterable: false,
    format: {
      type: "number",
    },
    aggregate: "sum",
    gridWidth: 70,
  },
  {
    field: "overtimeHours",
    title: "Overtime",
    type: "number",
    filterable: false,
    format: {
      type: "number",
    },
    aggregate: "sum",
    gridWidth: 70,
  },
  {
    field: "doubleHours",
    title: "Double",
    type: "number",
    filterable: false,
    format: {
      type: "number",
    },
    aggregate: "sum",
    gridWidth: 70,
  },
  {
    field: "lunchHours",
    title: "Lunch",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
    gridWidth: 70,
  },
  {
    field: "isPW",
    title: "PW",
    type: "string",
    filterable: false,
    format: {
      type: "string",
      customFormat: true,
      getCustomFormat(
        dataItem: SQL_DB_TK_TimeCardsForCompare_Response_TCAllocation,
        field: string
      ): any {
        return dataItem[
          field as keyof SQL_DB_TK_TimeCardsForCompare_Response_TCAllocation
        ]
          ? "PW"
          : "";
      },
    },
    gridWidth: 50,
  },
  {
    field: "overnight",
    title: "Overnight",
    filterable: false,
    type: "string",
    gridWidth: 80,
  },
  {
    field: "isOTExclude",
    title: "OT Exclude",
    type: "string", // yes/no
    filterable: false,
    format: {
      type: "string",
      customFormat: true,
      getCustomFormat(
        dataItem: SQL_DB_TK_TimeCardsForCompare_Response_TCAllocation,
        field: string
      ): any {
        if (
          dataItem[
            field as keyof SQL_DB_TK_TimeCardsForCompare_Response_TCAllocation
          ] === null
        )
          return null;
        return (
          <div style={{ textAlign: "center" }}>
            {dataItem[
              field as keyof SQL_DB_TK_TimeCardsForCompare_Response_TCAllocation
            ]
              ? "Yes"
              : "No"}
          </div>
        );
      },
    },
    gridWidth: 80,
  },
  {
    field: "isLockedWageType",
    title: "Locked Wage",
    type: "string", // icon
    filterable: false,
    format: {
      type: "icon",
      customFormat: true,
      getCustomFormat(
        dataItem: SQL_DB_TK_TimeCardsForCompare_Response_TCAllocation,
        field: string
      ): any {
        return dataItem[
          field as keyof SQL_DB_TK_TimeCardsForCompare_Response_TCAllocation
        ] ? (
          <div style={{ textAlign: "center" }}>
            <span className={"mdi mdi-lock-outline"}></span>
          </div>
        ) : (
          ""
        );
      },
    },
    gridWidth: 70,
  },
];

export default function TCDetailsGrid(props: {
  data:
    | SQL_DB_TK_TimeCardsForCompare_Response[]
    | SQL_DB_TimeCardTimeDetails_Response[]
    | SQL_DB_TK_GetTCSummary_Response_Details[];
}) {
  const { data } = props;

  return (
    <DashboardGrid
      className={`${styles.Grid}`}
      containerClassName={styles.Container}
      isActive={true}
      isParentPage={true}
      columnsSettings={DETAILS_COLUMNS}
      getData={async () => data}
      excelFileName="TCDetails.xlsx"
      getRowKey={({
        rowNum,
      }: SQL_DB_TK_TimeCardsForCompare_Response_TCAllocation) => rowNum + ""}
      toolbar={{
        hide: true,
      }}
    ></DashboardGrid>
  );
}
