import { CustomToolPanelProps } from "ag-grid-react";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { useCallback } from "react";

export interface DataToolPanelProps extends CustomToolPanelProps {
  onDateChange: (newValue: Date | null) => void;
}

export default function DataToolPanel(props: DataToolPanelProps) {
  const { onDateChange } = props;
  const handleOnChange = useCallback(
    (event: DatePickerChangeEvent) => {
      onDateChange(event.value);
    },
    [onDateChange]
  );

  return (
    <div style={{ padding: 10 }}>
      <DatePicker onChange={handleOnChange} />
    </div>
  );
}
