import { ITabsToolbar } from "./interfaces";
import { Button, ToolbarSpacer } from "@progress/kendo-react-buttons";
import React, { useCallback } from "react";
import { tabId } from "../Tabs/interfaces";
import LoaderComponent from "../Common/Loader";
import TabsBtnsGroup from "./TabsBtnsGroup";
import { TabsToolbarComponent } from "../Common/Tabs/TabsComponents";

const TabsToolbar = (props: ITabsToolbar) => {
  const {
    isLoading,
    activeTabId,
    onChangeTab,
    tabsList,
    actionBtns,
    commonActionBtns,
    setDefaultFilters,
    isFiltersDefault,
    exportToExcel,
  } = props;

  const OnClickTab = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const { id } = e.currentTarget.dataset;
      if (id && onChangeTab) onChangeTab(id as tabId);
    },
    [onChangeTab]
  );

  const renderTabs = useCallback(
    (activeTabId?: tabId) => {
      return (
        <>
          {tabsList && (
            <TabsBtnsGroup
              tabsList={tabsList}
              activeTabId={activeTabId}
              onChange={onChangeTab}
            />
          )}
        </>
      );
    },
    [tabsList, OnClickTab]
  );

  const renderClearFiltersBtn = useCallback(() => {
    if (!setDefaultFilters) return null;
    return (
      <Button
        icon="filter-clear"
        title="Set Default Filters"
        fillMode={"flat"}
        onClick={setDefaultFilters}
        themeColor={!isFiltersDefault ? "primary" : undefined}
      />
    );
  }, [setDefaultFilters, isFiltersDefault]);

  const renderExportToExcelBtn = useCallback(() => {
    if (!exportToExcel) return null;
    return (
      <Button
        icon="file-excel"
        title="Export to Excel"
        fillMode={"flat"}
        onClick={exportToExcel}
      />
    );
  }, [exportToExcel]);

  return (
    <TabsToolbarComponent>
      <>
        {renderTabs(activeTabId)}
        <ToolbarSpacer />
        {isLoading ? (
          <div style={{ position: "relative", width: 27, height: 27 }}>
            <LoaderComponent
              size={"small"}
              style={{ background: "transparent" }}
            />
          </div>
        ) : (
          <>
            {commonActionBtns}
            {actionBtns}
            {renderClearFiltersBtn()}
            {renderExportToExcelBtn()}
          </>
        )}
      </>
    </TabsToolbarComponent>
  );
};

export default TabsToolbar;
