import OpenCardLink from "../../OpenCardLink";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import styles from "./timecard.module.scss";
import { SQL_DB_TK_GetTCSummary_Response_NonWageSummary } from "../../../core/api/generated/conterra";

const TCNonWages = ({
  data,
}: {
  data: SQL_DB_TK_GetTCSummary_Response_NonWageSummary[];
}) => {
  return (
    <Grid
      data={data}
      filterable={false}
      scrollable="none"
      className={`${styles.TCGrid} ${styles.TCGridWithoutTotalRow}`}
      style={{ marginTop: 16 }}
    >
      <Column
        field="Total"
        title="NON WAGE SUMMARY"
        headerClassName={"fw600"}
        cell={(props: any) => {
          const data: SQL_DB_TK_GetTCSummary_Response_NonWageSummary =
            props.dataItem;
          return (
            <td>
              <OpenCardLink
                text={data.woName}
                dataAttr={data.woId}
                refName="FSMWorkOrders"
              />
            </td>
          );
        }}
      />
      <Column field="piName" title="Payroll Item" width="280px" />
      <Column field="quantity" title="Quantity" width="110px" />
    </Grid>
  );
};

export default TCNonWages;
