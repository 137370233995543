import formStyles from "../card.module.scss";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import api from "../../../core/api/api";
import {
  useBooleanState,
  useLazyAsync,
  useLoadAction,
} from "../../../core/tools/Hooks";
import FXCard from "../../Common/FXCard/FXCard";
import LoaderComponent from "../../Common/Loader";
import { IInboxCardProps } from "../interfaces";
import OpenCardIconLink from "../OpenCardIconLink";
import {
  OpenJob,
  RunScriptAsync,
  RunScriptLocal,
} from "../../../helpers/runscripts";
import { Button, ButtonGroup, Toolbar } from "@progress/kendo-react-buttons";
import { IntegrationWithDesktopContext } from "../../../helpers/settings";
import InboxAttachmentLink from "./AttachmentLink";
import styles from "./inboxCard.module.scss";
import { ModalRef } from "../../Common/Modal/Modal";
import { TextArea } from "@progress/kendo-react-inputs";
import { showSomeError } from "../../../helpers/helpers";
import { SQL_DB_Inbox_GetJobInfo_Response_JobResults } from "../../../core/api/generated/conterra";

type tab = "text" | "attachments";
const tabs: { id: tab; text: string }[] = [
  {
    id: "text",
    text: "Thread",
  },
  {
    id: "attachments",
    text: "Attachments",
  },
];

const InboxCard = ({
  dataItem,
  refreshGrid,
  finally: handleClose,
}: IInboxCardProps) => {
  const processing = useBooleanState(false);
  const [textHtml, setTextHtml] = useState("");
  const getTextHtml = useLoadAction(async () => {
    const html = await api.script.inboxGetJobTreeHtml({ JobId: jobId });
    setTextHtml(html);
  }, []);
  const [getText, { data, loading }] = useLazyAsync(api.sql.dbInboxGetJobInfo);
  const { jobId, isRead } = dataItem;
  const [activeTabId, setActiveTabId] = useState<"text" | "attachments">(
    "text"
  );
  const { value: isIntegrationWithDesktop } = useContext(
    IntegrationWithDesktopContext
  );

  useEffect(() => {
    getText({ jobId });
    getTextHtml({ JobId: jobId });
  }, []);

  useEffect(() => {
    if (!data) return;
    if (!isRead) {
      api.script
        .inboxMarkJobsAsRead({ IsRead: true, JobIds: [{ Id: jobId }] })
        .then(refreshGrid);
    }
  }, [data, refreshGrid]);

  const attachments = useMemo(() => {
    const { attachments } = data?.[0] || {};
    return attachments;
  }, [data]);

  const jobResults = useMemo(() => {
    const { jobResults } = data?.[0] || {};
    return jobResults;
  }, [data]);

  const close = () => {
    handleClose?.();
  };

  const completeJob = useCallback(
    async (JobId: number, JobResult: string, Comments?: string) => {
      try {
        processing.setTrue();
        await RunScriptAsync("Routes_CompleteJob", {
          JobId,
          JobResult,
          Comments,
        });
        refreshGrid();
        close();
      } catch (e) {
        showSomeError(e);
      } finally {
        processing.setFalse();
      }
    },
    [close, processing, refreshGrid]
  );

  const askCommentForAction = useCallback(
    (
      jobId: number,
      {
        jobResult,
        commentsRequired,
      }: SQL_DB_Inbox_GetJobInfo_Response_JobResults
    ) => {
      let comment: any;
      ModalRef.showDialog({
        title: "Comment",
        buttons: [
          {
            text: "Cancel",
            action: () => {
              ModalRef.hideDialog();
            },
          },
          {
            text: "Ok",
            color: "primary",
            action: () => {
              let commentText = comment.element.current.value;
              if (commentsRequired && !commentText) {
                return;
              }
              ModalRef.hideDialog();
              completeJob(jobId, jobResult, commentText);
            },
          },
        ],
        children: (
          <TextArea
            required={commentsRequired}
            ref={(ref) => {
              comment = ref;
              if (ref && ref.focus) {
                ref?.focus();
              }
            }}
            rows={5}
            style={{ width: "100%" }}
          />
        ),
      });
    },
    [completeJob]
  );

  const onIframeLoad = (e: React.SyntheticEvent<HTMLIFrameElement>) => {
    // @ts-ignore
    const iframeDocument = e.target?.contentDocument;
    const links = iframeDocument.getElementsByTagName("a");
    if (links && links.length) {
      for (let el of links) {
        if (isIntegrationWithDesktop) {
          el.addEventListener("click", onIframeLinkClick);
        } else {
          el.classList.add("disabled");
        }
      }
    }
  };

  const onIframeLinkClick = (e: any) => {
    const { id, type } = e.currentTarget.dataset;
    RunScriptLocal("ConterraObject_Open", {
      ObjectID: id,
      Type: type,
    });
  };

  const swithchTab = (tab: tab) => {
    if (activeTabId !== tab) setActiveTabId(tab);
  };

  const renderTitle = () => {
    return (
      <>
        <OpenCardIconLink
          onClick={() => {
            OpenJob(jobId);
          }}
          title="Open Job Card"
        />
        <span>Inbox</span>
      </>
    );
  };

  const renderFooter = useCallback(() => {
    const showCompleteJobInDesktopBtn =
      isIntegrationWithDesktop && jobResults
        ? jobResults.findIndex((jr) => jr.jobResult === "Open Job") > -1
        : false;
    const restActions =
      jobResults?.filter((jr) => jr.jobResult !== "Open Job") || [];
    if (!showCompleteJobInDesktopBtn && !restActions.length) return null;
    return (
      <>
        <div className={`${formStyles.FormFooter} k-action-buttons`}>
          {showCompleteJobInDesktopBtn && (
            <Button
              key={"Open Job"}
              themeColor={"primary"}
              onClick={() => OpenJob(jobId)}
            >
              Complete in desktop
            </Button>
          )}
          {restActions.map((jr) => (
            <Button
              key={jr.jobResult}
              themeColor={"primary"}
              onClick={() => askCommentForAction(jobId, jr)}
            >
              {jr.jobResult}
            </Button>
          ))}
        </div>
      </>
    );
  }, [jobResults, askCommentForAction, isIntegrationWithDesktop]);

  return (
    <FXCard
      title={<span className={formStyles.HeaderTitle}>{renderTitle()}</span>}
      initialWidth={800}
      initialHeight={500}
      originalPaddings={true}
      onClose={close}
    >
      <div className={formStyles.FormWrapper}>
        <>
          <Toolbar>
            <ButtonGroup>
              {tabs.map(({ id, text }) => {
                return (
                  <Button
                    key={id}
                    data-id={id}
                    themeColor={activeTabId === id ? "primary" : undefined}
                    onClick={() => swithchTab(id as tab)}
                  >
                    {text}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Toolbar>

          {loading || getTextHtml.isProcessing || processing.value ? (
            <LoaderComponent />
          ) : (
            <>
              {activeTabId === "text" && !!textHtml && (
                <div style={{ flex: 1 }}>
                  <iframe
                    className={styles.TextIframe}
                    srcDoc={textHtml}
                    onLoad={onIframeLoad}
                  ></iframe>
                </div>
              )}
              {activeTabId === "attachments" && !!attachments?.length && (
                <div className={styles.AttachmentsContainer}>
                  {attachments.map((item) => (
                    <InboxAttachmentLink key={item.id} data={item} />
                  ))}
                </div>
              )}
            </>
          )}
          {renderFooter()}
        </>
      </div>
    </FXCard>
  );
};
export default InboxCard;
