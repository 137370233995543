import { AgGridReact } from "ag-grid-react";
import {
  CellClassParams,
  ColDef,
  Column,
  IAggFuncParams,
  ICellRendererParams,
  IRowNode,
  SetFilterModel,
  SideBarDef,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-enterprise";
import { useLazyAsync } from "../../../../core/tools/Hooks";
import api from "../../../../core/api/api";
import { useCallback, useEffect, useMemo, useState } from "react";
import LoaderComponent from "../../../../Components/Common/Loader";
import { formatFinancial } from "../../../../helpers/helpers";
import {
  PropsSQLDBStatusResponse,
  SQL_DB_Status_Response,
} from "../../../../core/api/generated/conterra";
import { GetRowIdFunc } from "ag-grid-community/dist/types/core/entities/gridOptions";
import OpenCardLink from "../../../../Components/OpenCardLink";
import ViewsToolPanel from "../../SideBar/Views";
import StatusPanel_ReloadRows from "../../StatusBar/Reload";
import StatusPanel_ClearFilters from "../../StatusBar/ClearFilters";
import DataToolPanel, { DataToolPanelProps } from "../../SideBar/Data";
import { ISO_DATE_FORMAT } from "../../../../core/tools/formats";
import moment from "moment";

function currencyFormatter(p: ValueFormatterParams) {
  if (isNaN(p.value)) {
    return "";
  }
  return "$" + formatFinancial(p.value);
}

function sumByBoolean(p: IAggFuncParams<SQL_DB_Status_Response>) {
  return p.values.reduce(
    (a, b) => a + (typeof b === "number" ? b : b ? 1 : 0),
    0
  );
}

enum COL_TYPES {
  currency = "currency",
  booleanForPivot = "booleanForPivot",
}

const getRowId: GetRowIdFunc<SQL_DB_Status_Response> = (p) => {
  return p.data.id.toString();
};

export default function AGGridStatusDashboard() {
  const [getMilestones, milestones] = useLazyAsync(api.sql.dbStatusMilestones);
  useEffect(() => getMilestones(), [getMilestones]);
  const MILESTONES_COLS = useMemo<ColDef[]>(() => {
    if (!milestones.data) return [];
    const columns: ColDef[] = [];
    milestones.data.forEach((ms) => {
      const lowerCamelCaseCode = ms.code[0].toLowerCase() + ms.code.slice(1);
      // Projected
      columns.push({
        field: "milestones." + lowerCamelCaseCode + "_Projected",
        headerName: ms.name + " Projected",
        cellDataType: "dateString",
      });
      // Actual
      columns.push({
        field: "milestones." + lowerCamelCaseCode + "_Actual",
        headerName: ms.name + " Actual",
        cellDataType: "dateString",
      });
    });
    return columns;
  }, [milestones]);

  const [getRowsMethod, rows] = useLazyAsync(api.sql.dbStatus);
  const [onDate, setOnDate] = useState<Date>();
  const getRows = useCallback(
    () =>
      getRowsMethod({
        onDate: onDate ? moment(onDate).format(ISO_DATE_FORMAT) : null,
      }),
    [getRowsMethod, onDate]
  );
  useEffect(getRows, [getRows]);

  const DEFAULT_COL_DEF = useMemo(
    () =>
      ({
        filter: true,
        floatingFilter: true,
        enableRowGroup: true,
        initialHide: true,
        enablePivot: true,
      } as ColDef),
    []
  );

  const COL_TYPES_DESCRIPTION = useMemo(
    () =>
      ({
        [COL_TYPES.currency]: {
          width: 150,
          valueFormatter: currencyFormatter,
          filter: "agNumberColumnFilter",
          aggFunc: "sum",
          enableValue: true,
        },
        [COL_TYPES.booleanForPivot]: {
          aggFunc: sumByBoolean,
          enableValue: true,
          cellRendererSelector: (
            p: ICellRendererParams<SQL_DB_Status_Response>
          ) => {
            if (typeof p.value === "number")
              return {
                component: (
                  pp: ICellRendererParams<SQL_DB_Status_Response>
                ) => (
                  <a
                    onClick={async () => {
                      if (!p.colDef?.colId) return;

                      let groupNode: IRowNode | null = p.node;
                      const filters: {
                        column: Column;
                        value: string | null;
                      }[] = [];
                      while (!!groupNode && groupNode.level >= 0) {
                        if (groupNode.rowGroupColumn) {
                          filters.push({
                            column: groupNode.rowGroupColumn,
                            value: groupNode.key,
                          });
                        }
                        groupNode = groupNode.parent;
                      }
                      filters.push({
                        column: p.column!,
                        value: "true",
                      });
                      p.api.updateGridOptions({ pivotMode: false });
                      p.api.setRowGroupColumns([]);
                      for (const filter of filters)
                        await p.api.setColumnFilterModel<SetFilterModel>(
                          filter.column,
                          {
                            filterType: "set",
                            values: [filter.value],
                          }
                        );
                      p.api.onFilterChanged();
                    }}
                  >
                    {pp.value}
                  </a>
                ),
              };
          },
        },
      } as Record<COL_TYPES, ColDef>),
    []
  );
  const CALCULATED_COLS = useMemo(
    () =>
      [
        {
          colId: "_SOEmpty",
          headerName: "No Sales Order",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            !p.data?.financial.salesOrder,
        },
        {
          colId: "_BudgetNotApproved",
          headerName: "Budget not Approved",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            !p.data?.financial.budgetApproved && !p.data?.excludeFromWIP,
        },
        {
          colId: "_BudgetNotApprovedHasSpend",
          headerName: `Budget Not Approved, Has Spend`,
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            !p.data?.financial.budgetApproved &&
            (p.data?.financial.totalSpend || 0) > 0 &&
            !p.data?.excludeFromWIP,
        },
        {
          colId: "_BudgetNotApprovedHasRevenue",
          headerName: "Budget Not Approved, Has Revenue",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            !p.data?.financial.budgetApproved &&
            (p.data?.financial.submittedInvoices || 0) > 0 &&
            !p.data?.excludeFromWIP,
        },
        {
          colId: "_BudgetNotEqualSalesOrder",
          headerName: "Budget doesn't equal Sales Order",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              !!p.data?.financial.soProjectedRevenueVariance &&
              !p.data?.excludeFromWIP
            );
          },
        },
        {
          colId: "_SpendExceedsBudget",
          headerName: "Spend Exceeds Budget",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            !!p.data?.financial.budgetApproved &&
            (p.data?.financial.totalSpend || 0) >
              (p.data?.financial.projectedSpend || 0) &&
            !p.data?.excludeFromWIP,
        },
        {
          colId: "_RevenueExceedsBudget",
          headerName: "Revenue Exceeds Budget",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            !!p.data?.financial.budgetApproved &&
            (p.data?.financial.submittedInvoices || 0) >
              (p.data?.financial.projectedRevenue || 0) &&
            !p.data?.excludeFromWIP,
        },
        {
          colId: "_CXStartPending",
          headerName: "CX Start Pending",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            !p.data?.milestones.constStart_Actual,
        },
        {
          colId: "_CXStartActualCXCompletePending",
          headerName: "CX Started and not Completed",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              !!p.data?.milestones.constStart_Actual &&
              !p.data?.milestones.consCompl_Actual
            );
          },
        },
        {
          colId: "_CXCompleteActualCOPApprovedPending",
          headerName: "CX Completed, COP not Approved",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              !!p.data?.milestones.consCompl_Actual &&
              !p.data?.milestones.copAppr_Actual
            );
          },
        },
        {
          colId: "_COPApprovedRemainingToInvoice",
          headerName: "COP Approved, w/ Remaining to Invoice",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              !!p.data?.milestones.copAppr_Actual &&
              (p.data?.financial.remainingToInvoice || 0) > 0
            );
          },
        },
        {
          colId: "_COPApprovedNoRemainingToInvoice",
          headerName: "COP Approved, No Remaining to Invoice",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              !!p.data?.milestones.copAppr_Actual &&
              !p.data?.financial.remainingToInvoice
            );
          },
        },
        {
          colId: "_COPApprovedReadyToClose",
          headerName: "COP Approved Ready to Close",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              !!p.data?.milestones.copAppr_Actual &&
              !p.data?.bom.opened &&
              !p.data?.financial.vimPoBalance &&
              !p.data?.financial.remainingToInvoice
            );
          },
        },
      ] as ColDef<SQL_DB_Status_Response>[],
    []
  );

  const COL_DEF = useMemo(
    () =>
      [
        {
          field: "rowNum",
          headerName: "#",
          pinned: true,
          width: 70,
          filter: false,
          suppressHeaderMenuButton: true,
          initialHide: false,
          aggFunc: "count",
        },
        {
          field: "number",
          headerName: "Build Plan",
          width: 300,
          initialHide: false,
          pinned: true,
          cellRenderer: (p: ICellRendererParams<SQL_DB_Status_Response>) => (
            <OpenCardLink
              text={p.value}
              refName={"FSMBuildPlans"}
              dataAttr={p.data?.id}
            />
          ),
        },
        {
          colId: "active",
          field: "active",
          headerName: "Active",
          width: 100,
          suppressHeaderMenuButton: true,
          initialHide: false,
          type: COL_TYPES.booleanForPivot,
        },
        {
          field: "project",
          headerName: "Project",
          initialHide: false,
        },
        { field: "scenario", headerName: "Scenario" },
        {
          field: "region",
          headerName: "Region",
        },
        {
          field: "market",
          headerName: "Market",
          initialHide: false,
        },
        { field: "customer", headerName: "Customer" },
        { field: "customerJob", headerName: "Customer Job" },
        { field: "carrier", headerName: "Carrier" },
        { field: "class", headerName: "Class" },
        { field: "category", headerName: "Category" },
        { field: "site", headerName: "Site" },
        { field: "siteAddress", headerName: "Site Address" },
        {
          field: "owner",
          headerName: "Build Plan Owner",
          initialHide: false,
        },
        { field: "profitCenter", headerName: "Profit Center" },
        { field: "roles.crewLead", headerName: "Crew Lead" },
        { field: "roles.copLead", headerName: "Cop Lead" },
        { field: "permitStatus", headerName: "Permit Status" },
        { field: "permitStatusComment", headerName: "Permit Status Comment" },
        { field: "cxStatus", headerName: "CX Status" },
        { field: "cxStatusComment", headerName: "CX Status Comment" },
        {
          field: "started",
          headerName: "Started",
          cellDataType: "dateString",
          initialHide: false,
        },
        {
          field: "financial.salesOrder",
          headerName: "Sales Order Total",
          headerTooltip: "Total of all Sales Orders in Build Plan",
          type: COL_TYPES.currency,
          initialHide: false,
        },
        {
          field: "financial.lastQuoteDate",
          headerName: "Sales Order Date",
          cellDataType: "dateString",
        },
        {
          field: "financial.lastQuoteSubmitted",
          headerName: "Submitted to Customer",
          cellDataType: "dateString",
        },
        {
          field: "financial.lastPODate",
          headerName: "PO Received",
          cellDataType: "dateString",
        },
        {
          field: "financial.unallocatedCustomerPO",
          headerName: "Unallocated Customer PO Total",
          type: COL_TYPES.currency,
          initialHide: false,
        },
        {
          field: "financial.allocatedCustomerPO",
          headerName: "Allocated Customer PO Total",
          type: COL_TYPES.currency,
          initialHide: false,
        },
        {
          field: "financial.projectedRevenue",
          headerName: "Budget Total Revenue",
          headerTooltip: "Total Planned Revenue from Budget Dashboard",
          type: COL_TYPES.currency,
          initialHide: false,
        },
        {
          field: "financial.sopoVariance",
          headerName: "Sales Order & PO Variance",
          headerTooltip: "SO Total - Unallocated PO Total - Allocated PO Total",
          type: COL_TYPES.currency,
        },
        {
          field: "financial.budgetStatus",
          headerName: "Budget Status",
          initialHide: false,
        },
        {
          field: "financial.budgetApproved",
          headerName: "Budget Approved",
          type: COL_TYPES.currency,
        },
        {
          field: "bom.initialSent",
          headerName: "BOM Sent to Warehouse",
          cellDataType: "dateString",
        },
        {
          field: "bom.total",
          headerName: "BOM Total",
          headerTooltip: "Total of All BOMs Amount Associated to Build Plan",
          type: COL_TYPES.currency,
        },
        {
          field: "bom.kitted",
          headerName: "Kitted to Date",
          headerTooltip: "Total of All Kitted BOMs",
          type: COL_TYPES.currency,
          cellStyle: (p: CellClassParams<SQL_DB_Status_Response>) => {
            const data = p.data?.bom;
            if (!data?.kitted || !data?.total) return;
            if (data.kitted > data.total) return { color: "red" };
            return;
          },
        },
        {
          field: "financial.budgetHrs",
          headerName: "Budgeted Field Labor Hours",
          headerTooltip: "Total Planned Labor Hours from Budget Dashboard",
          type: COL_TYPES.currency,
          initialHide: false,
        },
        {
          field: "financial.hoursConsumed",
          headerName: "Hours Consumed",
          headerTooltip: "Total Spend Labor Hours Allocated to Build Plan",
          initialHide: false,
          cellStyle: (p: CellClassParams<SQL_DB_Status_Response>) => {
            const data = p.data?.financial;
            if (!data?.hoursConsumed || !data?.budgetHrs) return;
            if (data.hoursConsumed > data.budgetHrs) return { color: "red" };
            return;
          },
        },
        {
          field: "financial.hoursRemaining",
          headerName: "Remaining Hours to Complete",
          headerTooltip: "Budgeted Field Labor Hours - Hours Consumed",
          initialHide: false,
        },
        { field: "pwByDefault", headerName: "PW by default" },
        {
          field: "pwLastUpdate",
          headerName: "PW last update",
        },
        {
          field: "completion",
          headerName: "Estimated Completion",
          headerTooltip: "% as Noted in Build Plan",
          initialHide: false,
        },
        {
          field: "financial.projectedSpend",
          headerName: "Projected Spend",
          headerTooltip: "Current Planned Spend from Budget Dashboard",
          type: COL_TYPES.currency,
          initialHide: false,
        },
        { field: "bom.opened", headerName: "Open BOMs" },
        {
          field: "financial.vimPoBalance",
          headerName: "VIM PO Balance",
          type: COL_TYPES.currency,
        },
        {
          field: "financial.totalSpend",
          headerName: "Total Spend to Date",
          headerTooltip: "Total Actual Spend from Budget Dashboard",
          type: COL_TYPES.currency,
          initialHide: false,
        },
        {
          field: "financial.totalSpendPercent",
          headerName: "% of Budgeted Spend",
          headerTooltip: "%, Total Spend to Date/Projected Spend",
          initialHide: false,
        },
        {
          field: "financial.remainingSpend",
          headerName: "Remaining Spend",
          headerTooltip: "Projected Spend - Total Spend To Date",
          type: COL_TYPES.currency,
        },
        {
          field: "financial.submittedInvoices",
          headerName: "Total Invoiced to Date",
          headerTooltip: "Total of Receivable and Paid Total from Sales Orders",
          type: COL_TYPES.currency,
          initialHide: false,
        },
        {
          field: "financial.lastInvoiceDate",
          headerName: "Invoice Submitted",
          cellDataType: "dateString",
        },
        {
          field: "financial.remainingToInvoice",
          headerName: "Remaining to Invoice",
          headerTooltip: "Total of Remaining To Bill in Sales Orders",
          type: COL_TYPES.currency,
          initialHide: false,
        },
        {
          field: "financial.readyToBill",
          headerName: "Ready To Bill",
          type: COL_TYPES.currency,
        },
        {
          field: "financial.projectedBillingDate",
          headerName: "Projected Billing Date",
        },
        {
          field: "financial.wip",
          headerName: "WIP",
          headerTooltip:
            "Variance Under Spend - Variance Under Billed, as Calculated in WIP Dashboard",
          initialHide: false,
        },
        {
          field: "excludeFromWIP",
          headerName: "Exclude from WIP",
          width: 100,
          initialHide: false,
        },
        {
          headerName: "Calculated Indicators",
          children: CALCULATED_COLS,
        },
        {
          headerName: "Milestones",
          children: MILESTONES_COLS,
        },
      ] as ColDef<SQL_DB_Status_Response>[],
    [CALCULATED_COLS, MILESTONES_COLS]
  );

  const sideBar = useMemo<SideBarDef>(() => {
    return {
      toolPanels: [
        {
          id: "data",
          labelDefault: "Data",
          labelKey: "data",
          iconKey: "grid",
          toolPanel: DataToolPanel,
          toolPanelParams: {
            onDateChange: setOnDate,
          } as DataToolPanelProps,
        },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
        {
          id: "views",
          labelDefault: "Views",
          labelKey: "views",
          iconKey: "pivot",
          toolPanel: ViewsToolPanel,
          toolPanelParams: {
            ownerStatsColIds: [
              PropsSQLDBStatusResponse.active,
              ...CALCULATED_COLS.map((x) => x.colId!),
            ],
          },
        },
      ],
      defaultToolPanel: "",
    };
  }, [CALCULATED_COLS, setOnDate]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        {
          statusPanel: StatusPanel_ClearFilters,
          align: "left",
        },
        {
          statusPanel: () => {
            if (!onDate) return <span></span>;
            return (
              <div className="ag-status-name-value">
                <span className="component">
                  {`Data on ${moment(onDate).format("L")}`}
                </span>
              </div>
            );
          },
          align: "left",
        },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
        {
          statusPanel: StatusPanel_ReloadRows,
          align: "right",
          statusPanelParams: {
            getRows,
          },
        },
      ],
    };
  }, [getRows, onDate]);

  if (milestones.loading) return <LoaderComponent />;

  return (
    <div
      className={"ag-theme-quartz"}
      style={{ width: "100%", height: "100%" }}
    >
      <AgGridReact
        sideBar={sideBar}
        statusBar={statusBar}
        getRowId={getRowId}
        rowData={rows.data}
        columnTypes={COL_TYPES_DESCRIPTION}
        columnDefs={COL_DEF}
        loading={rows.loading}
        defaultColDef={DEFAULT_COL_DEF}
        enableCharts={true}
        grandTotalRow={"bottom"}
        suppressAggFuncInHeader={true}
        pivotPanelShow={"onlyWhenPivoting"}
        rowGroupPanelShow={"onlyWhenGrouping"}
        cellSelection={true}
      />
    </div>
  );
}
