import React from "react";
import { simpleObject } from "../../helpers/interfaces";
import { ReferenceRecordsDataSource } from "../../helpers/queries";
import api from "../../core/api/api";
import BaseComponent from "../../Components/BaseComponent";
import {
  IColumnSetting,
  IFilterSetting,
} from "../../Components/Dashboard/interfaces";
import DashboardGrid from "../../Components/Dashboard/Grid";
import { SQL_DB_Audits_Response } from "../../core/api/generated/conterra";
import OpenCardLink from "../../Components/OpenCardLink";
import moment from "moment/moment";
import CardManagement from "../../Components/Cards/CardManagement";

interface props {
  isActive: boolean;
  switcher?: any;
  onChangeGridData?: (data: Array<simpleObject>) => void;
}

interface state {}

class AuditResults extends BaseComponent<props, state> {
  gridRef: any = React.createRef();
  COLUMNS: Array<IColumnSetting>;
  FILTERS: Array<IFilterSetting> = [];

  constructor(props: props) {
    super(props);
    this.state = {};

    this.COLUMNS = [
      {
        field: "rowNum",
        title: "#",
        type: "string",
        gridWidth: 40,
        filterable: false,
        columnMenu: false,
        aggregate: "count",
      },
      {
        field: "date",
        title: "Date",
        type: "date",
        format: {
          type: "date",
          customFormat: true,
          getCustomFormat: (rowData: SQL_DB_Audits_Response) => {
            return (
              <OpenCardLink
                text={moment(rowData.date).format("L")}
                refName="TKTimeCards"
                dataAttr={rowData.tcId}
              />
            );
          }, //formatDateColumn,
        },
      },
      {
        field: "employeeName",
        title: "Employee",
        type: "string",
        gridWidth: 200,
        format: {
          type: "link",
          fieldId: "employeeId",
          refName: "Employees",
        },
      },
      {
        field: "periodName",
        title: "Payroll Period",
        type: "string",
        minWidth: 200,
        format: {
          type: "string",
          fieldId: "periodId",
        },
      },
      {
        field: "firstClockIn",
        title: "First Clock In",
        type: "date",
        gridWidth: 130,
        format: {
          type: "datetime",
        },
      },
      {
        field: "lastClockOut",
        title: "Last Clock Out",
        type: "date",
        gridWidth: 130,
        format: {
          type: "datetime",
        },
      },
      {
        field: "clockedHours",
        title: "Clocked Hours",
        type: "number",
        gridWidth: 90,
        aggregate: "sum",
        format: {
          type: "number",
        },
      },
      {
        title: "Lunch Hours",
        type: "parent",
        columns: [
          {
            field: "auditLunchHours",
            title: "Audit",
            type: "number",
            gridWidth: 90,
            format: {
              type: "number",
            },
          },
          {
            field: "manualLunchHours",
            title: "Manual",
            type: "number",
            gridWidth: 90,
            format: {
              type: "number",
            },
          },
        ],
      },
      {
        field: "approvedHours",
        title: "Approved Hours",
        type: "number",
        gridWidth: 90,
        aggregate: "sum",
        format: {
          type: "number",
        },
      },
      {
        title: "audit Detail",
        type: "parent",
        columns: [
          {
            field: "auditRuleName",
            title: "Audit Rule",
            type: "string",
            minWidth: 120,
            format: {
              type: "string",
              fieldId: "auditRuleId",
            },
          },
          {
            field: "resultText",
            title: "Text",
            type: "string",
            gridWidth: 70,
            format: {
              type: "icon",
              iconClass: "k-icon k-font-icon k-i-file-txt",
              tooltipProps: {
                position: "top",
              },
            },
          },
          {
            field: "adjustment",
            title: "Adjustment",
            type: "number",
            gridWidth: 100,
            format: {
              type: "number",
            },
          },
        ],
      },
    ];
    this.FILTERS = [
      {
        id: "filterPeriod",
        type: "dateRange",
        serverFilter: {
          param: "",
        },
        dateRangeSettings: {
          dateFromParam: "dateFrom",
          dateToParam: "dateTo",
          defaultPeriod: "notApplied",
          allowEmptyValues: true,
        },
        localStoragePrefix: "TKDashboard",
        toolbarRow: 1,
      },
      {
        id: "filterPP",
        placeholder: "Filter by Payroll Period",
        type: "combobox",
        serverFilter: {
          param: "periodId",
          getData: () =>
            ReferenceRecordsDataSource("TKPayrollPeriods", {
              activeOnly: false,
            }),
        },
        localStoragePrefix: "TKDashboard",
        width: 298,
        toolbarRow: 2,
      },
    ];
  }

  render() {
    return (
      <DashboardGrid
        isActive={this.props.isActive}
        isParentPage={true}
        ref={this.gridRef}
        columnsSettings={this.COLUMNS}
        getData={this.LoadGridData}
        getRowKey={this.GetRowKey}
        initRefresh={this.InitRefresh}
        excelFileName="AuditResults.xlsx"
        saveSettings={{
          prefix: "auditResultsDash",
          saveFilters: true,
        }}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        filters={this.FILTERS}
        toolbar={{
          switcher: this.props.switcher,
        }}
        selectSettings={{
          pageId: "TKDashboard",
          onSelectRow: this.OpenTCCard,
        }}
        onChangeGridData={this.props.onChangeGridData}
      />
    );
  }

  LoadGridData = async (filters: simpleObject) => {
    return await api.sql.dbAudits({ ...filters });
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };

  GetRowKey = ({ rowNum }: SQL_DB_Audits_Response) => rowNum + "";

  OpenTCCard = (rowData: SQL_DB_Audits_Response | null) => {
    if (rowData && rowData.tcId) {
      CardManagement.OpenTCCard({ tcId: rowData.tcId });
    }
  };
}

export default AuditResults;
