import {
  PropsSQLDBCalendarResourcesResponseResources,
  SQL_DB_Calendar_WOCertificateInfo_Response,
} from "../../../core/api/generated/conterra";
import { Button, Chip } from "@progress/kendo-react-buttons";
import formStyles from "../card.module.scss";
import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  ComboBoxChangeEvent,
  ListItemProps,
  MultiColumnComboBox,
  MultiColumnComboBoxColumn,
  MultiColumnComboBoxHandle,
} from "@progress/kendo-react-dropdowns";
import {
  IResourceItem,
  IScheduledEmployees,
  IScheduledEmployeesReasons,
} from "./interfaces";
import { Checkbox } from "@progress/kendo-react-inputs";
import { stopPropagationWrapper } from "../../../core/tools/Hooks";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { ModalRef } from "../../Common/Modal/Modal";

const ColumnComboboxFilter = (props: {
  placeholder: string;
  resources: IResourceItem[];
  className: string;
  isMultiSelect: boolean;
  value?: IResourceItem | null; // if single select
  onSingleChange?: (value: IResourceItem | null) => void;
  onMultiChange?: (value: IResourceItem[]) => void;
  multiValues?: IResourceItem[]; // if multiselect
  woCertificates: SQL_DB_Calendar_WOCertificateInfo_Response[];
  disabled: boolean;
  scheduledEmployees: IScheduledEmployees;
  scheduledEmployeesReasons: IScheduledEmployeesReasons;
}) => {
  const {
    placeholder,
    disabled,
    isMultiSelect,
    resources,
    className,
    value,
    multiValues,
    woCertificates,
    onSingleChange,
    onMultiChange,
    scheduledEmployeesReasons,
    scheduledEmployees,
  } = props;
  const controlRef = useRef<MultiColumnComboBoxHandle>(null);
  const isControlOpenedRef = useRef(false);
  const [filter, setFilter] = useState("");
  const filteredData = useMemo(() => {
    if (!filter) return resources;
    return resources.filter((x) =>
      x.name.toLowerCase().includes(filter.toLowerCase())
    );
  }, [resources, filter]);

  const allColumns = useMemo(() => {
    const resourcePopupColumns: MultiColumnComboBoxColumn[] = [];
    if (isMultiSelect) {
      resourcePopupColumns.push({
        field: PropsSQLDBCalendarResourcesResponseResources.id,
        header: " ",
        width: 30,
      });
    }
    resourcePopupColumns.push({
      field: PropsSQLDBCalendarResourcesResponseResources.name,
      header: "Employee",
      width: 150,
    });

    woCertificates.forEach((x) => {
      resourcePopupColumns.push({
        field: x.certificateId + "",
        header: x.certificateName,
        width: 50,
      });
    });
    resourcePopupColumns.push({
      field: "warnings",
      header: " ",
      width: 30,
    });
    return resourcePopupColumns;
  }, [woCertificates]);

  const renderWarningTooltip = useCallback((e: any) => {
    const warnings = e.title.split(",/n ");
    return (
      <div>
        {warnings.map((warning: string, i: number) => {
          return <div key={i}>{warning}.</div>;
        })}
      </div>
    );
  }, []);

  const closeControl = useCallback(() => {
    const el = controlRef.current?.element;
    if (el) {
      const btn = el.getElementsByClassName("k-button")[0] as HTMLButtonElement;
      if (btn) btn.click();
    }
  }, []);

  const renderValue = useCallback(
    (
      element: React.ReactElement<
        HTMLSpanElement,
        string | React.JSXElementConstructor<any>
      >
    ) => {
      if (!multiValues?.length) return element;

      const inputProps = {
        ...element.props,
        value: filter,
        placeholder: "",
      } as HTMLInputElement;
      // @ts-ignore
      inputProps.onBlur = () => {
        setFilter("");
        if (isControlOpenedRef.current) closeControl();
      };
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            padding: "2px 0",
          }}
        >
          {multiValues.map((x) => {
            return (
              <Chip
                key={x.id + ""}
                className={formStyles.CrewLeadChips}
                themeColor={x.isActive === false ? "error" : undefined}
                style={x.isActive === false ? { opacity: 0.6 } : undefined}
                value={x.id}
                removable={true}
                text={x.name}
                onRemove={(e) => {
                  onMultiChange?.([
                    ...(multiValues || []).filter(
                      (x) => x.id !== e.target.props.value
                    ),
                  ]);
                }}
                dataItem={x}
              />
            );
          })}
          {React.cloneElement(element, inputProps)}
        </div>
      );
    },
    [multiValues, filter]
  );

  const onSelectResource = useCallback((resourse: IResourceItem) => {
    if (resourse.isLimited) {
      ModalRef.showDialog({
        title: "Warning",
        text: `${resourse.name} restricted from regular activities due to expired certifications or injury. Please contact HR Manager before scheduling.`, // used twice
        type: "warning",
      });
    }
  }, []);

  const renderPopupRow = useCallback(
    (
      li: React.ReactElement<
        HTMLLIElement,
        string | React.JSXElementConstructor<any>
      >,
      props: ListItemProps
    ) => {
      const dataItem = props.dataItem as IResourceItem;
      const isChecked =
        isMultiSelect &&
        (multiValues || []).findIndex((x) => x.id === dataItem.id) > -1;
      const children = allColumns.map((col) => {
        const { field, width } = col;
        if (field === PropsSQLDBCalendarResourcesResponseResources.id) {
          return (
            <span
              key={field! + dataItem.id}
              className="k-table-td"
              style={{ width }}
            >
              <Checkbox
                onClick={stopPropagationWrapper(() => {})}
                value={isChecked}
                onChange={(e) => {
                  const values = e.value
                    ? [...(multiValues || []), dataItem]
                    : [
                        ...(multiValues || []).filter(
                          (x) => x.id !== dataItem.id
                        ),
                      ];
                  if (e.value) onSelectResource(dataItem);
                  onMultiChange?.(values);
                }}
              />
            </span>
          );
        }
        const value = dataItem[field as keyof IResourceItem];
        if (typeof value === "boolean") {
          return (
            <span
              className="k-table-td"
              style={{
                textAlign: "center",
                width,
              }}
              key={
                field! +
                dataItem[PropsSQLDBCalendarResourcesResponseResources.id]
              }
            >
              {value ? (
                <span
                  className={"mdi mdi-check"}
                  style={{ color: "green", fontSize: 14 }}
                ></span>
              ) : (
                <span
                  className={"mdi mdi-close"}
                  style={{ color: "red", fontSize: 14 }}
                ></span>
              )}
            </span>
          );
        }

        if (field === "warnings") {
          const { id, isLimited } = dataItem;
          const scheduledItem = scheduledEmployees[id];
          const scheduledReasonItem = scheduledEmployeesReasons[id];
          const isScheduled = !!scheduledItem || !!scheduledReasonItem;
          const warnings = [];
          if (isLimited) {
            warnings.push(
              `Restricted from regular activities due to expired certifications or injury. Please contact HR Manager before scheduling`
            ); // used twice
          }
          if (scheduledItem) {
            warnings.push(
              `Already scheduled for another WOs for ${scheduledItem.Dates.join(
                ", "
              )}`
            );
          }
          if (scheduledReasonItem) {
            for (let item of scheduledReasonItem) {
              warnings.push(
                `${item.Name} has Time Card for ${item.Date} with reason ${item.Reason}`
              );
            }
          }
          return (
            <div
              className="k-table-td"
              key={field + props.dataItem.Id}
              style={{ width }}
            >
              <div title={warnings.join(",/n ")}>
                <Tooltip
                  style={{ maxWidth: "300px" }}
                  parentTitle={true}
                  content={renderWarningTooltip}
                  showCallout={false}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {isLimited && (
                      <span
                        className={`mdi mdi-exclamation-thick ${formStyles.ExclamationListItemIcon}`}
                      ></span>
                    )}
                    {isScheduled && (
                      <span
                        className={`mdi mdi-alert-outline ${formStyles.AlertListItemIcon}`}
                      ></span>
                    )}
                  </div>
                </Tooltip>
              </div>
            </div>
          );
        }

        return (
          <span
            className="k-table-td"
            key={field + props.dataItem.Id}
            style={{ width }}
          >
            {value?.toString()}
          </span>
        );
      });
      let className = li.props.className;
      if (isChecked) {
        className += ` k-selected`;
      }
      return React.cloneElement(li, { ...li.props, className }, children);
    },
    [allColumns, multiValues]
  );

  const onChange = useCallback(
    (e: ComboBoxChangeEvent) => {
      setFilter("");
      if (e.value) onSelectResource(e.value);
      if (!isMultiSelect) {
        onSingleChange?.(e.value);
      } else {
        if (e.value) {
          const values = [
            ...(multiValues || []).filter((x) => x.id !== e.value.id),
          ];
          values.push(e.value);
          onMultiChange?.(values);
        } else {
          onMultiChange?.([]);
        }
      }
    },
    [onSingleChange, onMultiChange, multiValues]
  );

  const footer = useMemo(() => {
    if (!isMultiSelect) return null;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 4,
          marginRight: -16,
        }}
      >
        <Button themeColor={"primary"} onClick={closeControl}>
          Ok
        </Button>
      </div>
    );
  }, [isMultiSelect]);

  return (
    <MultiColumnComboBox
      ref={controlRef}
      className={className}
      data={filteredData}
      columns={allColumns}
      disabled={disabled}
      popupSettings={{
        width: "880px",
      }}
      filterable={true}
      onFilterChange={(event) => {
        setFilter(event.filter.value);
      }}
      textField={PropsSQLDBCalendarResourcesResponseResources.name}
      dataItemKey={PropsSQLDBCalendarResourcesResponseResources.id}
      placeholder={placeholder}
      clearButton={true}
      value={isMultiSelect ? multiValues?.[multiValues?.length - 1] : value}
      valueRender={isMultiSelect ? renderValue : undefined}
      itemRender={renderPopupRow}
      onChange={onChange}
      footer={footer}
      onOpen={() => (isControlOpenedRef.current = true)}
      onClose={() => (isControlOpenedRef.current = false)}
    />
  );
};
export default ColumnComboboxFilter;
