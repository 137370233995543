import React from "react";
import TCCardWithLoadingData from "./TCCardWithLoadingData";
import { ITCCardWithoutContextProps } from "../interfaces";

const TCCardWithoutContext = (props: ITCCardWithoutContextProps) => {
  const {
    finally: handleCloseCard, // handleCloseCard from cardStack
    ...restProps
  } = props;

  return (
    <TCCardWithLoadingData handleClose={handleCloseCard!} {...restProps} />
  );
};

export default TCCardWithoutContext;
