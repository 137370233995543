import { Button } from "@progress/kendo-react-buttons";
import FXCard from "../Common/FXCard/FXCard";
import LoaderComponent from "../Common/Loader";
import FilterCombobox from "../Common/Form/FilterCombobox";
import formStyles from "./card.module.scss";
import { IFXCardProps } from "./interfaces";
import { showSomeError } from "../../helpers/helpers";
import { useBooleanState } from "../../core/tools/Hooks";
import React, { useCallback, useEffect, useState } from "react";
import api from "../../core/api/api";
import { WarehouseAreaDto } from "../../core/api/generated/warehouse";
import {
  SQL_WH_MI_AvailableBuildPlans_Response,
  SQL_WH_MI_AvailableMaterials_Response,
  SQL_WH_MI_AvailableVendors_Response,
  SQL_WH_MyWarehouses_Response,
} from "../../core/api/generated/conterra";
import { Input } from "@progress/kendo-react-inputs";
import CardManagement from "./CardManagement";

interface IProps extends IFXCardProps {
  afterCreate(): void;

  buildPlanId?: number;
}

const AddMICard = (props: IProps) => {
  const { buildPlanId, finally: handleClose, afterCreate } = props;
  const processing = useBooleanState();
  const [warehouse, setWarehouse] = useState<SQL_WH_MyWarehouses_Response>();
  const [area, setArea] = useState<WarehouseAreaDto | null>();
  const [vendor, setVendor] =
    useState<SQL_WH_MI_AvailableVendors_Response | null>();
  const [buildPlan, setBuildPlan] =
    useState<SQL_WH_MI_AvailableBuildPlans_Response | null>();
  const [material, setMaterial] =
    useState<SQL_WH_MI_AvailableMaterials_Response | null>();
  const [sn, setSn] = useState("");
  const loadAreas = useCallback(async () => {
    if (!warehouse) return [];
    return api.wh.area.find({ warehouseId: warehouse.id });
  }, [warehouse]);
  const loadMaterials = useCallback(
    () => api.sql.whMiAvailableMaterials({}),
    []
  );
  const loadVendors = useCallback(async () => {
    if (!material) return [];
    return api.sql.whMiAvailableVendors({ materialId: material.id });
  }, [material]);
  useEffect(() => {
    setVendor(null);
  }, [material]);
  useEffect(() => {
    setArea(null);
  }, [warehouse]);

  const handleCreate = useCallback(async () => {
    if (!sn || !vendor || !material || !warehouse) return null;
    try {
      processing.setTrue();
      const result = await api.wh.mi.createAndConfirm({
        sn,
        vendorId: vendor.id,
        materialId: material.id,
        warehouseId: warehouse.id,
        warehouseAreaId: area?.id,
        buildPlanId: buildPlanId || buildPlan?.id,
      });
      CardManagement.OpenMaterialItemCard(result.id);
      afterCreate();
      handleClose?.();
    } catch (e) {
      showSomeError(e);
    } finally {
      processing.setFalse();
    }
  }, [sn, vendor, material, warehouse, area, buildPlan, buildPlanId]);

  const isInValidForm = !sn || !vendor || !material || !warehouse;
  return (
    <FXCard
      title={"New Material Item"}
      onClose={handleClose}
      initialWidth={450}
      initialHeight={buildPlanId ? 260 : 300}
      originalPaddings={true}
    >
      <div className={formStyles.FormWrapper}>
        {processing.value && (
          <LoaderComponent text={"New Material Item is Creating..."} />
        )}
        <FilterCombobox
          placeholder="Warehouse *"
          getData={api.sql.whMyWarehouses}
          value={warehouse}
          onChange={setWarehouse}
          className={formStyles.FormField}
          required={true}
          dataItemKey={"id"}
          textField={"name"}
        />
        <FilterCombobox
          key={"area" + warehouse?.id}
          placeholder="Area"
          getData={loadAreas}
          value={area}
          onChange={setArea}
          className={formStyles.FormField}
          dataItemKey={"id"}
          textField={"number"}
          disabled={!warehouse}
        />
        <FilterCombobox
          placeholder="Material *"
          getData={loadMaterials}
          value={material}
          onChange={setMaterial}
          className={formStyles.FormField}
          required={true}
          dataItemKey={"id"}
          textField={"name"}
        />
        <FilterCombobox
          key={"vendor" + material?.id}
          placeholder="Vendor *"
          getData={loadVendors}
          value={vendor}
          onChange={setVendor}
          className={formStyles.FormField}
          required={true}
          dataItemKey={"id"}
          textField={"name"}
          disabled={!material}
        />
        <Input
          className={formStyles.FormField}
          placeholder={"SN *"}
          onChange={(e) => {
            setSn(e.value);
          }}
          required={true}
        />
        {!buildPlanId && (
          <FilterCombobox
            placeholder="Build Plan"
            getData={api.sql.whMiAvailableBuildPlans}
            value={buildPlan}
            dataItemKey={"id"}
            textField={"name"}
            onChange={setBuildPlan}
            className={formStyles.FormField}
          />
        )}
        <div className={`${formStyles.FormFooter} k-action-buttons`}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleCreate}
            themeColor="primary"
            disabled={isInValidForm}
          >
            Create
          </Button>
        </div>
      </div>
    </FXCard>
  );
};

export default AddMICard;
