import React from "react";
import { Chip, ChipProps } from "@progress/kendo-react-buttons";

const TCChip = (props: ChipProps) => {
  return (
    <Chip
      size={"medium"}
      themeColor={"success"}
      rounded={"medium"}
      disabled={!props.onClick}
      {...props}
    />
  );
};

export default TCChip;
