import React from "react";
import { simpleObject } from "../../helpers/interfaces";
import { ReferenceRecordsDataSource } from "../../helpers/queries";
import { Button } from "@progress/kendo-react-buttons";
import UserInfo from "../../stores/User";
import { settingsStorage } from "../../helpers/settings";
import api from "../../core/api/api";
import { tabId } from "../../Components/Tabs/interfaces";
import {
  gridChangeDataAction,
  IColumnSetting,
  IFilterSetting,
} from "../../Components/Dashboard/interfaces";
import BaseComponent from "../../Components/BaseComponent";
import {
  filterByReviewStateSettings,
  formatDateColumn,
} from "../../Components/TC/helpers";
import DashboardGrid from "../../Components/Dashboard/Grid";
import CardManagement from "../../Components/Cards/CardManagement";
import OpenCardLink from "../../Components/OpenCardLink";
import moment from "moment/moment";
import { SQL_DB_TimeCardTimeDetails_Response } from "../../core/api/generated/conterra";
import TCCardWithLoadingData from "../../Components/Cards/TCCard/TCCardWithLoadingData";
import { CardsStackRef } from "../../Components/Common/FXCard/Cards";

interface IProps {
  isActive: boolean;
  switcher?: any;
  allowAccessToReports?: false;
  switchTab?: (activeTabId: tabId) => void;
  onChangeGridData?: (
    data: simpleObject[],
    action: gridChangeDataAction
  ) => void;
}

interface state {
  canCreateTimeCards: boolean;
  initedSettings: boolean;
  selectedRowId: number | undefined;
  cardInitialTabId: tabId | undefined;
}

const COLUMNS: IColumnSetting[] = [
  {
    field: "RowNum",
    title: "#",
    type: "number",
    gridWidth: 40,
    filterable: false,
    columnMenu: false,
    aggregate: "count",
    locked: true,
  },
  {
    field: "Date",
    title: "Date",
    type: "date",
    locked: true,
    format: {
      type: "date",
      customFormat: true,
      getCustomFormat: formatDateColumn,
    },
  },
  {
    field: "EmployeeName",
    title: "Employee",
    type: "string",
    gridWidth: 200,
    format: {
      type: "link",
      fieldId: "EmployeeId",
      refName: "Employees",
    },
    locked: true,
  },
  {
    field: "EmployeeFirstName",
    title: "First Name",
    type: "string",
    gridWidth: 100,
  },
  {
    field: "EmployeeLastName",
    title: "Last Name",
    type: "string",
    gridWidth: 100,
  },
  {
    field: "ClassName",
    title: "Employee Class",
    type: "string",
    format: {
      type: "link",
      refName: "Classes",
      fieldId: "ClassId",
    },
    gridWidth: 180,
  },
  {
    field: "PeriodName",
    title: "Payroll Period",
    type: "string",
    // locked: true,
    gridWidth: 220,
    format: {
      type: "link",
      fieldId: "PeriodId",
      refName: "TKPayrollPeriods",
    },
  },
  {
    field: "ReviewStateName",
    title: "Review State",
    type: "string",
    // locked: true,
    format: {
      type: "string",
      fieldId: "ReviewStateId",
    },
  },
  {
    field: "AllStateNames",
    title: "Payroll States",
    type: "string",
    gridWidth: 120,
    format: {
      type: "string",
    },
  },
  {
    field: "ClockedHours",
    title: "Clocked Hours",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
  },
  {
    field: "ApprovedHours",
    title: "Approved Hours",
    type: "number",
    gridWidth: 100,
    format: {
      type: "number",
    },
    aggregate: "sum",
  },
  {
    field: "RegularHours",
    title: "Regular",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
  },
  {
    field: "OvertimeHours",
    title: "Overtime",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
  },
  {
    field: "DoubleHours",
    title: "Double",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
  },
  {
    title: "PW",
    type: "parent",
    columns: [
      {
        field: "PWRegularHours",
        title: "Regular",
        type: "number",
        format: {
          type: "number",
        },
        aggregate: "sum",
      },
      {
        field: "PWOvertimeHours",
        title: "Overtime",
        type: "number",
        format: {
          type: "number",
        },
        aggregate: "sum",
      },
      {
        field: "PWDoubleHours",
        title: "Double",
        type: "number",
        format: {
          type: "number",
        },
        aggregate: "sum",
      },
      {
        field: "PWTotalHours",
        title: "Total",
        type: "number",
        format: {
          type: "number",
        },
        aggregate: "sum",
      },
    ],
  },
  {
    field: "TotalHours",
    title: "Total Hours",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
  },
  {
    field: "ReviewerName",
    title: "Reviewer",
    type: "string",
    gridWidth: 200,
    format: {
      type: "link",
      fieldId: "ReviewerId",
      refName: "Employees",
    },
  },
  {
    field: "ReasonName",
    title: "Reason",
    type: "string",
    format: {
      type: "string",
      fieldId: "ReasonId",
    },
  },
  {
    field: "FirstClockIn",
    title: "First Clock In",
    type: "date",
    gridWidth: 120,
    format: {
      type: "datetime",
    },
  },
  {
    field: "LastClockOut",
    title: "Last Clock Out",
    type: "date",
    gridWidth: 120,
    format: {
      type: "datetime",
    },
  },
  {
    field: "DepartmentName",
    title: "Department",
    type: "string",
    format: {
      type: "link",
      fieldId: "DepartmentId",
      refName: "Departments",
    },
  },
  {
    field: "CategoryName",
    title: "Employee Category",
    type: "string",
    gridWidth: 130,
    format: {
      type: "link",
      fieldId: "CategoryId",
      refName: "EmployeeCategories",
    },
  },
  {
    field: "HasAdjustmentText",
    title: "Has Adjustment",
    type: "string",
    format: {
      type: "string",
      fieldId: "HasAdjustment",
    },
  },
  {
    field: "WageAllocationLockedText",
    title: "Wage Allocation Locked",
    type: "string",
    format: {
      type: "string",
      fieldId: "WageAllocationLocked",
    },
  },
  {
    field: "RequestsCount",
    title: "Requests Count",
    type: "number",
    format: {
      type: "integer",
    },
    aggregate: "sum",
  },
  {
    field: "AuditsCount",
    title: "Audits Count",
    type: "number",
    format: {
      type: "integer",
    },
    aggregate: "sum",
  },
  {
    field: "AuditLunchHours",
    title: "Audit Lunch",
    type: "number",
    format: {
      type: "number",
    },
  },
  {
    field: "AppliedLunchHours",
    title: "Applied Lunch",
    type: "number",
    format: {
      type: "number",
    },
  },
  {
    field: "ManualLunchHours",
    title: "Manual Lunch",
    type: "number",
    format: {
      type: "number",
    },
  },
  {
    field: "MileageHours",
    title: "Mileage",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
  },
];
const DETAILS_COLUMNS: IColumnSetting[] = [
  {
    field: "rowNum",
    title: "#",
    type: "number",
    gridWidth: 40,
    filterable: false,
    columnMenu: false,
    aggregate: "count",
  },
  {
    field: "date",
    title: "Date",
    type: "string",
    format: {
      type: "date",
      customFormat: true,
      getCustomFormat: (rowData: SQL_DB_TimeCardTimeDetails_Response) => {
        return (
          <OpenCardLink
            text={moment(rowData.date).format("L")}
            refName="TKTimeCards"
            dataAttr={rowData.tcId}
          />
        );
      }, //formatDateColumn,
    },
    gridWidth: 80,
  },
  {
    field: "employeeName",
    title: "Employee",
    type: "string",
    format: {
      type: "link",
      fieldId: "employeeId",
      refName: "Employees",
    },
    gridWidth: 200,
  },
  {
    field: "className",
    title: "Employee Class",
    type: "string",
    format: {
      type: "link",
      fieldId: "classId",
      refName: "Classes",
    },
    gridWidth: 170,
  },
  {
    field: "start",
    title: "Start",
    type: "date",
    format: {
      type: "datetime",
    },
    gridWidth: 120,
  },
  {
    field: "sinish",
    title: "Finish",
    type: "date",
    format: {
      type: "datetime",
    },
    gridWidth: 120,
  },
  {
    field: "costTypeName",
    title: "Cost Type",
    type: "string",
    format: {
      type: "string",
    },
    gridWidth: 120,
  },
  {
    field: "woName",
    title: "WO",
    type: "string",
    format: {
      type: "link",
      fieldId: "woId",
      refName: "FSMWorkOrders",
    },
    gridWidth: 200,
  },
  {
    field: "stateName",
    title: "State",
    type: "string",
    format: {
      type: "string",
      fieldId: "stateId",
      // refName: 'States'
    },
    gridWidth: 100,
  },
  {
    field: "totalHours",
    title: "Hours",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
    gridWidth: 80,
  },
  {
    field: "regularHours",
    title: "Regular",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
    gridWidth: 80,
  },
  {
    field: "overtimeHours",
    title: "Overtime",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
    gridWidth: 80,
  },
  {
    field: "doubleHours",
    title: "Double",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
    gridWidth: 80,
  },
  {
    field: "lunchHours",
    title: "Lunch",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
    gridWidth: 80,
  },
  {
    field: "pw",
    title: "PW",
    type: "string",
    format: {
      type: "string",
      fieldId: "isPW",
    },
    gridWidth: 60,
  },
  {
    field: "overnight",
    title: "Overnight",
    type: "string",
    gridWidth: 80,
  },
  {
    field: "wageType",
    title: "Locked Wage",
    type: "string", // icon
    format: {
      type: "icon",
      fieldId: "isLockedWageType",
      customFormat: true,
      getCustomFormat({
        isLockedWageType,
      }: SQL_DB_TimeCardTimeDetails_Response) {
        return isLockedWageType ? (
          <div style={{ textAlign: "center" }}>
            <span className={"mdi mdi-lock-outline"}></span>
          </div>
        ) : (
          ""
        );
      },
    },
    gridWidth: 120,
  },
  {
    field: "otExclude",
    title: "OT Exclude",
    type: "string",
    format: {
      type: "string",
      fieldId: "isOTExclude",
    },
    gridWidth: 120,
  },
  {
    field: "departmentName",
    title: "Department",
    type: "string",
    format: {
      type: "link",
      fieldId: "departmentId",
      refName: "Departments",
    },
    gridWidth: 120,
  },
  {
    field: "categoryName",
    title: "Employee Category",
    type: "string",
    format: {
      type: "link",
      fieldId: "categoryId",
      refName: "EmployeeCategories",
    },
    gridWidth: 120,
  },
];

class TimeCardsGrid extends BaseComponent<IProps, state> {
  gridRef: any = React.createRef();
  rowId: number | null = null;
  FILTERS: IFilterSetting[] = [];
  gridData: simpleObject[] = [];
  selectedTCId: number | undefined;
  rowsNav:
    | {
        selectPrev?: () => void;
        selectNext?: () => void;
        selectedTCIndex: number;
      }
    | undefined;

  constructor(props: any) {
    super(props);
    this.state = {
      canCreateTimeCards: false,
      initedSettings: false,
      selectedRowId: undefined,
      cardInitialTabId: undefined,
    };
  }

  componentDidMount(): void {
    this.InitSettings();
  }

  render() {
    const { initedSettings, selectedRowId, canCreateTimeCards } = this.state;
    if (!initedSettings) return null;
    return (
      <>
        <DashboardGrid
          isActive={this.props.isActive}
          isParentPage={true}
          ref={this.gridRef}
          columnsSettings={COLUMNS}
          filters={this.FILTERS}
          getData={this.GetGridData}
          saveSettings={{
            prefix: "timeCardsDash",
            saveFilters: true,
          }}
          excelFileName={"TimeCardsDashboard.xlsx"}
          getRowKey={(dataItem: simpleObject) => dataItem.TCId}
          columnMenu={{
            filters: true,
            columns: true,
          }}
          reorderable={true}
          toolbar={{
            actionBtns: canCreateTimeCards && (
              <Button onClick={this.CreateTC}>Add Time Card</Button>
            ),
            switcher: this.props.switcher,
          }}
          selectSettings={{
            pageId: "TKDashboard",
            onSelectRow: (rowData: simpleObject | null) => {
              if (rowData) this.SelectRow(rowData.TCId);
            },
          }}
          modeSettings={{
            modeType: "dashboard",
            name: "Details",
            id: "detailsTKGrid",
            dashboard: {
              columns: DETAILS_COLUMNS,
              loadData: this.GetDetailsGridData,
            },
          }}
          onChangeGridData={this.OnChangeGridData}
        ></DashboardGrid>
        {selectedRowId && (
          <TCCardWithLoadingData
            tcId={selectedRowId}
            handlePrev={this.rowsNav?.selectPrev}
            handleNext={this.rowsNav?.selectNext}
            handleClose={() => {
              this.selectedTCId = undefined;
              this.rowsNav = undefined;
              CardsStackRef.closeAllCards();
              this.setState({ selectedRowId: undefined });
            }}
            initialTabId={this.state.cardInitialTabId}
          />
        )}
      </>
    );
  }

  SelectRow = (tcId: number) => {
    CardsStackRef.closeAllCards();
    this.selectedTCId = tcId;
    this.rowsNav = this.GetRowsNav(this.selectedTCId);
    this.setState({ selectedRowId: this.selectedTCId });
  };

  GetRowsNav = (tcId: number) => {
    const currentIndex = this.gridData.findIndex((x) => x.TCId === tcId);
    const prevTCId = this.gridData[currentIndex - 1]?.TCId;
    const nextTCId = this.gridData[currentIndex + 1]?.TCId;
    return {
      selectedTCIndex: currentIndex,
      selectNext: nextTCId ? () => this.SelectRow(nextTCId) : undefined,
      selectPrev: prevTCId ? () => this.SelectRow(prevTCId) : undefined,
    };
  };

  OnChangeGridData = (data: simpleObject[], action: gridChangeDataAction) => {
    this.gridData = data;
    this.rowsNav = this.selectedTCId
      ? this.GetRowsNav(this.selectedTCId)
      : undefined;
    this.props.onChangeGridData?.(data, action);
  };

  GetGridData = async (filters: simpleObject) => {
    const result = await this.GetSQLData({
      spName: "DB_TimeCards",
      params: filters,
    });
    const data = result[1];
    let hasNewSelectedRow = false;
    data.forEach((item: simpleObject) => {
      if (!hasNewSelectedRow && this.rowId && item.TCId === this.rowId) {
        hasNewSelectedRow = true;
      }
      item.WageAllocationLockedText = item.WageAllocationLocked ? "Yes" : "No";
      item.HasAdjustmentText = item.HasAdjustment ? "Yes" : "No";
    });

    if (this.rowId) {
      if (!hasNewSelectedRow) {
        settingsStorage.removeForCurrentUser("timeCardsDash_SelectedRowId");
      }
      this.rowId = null;
    }
    this.setState({ canCreateTimeCards: result[0][0].CanCreateTimeCards });

    return data;
  };

  GetDetailsGridData = async (filters: any) => {
    const result = await api.sql.dbTimeCardTimeDetails({ ...filters });
    return result.map((item) => {
      return {
        ...item,
        key: item.rowNum,
        pw: item.isPW ? "PW" : "",
        wageType: item.isLockedWageType ? "Locked" : "Not Locked",
        otExclude:
          item.isOTExclude === null ? null : item.isOTExclude ? "Yes" : "No",
      };
    });
  };

  InitSettings = async () => {
    let userInfo = await UserInfo.getInfo();
    let reviewerValue =
      this.props.allowAccessToReports === false && userInfo
        ? {
            Id: userInfo.EmployeeId,
            Name: userInfo.Name,
          }
        : undefined;
    let filterByReviewer: IFilterSetting = {
      id: "filterReviewer",
      placeholder: "Filter by Reviewer",
      type: "combobox",
      disabled: !!reviewerValue,
      defValue: reviewerValue,
      serverFilter: {
        param: "reviewerId",
        getData: () => ReferenceRecordsDataSource("Employees"),
      },
      width: 248,
      toolbarRow: 2,
    };

    this.FILTERS = [
      {
        id: "filterPeriod",
        type: "dateRange",
        serverFilter: {
          param: "",
        },
        dateRangeSettings: {
          dateFromParam: "dateFrom",
          dateToParam: "dateTo",
          defaultPeriod: "notApplied",
          allowEmptyValues: true,
        },
        localStoragePrefix: "TKDashboard",
        toolbarRow: 1,
      },
      {
        id: "filterAR",
        placeholder: "Filter by Audit Rule",
        type: "multiselect",
        itemName: "Audit Rule",
        serverFilter: {
          param: "auditRuleId",
          getData: () => ReferenceRecordsDataSource("TKAuditRules"),
        },
        toolbarRow: 1,
        width: 300,
      },
      filterByReviewStateSettings,
      filterByReviewer,
      {
        id: "filterPP",
        placeholder: "Filter by Payroll Period",
        type: "combobox",
        serverFilter: {
          param: "periodId",
          getData: () =>
            ReferenceRecordsDataSource("TKPayrollPeriods", {
              activeOnly: false,
            }),
        },
        localStoragePrefix: "TKDashboard",
        toolbarRow: 2,
        width: 300,
      },
      {
        id: "filterPT",
        placeholder: "Filter by Payroll Period Type",
        type: "combobox",
        serverFilter: {
          param: "periodTypeId",
          getData: () => ReferenceRecordsDataSource("TKPayrollPeriodTypes"),
        },
        localStoragePrefix: "TKDashboard",
        toolbarRow: 2,
        width: 200,
      },
    ];

    this.setState({ initedSettings: true });
  };

  Refresh = (tcId?: number) => {
    let refresh = this.gridRef?.current?.Refresh;
    if (tcId) {
      settingsStorage.setForCurrentUser(
        "timeCardsDash_SelectedRowId",
        JSON.stringify(tcId)
      );
      this.rowId = tcId;
    }
    if (refresh) refresh();
  };

  CreateTC = () => {
    CardManagement.OpenAddTCCard({ onFinish: this.Refresh });
  };
}

export default TimeCardsGrid;
