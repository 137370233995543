import React, { useEffect, useRef, useState } from "react";
import FXCard from "../Common/FXCard/FXCard";
import formStyles from "./card.module.scss";
import { IFXCardProps } from "./interfaces";
import { Button } from "@progress/kendo-react-buttons";
import { RunScriptAsync } from "../../helpers/runscripts";
import { useBooleanState } from "../../core/tools/Hooks";
import LoaderComponent from "../Common/Loader";
import { TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import moment from "moment";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { GridRowHeight } from "../Dashboard/helpers";
import { showSomeError } from "../../helpers/helpers";
import {
  SQL_TK_GetRequestAllocation_Response,
  SQL_TK_GetRequestData_Response,
} from "../../core/api/generated/conterra";

interface IProps extends IFXCardProps {
  rowData: SQL_TK_GetRequestData_Response;
  allocation: SQL_TK_GetRequestAllocation_Response[];
  refresh: () => void;
}

type requestActions =
  | "TARtoTKAdjustment"
  | "Complete"
  | "Approve"
  | "AdminReject"
  | "ReviewerReject"
  | "Revoke";

export interface IRequestAction {
  Id: string;
  Name: string;
  IsAvailableField: keyof SQL_TK_GetRequestData_Response;
  ActionName: requestActions;
}

const rejectActions: IRequestAction[] = [
  {
    Id: "Revoke",
    Name: "Revoke",
    IsAvailableField: "canRevoke",
    ActionName: "Revoke",
  },
  {
    Id: "Reject",
    Name: "Reject",
    IsAvailableField: "canAdminReject",
    ActionName: "AdminReject",
  },
  {
    Id: "Reject",
    Name: "Reject",
    IsAvailableField: "canReviewerReject",
    ActionName: "ReviewerReject",
  },
];

const approveActions: IRequestAction[] = [
  {
    Id: "Complete",
    Name: "Complete",
    IsAvailableField: "canComplete",
    ActionName: "Complete",
  },
  {
    Id: "Approve",
    Name: "Approve",
    IsAvailableField: "canApprove",
    ActionName: "Approve",
  },
];

const overrideActions: IRequestAction[] = [
  {
    Id: "OverrideandComplete",
    Name: "Override and Complete",
    IsAvailableField: "canApplyandComplete",
    ActionName: "TARtoTKAdjustment",
  },
  {
    Id: "OverrideandApprove",
    Name: "Override and Approve",
    IsAvailableField: "canOverrideandApprove",
    ActionName: "TARtoTKAdjustment",
  },
];
const applyActions: IRequestAction[] = [
  {
    Id: "ApplyandComplete",
    Name: "Apply and Complete",
    IsAvailableField: "canApplyandComplete",
    ActionName: "TARtoTKAdjustment",
  },
  {
    Id: "ApplyandApprove",
    Name: "Apply and Approve",
    IsAvailableField: "canApplyandApprove",
    ActionName: "TARtoTKAdjustment",
  },
];

const cardHeight: { [key: string]: number } = {
  Misc: 400,
  "Time Off": 380,
  "Time Adjustment": 620,
};

const TCRequestsActionsCard = (props: IProps) => {
  const { rowData } = props;
  const isProcessing = useBooleanState(false);
  const [availableActions, setAvailableActions] = useState<IRequestAction[]>(
    []
  );

  const commentRef = useRef<string>("");
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const reject = rejectActions.filter(filterActions);
    const approve = approveActions.filter(filterActions);
    const override = overrideActions.filter(filterActions);
    const apply = applyActions.filter(filterActions);

    const actions: IRequestAction[] = [];
    if (reject.length) actions.push(reject[0]);
    if (approve.length) actions.push(approve[0]);
    if (override.length) actions.push(override[0]);
    if (apply.length) actions.push(apply[0]);

    setAvailableActions(actions);
  }, [rowData]);

  const filterActions = (action: IRequestAction) =>
    rowData[action.IsAvailableField];

  const close = () => {
    props.finally!();
  };

  const TARtoTKAdjustment = async (requestId: number) => {
    try {
      isProcessing.setTrue();
      await RunScriptAsync("TK_TransformTARtoTKAdjustment", {
        RequestId: requestId,
        Comment: commentRef.current || "Applied and Completed",
      });
      props.refresh(); // refresh only requests?? or timecards grid also??
      close();
    } catch (e) {
      showSomeError(e);
    } finally {
      isProcessing.setFalse();
    }
  };

  const PerformRequestAction = async (
    ActionName: requestActions,
    RequestID: number
  ) => {
    /*if (actionName !== "Revoke") {
                                comment = $("#request-comment-" + requestId)
                                    .val()
                                    .trim();
                                $("#request-comment-" + requestId).val("");
                            }*/
    try {
      let Comment = commentRef.current;
      if (
        !Comment &&
        ActionName !== "ReviewerReject" &&
        ActionName !== "AdminReject"
      )
        Comment = ActionName;
      if (!Comment) {
        setIsValid(false);
        return;
      }
      isProcessing.setTrue();
      await RunScriptAsync("TK_COVER_PerformRequestAction", {
        RequestID,
        Comment,
        ActionName,
      });
      props.refresh(); // refresh only requests?? or timecards grid also??
      close();
    } catch (e) {
      showSomeError(e);
    } finally {
      isProcessing.setFalse();
    }
  };

  const onAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isValid) setIsValid(true);
    const actionName = event.currentTarget.value as requestActions;
    const { requestId } = props.rowData;
    if (actionName === "TARtoTKAdjustment") {
      TARtoTKAdjustment(requestId);
    } else {
      PerformRequestAction(actionName, requestId);
    }
  };

  const onChangeComment = (event: TextAreaChangeEvent) => {
    commentRef.current = event.value;
    if (!isValid) setIsValid(true);
  };

  const renderTopRow = () => {
    return (
      <div className={formStyles.Row}>
        <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
          <div className={formStyles.FormFieldLabel}>Type:</div>
          <div>
            <div className={formStyles.ReadOnlyField}>
              {rowData.requestType}
            </div>
          </div>
        </div>

        <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
          <div className={formStyles.FormFieldLabel}>Stage:</div>
          <div>
            <div className={formStyles.ReadOnlyField}>
              {rowData.reviewStage}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderComment = () => {
    if (availableActions.length === 0) return null;

    return (
      <TextArea
        className={`${formStyles.FormField} ${formStyles.TextArea}`}
        placeholder={"Comment"}
        onChange={onChangeComment}
        rows={5}
        style={{ width: "100%", marginTop: "auto" }}
      />
    );
  };

  const renderFooter = () => {
    const showComment = !!availableActions.length;
    const style: React.CSSProperties | undefined = showComment
      ? { marginTop: 0 }
      : undefined;
    return (
      <div
        className={`${formStyles.FormFooter} k-action-buttons`}
        style={style}
      >
        <span className={formStyles.InvalidMessage}>
          {!isValid && <span>Comment Is required</span>}
        </span>
        <Button onClick={close}>Close</Button>
        {availableActions.map((a) => renderActionBtn(a))}
      </div>
    );
  };

  const renderActionBtn = (action: IRequestAction) => {
    return (
      <Button
        key={action.Id}
        onClick={onAction}
        value={action.ActionName}
        themeColor="primary"
      >
        {action.Name}
      </Button>
    );
  };

  const renderTimeoffRequestInfo = () => {
    return (
      <>
        <div>
          <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
            <div className={formStyles.FormFieldLabel}>Reason:</div>
            <div>
              <div className={formStyles.ReadOnlyField}>
                {rowData.reasonName}
              </div>
            </div>
          </div>
        </div>
        {renderDescription()}
      </>
    );
  };

  const renderMiscRequestInfo = () => {
    return (
      <>
        <div className={`${formStyles.Row}`}>
          <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
            <div className={formStyles.FormFieldLabel}>Payroll Item:</div>
            <div>
              <div className={formStyles.ReadOnlyField}>
                {rowData.payrollItemName}
              </div>
            </div>
          </div>
          <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
            <div className={formStyles.FormFieldLabel}>QTY:</div>
            <div>
              <div className={formStyles.ReadOnlyField}>{rowData.qty}</div>
            </div>
          </div>
        </div>

        <div className={`${formStyles.Row}`}>
          <div className={formStyles.FormFieldLabel}>Work Order:</div>
          <div>
            <div className={formStyles.ReadOnlyField}>
              {rowData.workOrderName}
            </div>
          </div>
        </div>

        {renderDescription()}
      </>
    );
  };

  const renderTimeAdjustmentRequestInfo = () => {
    return (
      <>
        <div className={`${formStyles.Row}`}>
          <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
            <div className={formStyles.FormFieldLabel}>Clock In:</div>
            <div>
              <div className={formStyles.ReadOnlyField}>
                {rowData.clockIn ? moment(rowData.clockIn).format("LT") : ""}
              </div>
            </div>
          </div>
          <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
            {!!rowData.lunchStart && (
              <>
                <div className={`${formStyles.FormFieldLabel}`}>
                  Lunch Start:
                </div>
                <div>
                  <div className={formStyles.ReadOnlyField}>
                    {moment(rowData.lunchStart).format("LT")}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className={`${formStyles.Row}`}>
          <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
            <div className={formStyles.FormFieldLabel}>Clock Out:</div>
            <div>
              <div className={formStyles.ReadOnlyField}>
                {rowData.clockOut ? moment(rowData.clockOut).format("LT") : ""}
              </div>
            </div>
          </div>
          <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
            {!!rowData.lunchFinish && (
              <>
                <div className={`${formStyles.FormFieldLabel}`}>
                  Lunch Finish:
                </div>
                <div>
                  <div className={formStyles.ReadOnlyField}>
                    {moment(rowData.lunchFinish).format("LT")}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {renderDescription()}
        <Grid
          className={``}
          style={{
            width: "100%",
            maxHeight: 225,
            flex: "0 0 auto",
            marginBottom: 4,
          }}
          data={props.allocation}
          scrollable={"scrollable"}
          filterable={false}
          rowHeight={GridRowHeight}
        >
          <GridColumn title={"Work Order"} field={"WorkOrderName"} />
          <GridColumn title={"Percentage"} field={"Percentage"} width={80} />
        </Grid>
      </>
    );
  };

  const renderDescription = () => {
    return (
      <div>
        <div
          className={formStyles.FormFieldLabel}
          style={{ margin: "5px 0 0" }}
        >
          Description:
        </div>
        <div className={`${formStyles.Row}`}>
          <div className={formStyles.ReadOnlyTextarea}>
            {rowData.description}
          </div>
        </div>
      </div>
    );
  };

  return (
    <FXCard
      title={`${rowData.requestType} Request ${moment(
        rowData.timeCardDate
      ).format("L")}`}
      initialWidth={600}
      initialHeight={cardHeight[rowData.requestType]}
      originalPaddings={true}
      onClose={close}
      modal={false}
      className={"TCRequestActionsCard"}
    >
      <div className={formStyles.FormWrapper}>
        {isProcessing.value && <LoaderComponent />}
        {renderTopRow()}

        {rowData.requestType === "Time Off" && renderTimeoffRequestInfo()}
        {rowData.requestType === "Misc" && renderMiscRequestInfo()}
        {rowData.requestType === "Time Adjustment" &&
          renderTimeAdjustmentRequestInfo()}
        {renderComment()}
        {renderFooter()}
      </div>
    </FXCard>
  );
};

export default TCRequestsActionsCard;
