import styles from "./tkToolbar.module.scss";
import {
  CalendarHeaderTitleProps,
  DateRangePicker,
  DateRangePickerChangeEvent,
  DateRangePickerDateInputSettings,
  SelectionRange,
} from "@progress/kendo-react-dateinputs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";

const dateInputSettings: DateRangePickerDateInputSettings = {
  formatPlaceholder: {
    month: "m",
    day: "d",
    year: "y",
  },
};

export function DateRangeFilter(props: {
  onChange: (value: SelectionRange) => void;
  start: Date | null;
  end: Date | null;
}) {
  const { start: propsStart, end: propsEnd, onChange } = props;
  const valuesRef = useRef<SelectionRange>({
    start: propsStart,
    end: propsEnd,
  });
  const [values, setValues] = useState<SelectionRange>(valuesRef.current);
  const pickerRef = useRef<any>();
  const applyChanges = useCallback(
    (isOnClearPressed?: boolean) => {
      const isValidStart =
        !valuesRef.current.start || moment(valuesRef.current.start).isValid();
      const isValidEnd =
        !valuesRef.current.end || moment(valuesRef.current.end).isValid();
      if (!isValidStart) {
        valuesRef.current.start = null;
      }
      if (!isValidEnd) {
        valuesRef.current.end = null;
      }
      setValues({ ...valuesRef.current });
      const isStartChanged = propsStart !== valuesRef.current.start;
      const isEndChanged = propsEnd !== valuesRef.current.end;
      if (isStartChanged || isEndChanged || isOnClearPressed) {
        onChange({ ...valuesRef.current });
      }
    },
    [onChange, setValues]
  );

  const handleChange = useCallback(
    (event: DateRangePickerChangeEvent) => {
      const { show, value } = event;
      valuesRef.current = value;
      setValues(value);
      const isOnClearPressed = !show;
      if (isOnClearPressed) applyChanges(true);
    },
    [applyChanges, setValues]
  );

  const handleOk = useCallback(() => {
    pickerRef.current?.setShow?.(false);
  }, []);

  useEffect(() => {
    if (
      propsStart !== valuesRef.current.start ||
      propsEnd !== valuesRef.current.end
    ) {
      valuesRef.current = { start: propsStart, end: propsEnd };
      setValues(valuesRef.current);
    }
  }, [propsStart, propsEnd, setValues]);

  return (
    <DateRangePicker
      ref={pickerRef}
      startDateInputSettings={dateInputSettings}
      endDateInputSettings={dateInputSettings}
      className={styles.DateRangeFilter}
      onChange={handleChange}
      clearButton={true}
      value={values}
      onClose={() => applyChanges()}
      calendarSettings={{
        headerTitle: (props: CalendarHeaderTitleProps) => (
          <div>
            <Button
              themeColor={"primary"}
              fillMode={"link"}
              value={props.value}
              onClick={props.onClick}
            >
              {props.children}
            </Button>

            <Button
              style={{
                position: "absolute",
                bottom: 5,
                right: 5,
                zIndex: 2,
              }}
              themeColor={"primary"}
              onClick={handleOk}
            >
              Ok
            </Button>
          </div>
        ),
      }}
    />
  );
}
