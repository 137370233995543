import { Button } from "@progress/kendo-react-buttons";
import styles from "../vim.module.scss";

interface IProps {
  disabledPrev: boolean;
  disabledNext: boolean;

  onPrev(): void;

  onNext(): void;
}

const VIMListNav = ({ disabledPrev, onPrev, disabledNext, onNext }: IProps) => {
  return (
    <div>
      <Button
        className={styles.ListArrow}
        icon="arrow-60-up"
        fillMode="flat"
        onClick={(e) => {
          e.stopPropagation();
          onPrev();
        }}
        disabled={disabledPrev}
      ></Button>
      <Button
        className={styles.ListArrow}
        icon="arrow-60-down"
        fillMode="flat"
        onClick={(e) => {
          e.stopPropagation();
          onNext();
        }}
        disabled={disabledNext}
      ></Button>
    </div>
  );
};

export default VIMListNav;
