import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { DataResult, process } from "@progress/kendo-data-query";
import moment from "moment";
import BaseComponent from "../../../Components/BaseComponent";
import { IComboboxItem } from "../../../helpers/interfaces";
import { RunScriptAsync } from "../../../helpers/runscripts";
import { settingsStorage } from "../../../helpers/settings";

import Loader from "../../../Components/Common/Loader";
import {
  IColumnValue,
  IGridFilter,
} from "../../../Components/Dashboard/interfaces";
import {
  GetDefaultGridFilter,
  IsComplexGridFilter,
} from "../../../Components/Dashboard/helpers";
import ComboboxFilterVirtual from "../../../Components/Dashboard/ComboboxFilterVirtual";
import ComboboxFilter from "../../../Components/Dashboard/ComboboxFilter";
import { ModalRef } from "../../../Components/Common/Modal/Modal";

import {
  downloadCLResultsAction,
  IDocumentItem,
  IFileItem,
  IUpdateIncludedParams,
  props,
} from "./interfaces";
import ChecklistResultCarousel from "./PreviewerCarousel";
import styles from "./checklistResults.module.scss";

import popupStyles from "../../../Components/Common/MobilePopup/index.module.scss";
import React from "react";
import { TextArea } from "@progress/kendo-react-inputs";
import MobileList from "./MobileList";

import {
  CreateResultFromVersion,
  FIELD_DETAIL_LIST,
  GetCommentForMe,
  GetMobileMenuItems,
  GetMyComment,
  GetMyCommentsTitle,
  HAS_MEDIA_LIST,
  IsDisableEditStatus,
  SaveMyComment,
  SendCliIncluded,
  TYPES,
} from "./helpers";
import CardManagement from "../../../Components/Cards/CardManagement";
import MobilePopup from "../../../Components/Common/MobilePopup/Index";
import MobileActionsPopup from "./MobileActionsPopup";
import MobileCliHistory from "./MobileCliHistory";
import MobileCLIStatuses from "./MobileCLIStatuses";
import {
  ICLMSettings,
  ICLPMBPItem,
  ICLPMWOItem,
} from "../../../stores/interfaces";
import CLPMSettings from "../../../stores/CLPMSettings";
import "hammerjs";
import {
  mobilePopupBtnOuterHeight,
  mobilePopupPaddings,
} from "../../../Components/Common/MobilePopup/helpers";
import api from "../../../core/api/api";
import {
  GetDocumentUrl,
  showSomeError,
  UpdateDocument,
} from "../../../helpers/helpers";
import config from "../../../config";

type popup =
  | "History"
  | "CliComment"
  | "CommentForMe"
  | "MyComments"
  | "Download"
  | string /*Download_groupId*/;

interface state {
  bploading: boolean;
  loading: boolean;
  documents: DataResult;
  buildPlans: ICLPMBPItem[];
  filteredWorkOrders: ICLPMWOItem[];
  gridFilter: IGridFilter;
  disableNext: boolean;
  disablePrev: boolean;
  remountFilterKey: number;
  remountMobileListKey: number;
  remountMobilePreviewKey: number;
  collapsedMobileKey: number;
  popup: popup | null;
  popupActions: {
    dataItem: IDocumentItem;
    fromPreview?: boolean;
    fileId?: number;
  } | null;
  popupPreview: IDocumentItem | null;
  popupStatus: {
    dataItem: IDocumentItem;
    fileId?: number;
  } | null;
  popupProcessing: boolean;
  collapseToolbar: boolean;
}

class MobileChecklistResults extends BaseComponent<props, state> {
  settings: ICLMSettings | null = null;
  bpId: number | undefined = this.props.buildPlanId
    ? +this.props.buildPlanId
    : undefined;
  woId: number | undefined = this.props.workOrderId
    ? +this.props.workOrderId
    : undefined;
  selectedBP: ICLPMBPItem | undefined;
  selectedWorkOrder: ICLPMWOItem | null = null;
  selectedStatus: IComboboxItem | null = null;
  selectedHasMedia: IComboboxItem | null = null;
  selectedDetails: IComboboxItem | null = null;
  selectedType: IComboboxItem | null = this.props.isCLM ? TYPES[0] : null;
  selectedDocument: IDocumentItem | undefined;
  nextDocument: IDocumentItem | undefined;
  prevDocument: IDocumentItem | undefined;
  currentFileId: number | null = null;
  group: Array<{ field: string; dir?: "asc" | "desc" }> = [
    {
      field: "groupName",
      dir: "asc",
    },
  ];
  gridFilter: IGridFilter = GetDefaultGridFilter();
  expandAll: boolean = false;
  documents: Array<IDocumentItem> = [];
  statuses: Array<IColumnValue> = [];
  hasMediaList = HAS_MEDIA_LIST;
  fieldDetailList = FIELD_DETAIL_LIST;
  mobileExpandedSettings: { [key: string]: boolean } = {};
  initialHeight: number = window.innerHeight;
  heightWasChanged: boolean = false;

  constructor(props: any) {
    super(props);

    if (this.selectedType) {
      let isDocCl = this.selectedType.Id === "Documents";
      let field: keyof IDocumentItem = "docCl";
      this.gridFilter.filters.push({
        field,
        value: isDocCl,
        operator: "eq",
      });
    }

    this.state = {
      bploading: false,
      loading: false,

      documents: { data: [], total: 0 },
      gridFilter: this.gridFilter,

      buildPlans: [],
      filteredWorkOrders: [],
      disableNext: false,
      disablePrev: false,
      collapseToolbar: false,

      popup: null,
      popupActions: null,
      popupStatus: null,
      popupPreview: null,
      popupProcessing: false,

      remountFilterKey: +new Date(),
      remountMobileListKey: +new Date(),
      remountMobilePreviewKey: +new Date(),
      collapsedMobileKey: +new Date(),
    };
  }

  async componentDidMount() {
    try {
      this.LoadClmBuildPlans();
      this.settings = await CLPMSettings.getSettings();
      this.LoadData();

      let statuses = await CLPMSettings.getStatusesList();
      statuses.forEach((st) => {
        this.statuses.push({
          Id: st.value,
          Name: st.text || "No Result",
          Selected: true,
          FieldId: "status",
        });
      });

      let el = document.querySelector("#CheckListResults");
      // @ts-ignore
      var hammertime = new Hammer(el);
      // @ts-ignore
      hammertime.get("swipe").set({ direction: Hammer.DIRECTION_VERTICAL });
      hammertime.on("swipedown", this.Refresh);
      window.helpers.onInactive(300000, this.AutoRefresh); // ???
      window.visualViewport?.addEventListener("resize", this.OnResize);
    } catch (e) {
      showSomeError(e);
    }
  }

  componentDidUpdate(prevProps: props) {
    if (this.props.isActive) {
      let updateBPorWO = false;

      if (
        (this.props.buildPlanId &&
          this.bpId !== this.props.buildPlanId &&
          prevProps.buildPlanId !== this.props.buildPlanId) ||
        (this.props.workOrderId &&
          prevProps.workOrderId !== this.props.workOrderId)
      ) {
        this.bpId = this.props.buildPlanId;
        updateBPorWO = true;
      }
      const prevWoId = prevProps.workOrderId;
      const woId = this.props.workOrderId;
      if (/*woId && prevWoId && */ woId !== prevWoId && this.woId !== woId) {
        updateBPorWO = true;
        this.woId = woId;
        this.selectedWorkOrder = null;
        this.GetSetFilteredWorkOrders();
      }

      if (updateBPorWO) {
        this.selectedDocument = undefined;
        this.LoadData();
      }
    }
  }

  componentWillUnmount(): void {
    super.componentWillUnmount();
    window.visualViewport?.removeEventListener("resize", this.OnResize);
    // hammer off todo
  }

  render() {
    let documents = this.state.documents.data;
    for (let Group of documents) {
      let GroupId = Group.value.trim();
      if (this.mobileExpandedSettings[GroupId] === undefined) {
        this.mobileExpandedSettings[GroupId] = false;
      }
    }

    return (
      <div id="CheckListResults">
        {this.state.loading && <Loader />}
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {this.state.popupProcessing && (
            <Loader style={{ background: "rgba(255, 255, 255, 0.3)" }} />
          )}
          <Toolbar className={`${styles.MobileToolbar} ${styles.Toolbar}`}>
            {this.renderMobileToolbarContent()}
          </Toolbar>
          <MobileList
            key={this.selectedBP?.Id || "list"}
            toolbarHeight={this.state.collapseToolbar ? 58 : 256}
            data={this.state.documents}
            expandedSettings={this.mobileExpandedSettings}
            onMobileExpandeChange={this.OnMobileExpandChange}
            onItemClick={this.OnItemClick}
            remountListKey={this.state.remountMobileListKey}
            collapsedKey={this.state.collapsedMobileKey}
            setClIncluded={this.SendCliIncluded}
            handleDownloadGroupFiles={this.OpenMobilePopupDownloadGroupItems}
          />
          {this.renderSelectedItemPreview()}
          {this.renderPopupActions()}
          {this.renderPopupStatus()}
          {this.renderPopup()}
        </div>
      </div>
    );
  }

  renderMobileToolbarContent = () => {
    if (this.state.collapseToolbar) {
      return this.renderFilterButtons();
    }
    return (
      <>
        {this.props.isCLM && (
          <div className={styles.CLMFilterRow}>
            <ComboboxFilterVirtual
              key={this.state.remountFilterKey + "buildplans"}
              data={this.state.buildPlans}
              loading={this.state.bploading}
              onChange={this.OnChangeBuildPlan}
              filter={{
                id: "buildPlans",
                placeholder: "Filter by Build Plan",
                type: "combobox",
                width: "100%",
              }}
              popupSettings={{ popupClass: styles.MobileComboboxPopup }}
              defaultValue={this.selectedBP}
              required={true}
              valueRender={this.renderBPComboboxValue}
            />
          </div>
        )}
        <div className={styles.CLMFilterRow}>
          <ComboboxFilter
            key={this.state.remountFilterKey + "wos"}
            defaultValue={this.selectedWorkOrder}
            filterData={this.state.filteredWorkOrders}
            onChange={this.ChangeFilterByWO}
            filter={{
              id: "workOrders",
              placeholder: "Filter by Work Order",
              type: "combobox",
              width: "100%",
            }}
            popupSettings={{ popupClass: styles.MobileComboboxPopup }}
          />
        </div>
        <div className={styles.CLMFilterRow}>
          <div style={{ flex: 1, paddingRight: 4 }}>
            <ComboboxFilter
              key={this.state.remountFilterKey + "type"}
              defaultValue={this.selectedType}
              filterData={TYPES}
              onChange={this.OnChangeTypeFilter}
              filter={{
                id: "filterBeType",
                placeholder: "Filter by Type",
                type: "combobox",
                width: "100%",
              }}
              popupSettings={{ popupClass: styles.MobileComboboxPopup }}
            />
          </div>

          <div style={{ flex: 1, paddingLeft: 4 }}>
            <ComboboxFilter
              key={this.state.remountFilterKey + "hasMedia"}
              defaultValue={this.selectedHasMedia}
              filterData={this.hasMediaList}
              onChange={this.OnChangeHasMediaFilter}
              filter={{
                id: "detail",
                placeholder: "Has Media",
                type: "combobox",
                width: "100%",
              }}
              popupSettings={{ popupClass: styles.MobileComboboxPopup }}
            />
          </div>
        </div>

        <div className={styles.CLMFilterRow}>
          <div style={{ flex: 1, paddingRight: 4 }}>
            <ComboboxFilter
              key={this.state.remountFilterKey + "statuses"}
              defaultValue={this.selectedStatus}
              filterData={this.statuses}
              onChange={this.OnChangeStatus}
              filter={{
                id: "statuses",
                placeholder: "Filter by Status",
                type: "combobox",
                width: "100%",
              }}
              popupSettings={{ popupClass: styles.MobileComboboxPopup }}
            />
          </div>
          <div style={{ flex: 1, paddingLeft: 4 }}>
            <ComboboxFilter
              key={this.state.remountFilterKey + "Detail"}
              defaultValue={this.selectedDetails}
              filterData={this.fieldDetailList}
              onChange={this.OnChangeDetailFilter}
              filter={{
                id: "detail",
                placeholder: "Details",
                type: "combobox",
                width: "100%",
              }}
              popupSettings={{ popupClass: styles.MobileComboboxPopup }}
            />
          </div>
        </div>
        {this.renderFilterButtons()}
      </>
    );
  };

  renderFilterButtons = () => {
    const isSubmitter = this.settings?.IsSubmitter;
    return (
      <div className={styles.CLMFilterRow}>
        <Button
          icon={"plus"}
          title={"Add WO CheckList"}
          onClick={this.OpenWOCheckListTemplateCard}
          disabled={
            this.selectedBP?.IsActive === false ||
            this.selectedWorkOrder?.IsActive === false
          }
        />
        <div style={{ flex: 1 }}></div>
        <Button
          style={{ marginLeft: 8 }}
          themeColor={
            this.gridFilter.filters.length || this.woId ? "primary" : undefined
          }
          icon={"filter-clear"}
          title={"Set Default Filters"}
          onClick={this.SetDefaultFilters}
        />
        {!isSubmitter && (
          <Button
            style={{ marginLeft: 8 }}
            icon="download"
            onClick={this.OpenMobilePopupDownload}
          />
        )}
        <Button
          style={{ marginLeft: 8 }}
          icon="refresh"
          onClick={this.Refresh}
        />
        <Button
          style={{ marginLeft: 8 }}
          iconClass={`${styles.ExpandIcon} mdi mdi-${
            this.expandAll ? "collapse-all-outline" : "expand-all-outline"
          }`}
          title={this.expandAll ? "Collapse All" : "Expand All"}
          onClick={this.ToggleExpandGroup}
        />
        <Button
          themeColor={this.state.collapseToolbar ? "primary" : undefined}
          style={{ marginLeft: 8 }}
          icon={
            this.state.collapseToolbar
              ? "arrow-chevron-down"
              : "arrow-chevron-up"
          }
          onClick={() => {
            this.setState(
              (state) => {
                return { collapseToolbar: !state.collapseToolbar };
              },
              () => {
                this.setState({ remountMobileListKey: +new Date() });
              }
            );
          }}
        />
      </div>
    );
  };

  renderPopupActions = () => {
    let actionsSettings = this.state.popupActions;
    if (!actionsSettings) return null;
    let { dataItem, fromPreview, fileId } = actionsSettings;
    return (
      <MobileActionsPopup
        settings={actionsSettings}
        onSelectAction={this.DoAction}
        onHide={this.HidePopupActions}
        actions={GetMobileMenuItems(
          this.settings!,
          dataItem,
          fromPreview,
          fileId
        )}
      />
    );
  };

  renderSelectedItemPreview = () => {
    const { popupPreview, remountMobilePreviewKey } = this.state;
    if (!popupPreview) return null;

    return (
      <div className={styles.PopupPreview}>
        {!!this.bpId && (
          <div className={styles.PreviewerCarouselBox}>
            <ChecklistResultCarousel
              key={remountMobilePreviewKey + "" + popupPreview.id}
              dataItem={popupPreview}
              buildPlanId={this.bpId}
              pageId={this.props.pageId}
              onSelectFile={this.OnSelectFile}
              settings={this.settings!}
              mobile={{
                onPreviewClose: this.HidePreviewDocument,
                onPopupShow: this.OnMobileShowActionsFromPreview,
                onChangeStatusPopupShow: this.ShowPopupStatus,
                arrows: this.renderArrows(),
                refreshList: this.BackgroundRefresh,
              }}
              editComments={() => {}} // only for desktop
              abort={() => {
                this.CancelPrevRequestUpdateToken();
                return this.abortController.signal;
              }}
            />
          </div>
        )}
      </div>
    );
  };

  renderBPComboboxValue = (rendering: React.ReactElement<HTMLSpanElement>) => {
    let isSubmitter = this.settings?.IsSubmitter;

    return (
      <>
        {rendering}
        {!isSubmitter && (
          <Button
            icon="hyperlink-open"
            className={styles.OpenSiteCardBtn}
            fillMode="flat"
            onClick={this.OpenBPCard}
          />
        )}
      </>
    );
  };

  renderArrows = () => {
    if (!this.state.popupPreview) return null;
    return (
      <div className={styles.ResultsNav}>
        <Button
          icon="caret-double-alt-left"
          data-action="prev"
          onClick={this.OnNavResults}
          fillMode="flat"
          disabled={this.state.disablePrev}
        />
        <Button
          icon="caret-double-alt-right"
          data-action="next"
          onClick={this.OnNavResults}
          fillMode="flat"
          disabled={this.state.disableNext}
        />
      </div>
    );
  };

  renderPopup = () => {
    const popupType = this.state.popup;
    if (popupType?.includes("Download")) return this.renderPopupDownload();
    let popupItem = this.state.popupPreview || this.selectedDocument;
    if (!popupType || !popupItem) return null;

    switch (popupType) {
      case "CliComment":
        return this.renderPopupCliComment(popupItem);
      case "CommentForMe":
        return this.renderPopupCommentForMe(popupItem);
      case "MyComments":
        return this.renderPopupMyComments(popupItem);
      case "History":
        return this.renderPopupHistory(popupItem);
    }
  };

  renderPopupDownload = () => {
    return (
      <MobilePopup
        onHide={this.HidePopup}
        height={mobilePopupBtnOuterHeight * 2 + mobilePopupPaddings * 2}
        width={320}
      >
        <Button
          id={"ApprovedOnly"}
          className={popupStyles.MobilePopupBtn}
          size={"large"}
          onClick={this.MobileDownloadResults}
        >
          Approved Only
        </Button>
        <Button
          id={"All"}
          className={popupStyles.MobilePopupBtn}
          size={"large"}
          onClick={this.MobileDownloadResults}
        >
          All
        </Button>
      </MobilePopup>
    );
  };

  renderPopupStatus = () => {
    let settings = this.state.popupStatus;
    if (!settings) return null;

    return (
      <MobileCLIStatuses
        settings={this.settings!}
        dataItem={settings.dataItem}
        currentFileId={settings.fileId || null}
        refreshList={this.Refresh}
        onHide={this.HidePopupStatus}
        onProcessing={(processing: boolean) =>
          this.setState({ popupProcessing: processing })
        }
        abort={() => {
          this.CancelPrevRequestUpdateToken();
          return this.abortController.signal;
        }}
      />
    );
  };

  renderPopupHistory = (popupItem: IDocumentItem) => {
    return (
      <MobilePopup
        onHide={this.HidePopup}
        height={"100vh"}
        width={"100%"}
        style={{ padding: 0 }}
      >
        <div
          style={{
            flex: 1,
            width: "100%",
            overflow: "auto",
            position: "relative",
            padding: 16,
          }}
        >
          <MobileCliHistory dataItem={popupItem} />
        </div>
        <Toolbar
          className={`${styles.MobileToolbar}`}
          style={{ width: "100%" }}
        >
          <div style={{ fontSize: 16 }}>History</div>
          <ToolbarSpacer />
          <Button
            title="Close History"
            icon="close"
            onClick={this.HidePopup}
            fillMode="flat"
          />
        </Toolbar>
      </MobilePopup>
    );
  };

  renderPopupCliComment = (popupItem: IDocumentItem) => {
    let commentRef: any = React.createRef();
    let oldValue = popupItem.comments || "";
    let disabled = !this.settings?.IsReviewer;
    return (
      <MobilePopup onHide={this.HidePopup} height={370} width={310}>
        <div style={{ marginBottom: 8, fontSize: 16, width: "100%" }}>
          CLI Comments
        </div>
        <div
          style={{
            flex: 1,
            overflow: "auto",
            width: "100%",
            position: "relative",
          }}
        >
          {disabled ? (
            oldValue
          ) : (
            <TextArea
              ref={(ref) => (commentRef = ref)}
              style={{ width: "100%", height: "100%" }}
              defaultValue={oldValue}
              readOnly={disabled}
              maxLength={4096}
            />
          )}
        </div>
        {!disabled && (
          <Button
            style={{ marginTop: 8 }}
            themeColor={"primary"}
            onClick={async () => {
              let value = commentRef.element.current.value;
              if (value !== oldValue && popupItem) {
                await this.UpdateCliComments(value, popupItem);
              }
              this.HidePopup();
            }}
          >
            Save
          </Button>
        )}
      </MobilePopup>
    );
  };

  renderPopupMyComments = (popupItem: IDocumentItem) => {
    let commentRef: any = React.createRef();
    let disabled = IsDisableEditStatus(popupItem, this.settings!);
    let comment = GetMyComment(popupItem, this.settings!);
    return (
      <MobilePopup onHide={this.HidePopup} height={370} width={310}>
        <div
          style={{
            marginBottom: 8,
            fontSize: 16,
            width: "100%",
          }}
        >
          {GetMyCommentsTitle(popupItem, this.settings!)}
        </div>
        <div style={{ flex: 1, overflow: "auto", width: "100%" }}>
          {disabled ? (
            comment
          ) : (
            <TextArea
              ref={(ref) => (commentRef = ref)}
              style={{ width: "100%", height: "100%" }}
              defaultValue={comment || ""}
              readOnly={disabled}
              maxLength={4096}
            />
          )}
        </div>
        {!disabled && (
          <Button
            style={{ marginTop: 8 }}
            themeColor={"primary"}
            onClick={async () => {
              try {
                this.setState({ popupProcessing: true });
                let value = commentRef.element.current.value;
                if (value !== comment && popupItem) {
                  this.CancelPrevRequestUpdateToken();
                  await SaveMyComment(
                    popupItem,
                    value,
                    this.settings!,
                    this.abortController.signal
                  );
                }
                this.HidePopup();
              } catch (e) {
                showSomeError(e);
              } finally {
                this.setState({ popupProcessing: false });
              }
            }}
          >
            Save
          </Button>
        )}
      </MobilePopup>
    );
  };

  renderPopupCommentForMe = (popupItem: IDocumentItem) => {
    return (
      <MobilePopup onHide={this.HidePopup} height={370} width={310}>
        <div style={{ marginBottom: 8, fontSize: 16, width: "100%" }}>
          Comments For Me
        </div>
        <div
          style={{
            flex: 1,
            overflow: "auto",
            width: "100%",
          }}
        >
          {GetCommentForMe(popupItem, this.settings!)}
        </div>
      </MobilePopup>
    );
  };

  OnChangeBuildPlan = (value: ICLPMBPItem | null) => {
    this.expandAll = false;
    this.ChangeFilterByWO(null);
    if (value) {
      this.bpId = +value.Id;
      this.selectedBP = value;
      settingsStorage.setForCurrentUser(
        CLPMSettings.CLM_BUILDPLAN_LS,
        JSON.stringify(value)
      );
      this.LoadData();
    } else {
      this.bpId = undefined;
      this.selectedBP = undefined;
      this.selectedWorkOrder = null;
      this.documents = [];
      if (this.props.isCLM)
        settingsStorage.removeForCurrentUser(CLPMSettings.CLM_BUILDPLAN_LS);
      this.setState({
        documents: { data: [], total: 0 },
        filteredWorkOrders: [],
        remountFilterKey: +new Date(),
        remountMobileListKey: +new Date(),
      });
    }
  };

  OnChangeHasMediaFilter = (value: IComboboxItem | null) => {
    this.selectedHasMedia = value;
    this.FinishChangeFilter(value, value?.Id, "HasMedia");
  };

  OnChangeDetailFilter = (value: IComboboxItem | null) => {
    this.selectedDetails = value;
    this.FinishChangeFilter(value, value?.Id, "FieldDetailId");
  };

  ChangeFilterByWO = (value: ICLPMWOItem | null) => {
    if (
      !value ||
      (this.selectedDocument && this.selectedDocument.woId !== value.Id)
    ) {
      this.selectedDocument = undefined;
      this.currentFileId = null;
    }
    this.selectedWorkOrder = value;
    this.woId = value ? +value.Id : undefined;
    this.LoadData();
  };

  OnChangeTypeFilter = (value: IComboboxItem | null) => {
    this.selectedType = value;
    const filterValue = value ? value.Id === "Documents" : undefined;
    this.FinishChangeFilter(value, filterValue, "docCl");
  };

  OnChangeStatus = (value: IComboboxItem | null) => {
    this.selectedStatus = value;
    this.FinishChangeFilter(value, value?.Id, "status");
  };

  FinishChangeFilter = (value: any, filterValue: any, field: string) => {
    let gridFilter = this.gridFilter;
    this.gridFilter.filters = gridFilter.filters.filter((f) =>
      !IsComplexGridFilter(f) && f.field === field ? false : true
    );
    if (filterValue !== undefined) {
      this.gridFilter.filters.push({
        field,
        value: filterValue,
        operator: "eq",
      });
    }

    if (this.selectedDocument) {
      let filtered = process([this.selectedDocument], {
        filter: this.gridFilter,
      });
      if (!filtered.data.length) {
        this.selectedDocument = undefined;
        this.currentFileId = null;
      }
    }

    this.setState({ ...this.GetGroupedGridData() });
  };

  SetDefaultFilters = () => {
    this.selectedWorkOrder = null;
    this.selectedStatus = null;
    this.selectedHasMedia = null;
    this.selectedDetails = null;
    this.selectedType = null;
    this.gridFilter = GetDefaultGridFilter();
    this.setState({
      ...this.GetGroupedGridData(),
      gridFilter: this.gridFilter,
      remountFilterKey: +new Date(),
    });
  };

  HidePopupActions = () => {
    this.setState({ popupActions: null });
  };

  HidePopup = () => {
    this.setState({ popup: null });
  };

  ShowPopupStatus = (dataItem: IDocumentItem, fileId?: number) => {
    this.setState({ popupStatus: { dataItem, fileId } });
  };

  HidePopupStatus = () => {
    this.setState({ popupStatus: null });
  };

  OpenMobilePopupDownload = () => {
    this.setState({ popup: "Download" });
  };

  OpenMobilePopupDownloadGroupItems = (groupId: number) => {
    this.setState({ popup: "Download_" + groupId });
  };

  OpenWOCheckListTemplateCard = () => {
    CardManagement.OpenWOChecklistCard({
      buildPlanId: this.bpId,
      workOrderId: this.woId,
      isMobile: true,
      onFinish: this.Refresh,
    });
  };

  OnSelectFile = (fileId: number) => {
    this.currentFileId = fileId;
  };

  CreateResultFromVersion = async () => {
    try {
      var fileId = this.currentFileId;
      if (!fileId) return;
      this.setState({ popupProcessing: true });
      this.HidePreviewDocument();
      this.HidePopupActions();
      let resultId = await CreateResultFromVersion(fileId);
      await this.LoadData();
      for (let item of this.documents) {
        item.Selected = item.resultId === resultId;
        if (item.Selected) this.selectedDocument = item;
      }
      this.setState({
        remountMobileListKey: +new Date(),
      });
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ popupProcessing: false });
    }
  };

  LoadData = async (mode?: "grid" | "background") => {
    if (this.bpId === undefined) {
      this.selectedBP = undefined;
      this.selectedWorkOrder = null;
      this.selectedDocument = undefined;
      this.selectedStatus = null;
      this.currentFileId = null;
      this.documents = [];
      this.setState({
        documents: { data: [], total: 0 },
        remountFilterKey: +new Date(),
        remountMobileListKey: +new Date(),
        popupPreview: null,
      });
      return;
    }
    let isFullUpdate = !mode;
    if (isFullUpdate) this.setState({ loading: true });
    try {
      let result: any = await this.GetSQLData({
        spName: "CLPM_GetChecklistsResults",
        params: {
          buildPlanId: this.bpId,
          workOrderId: this.woId,
        },
      });
      this.documents = result[0] || [];
      let files: Array<IFileItem> = result[1] || [];
      files.forEach((file) => {
        // @ts-ignore
        file.e = file.e?.toUpperCase() || null;
        file.name = `Version ${file.v}. Uploaded (${
          file.d ? moment(file.d).format("L") : ""
        }) `;
      });
      let buildPlans = this.state.buildPlans;
      this.selectedBP = buildPlans.find((item) => item.Id === this.bpId);

      let CLPMStatuses = await CLPMSettings.getCLPMStatusesLib();
      this.documents.forEach((item) => {
        if (this.selectedDocument && this.selectedDocument.id === item.id) {
          item.Selected = true;
          this.selectedDocument = item;
        }
        // @ts-ignore
        item.sampleExt = item.sampleExt?.toUpperCase() || null;
        item.files = files.filter((f) => f.resId === item.resultId);

        item.HasMedia = item.files.length ? "Yes" : "No";
        item.FieldDetailId = item.FieldDetail || "null";
        // @ts-ignore
        item.statusName = CLPMStatuses[item.status];
      });

      let groupedGridData = this.GetGroupedGridData();
      let { next, prev } = this.GetResultSiblings(
        this.selectedDocument,
        groupedGridData.documents
      );
      this.nextDocument = next;
      this.prevDocument = prev;

      this.setState({
        ...groupedGridData,
        ...this.GetDisabledArrows(),
        remountFilterKey: +new Date(),
      });
    } catch (e) {
      showSomeError(e);
    } finally {
      if (isFullUpdate) this.setState({ loading: false });
    }
  };

  GetSetFilteredWorkOrders = async () => {
    const workOrders = await CLPMSettings.getActiveWorkOrders();
    const filteredWorkOrders = this.bpId
      ? workOrders.filter((wo) => wo.BuildPlanId === this.bpId)
      : workOrders;
    this.selectedWorkOrder = this.woId
      ? filteredWorkOrders.find((wo) => wo.Id === this.woId) || null
      : null;
    this.woId = this.selectedWorkOrder ? +this.selectedWorkOrder.Id : undefined;
    this.setState({ filteredWorkOrders, remountFilterKey: +new Date() });
  };

  LoadClmBuildPlans = async () => {
    this.setState({ bploading: true });
    try {
      const { buildPlans, workOrders } =
        await CLPMSettings.getActiveBuildPlansAndWorkOrders();
      this.selectedBP = CLPMSettings.getSelectedBP(this.bpId) || undefined;
      this.bpId = this.selectedBP && +this.selectedBP.Id;
      const filteredWorkOrders = this.bpId
        ? workOrders.filter((wo) => wo.BuildPlanId === this.bpId)
        : workOrders;
      if (this.woId) {
        const wo = filteredWorkOrders.find((wo) => wo.Id === this.woId);
        this.selectedWorkOrder = wo || null;
        this.woId = wo ? +wo.Id : undefined;
      }
      this.setState({
        buildPlans,
        filteredWorkOrders,
        remountFilterKey: +new Date(),
      });
      if (this.bpId) this.LoadData();
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ bploading: false });
    }
  };

  SendCliIncluded = async (queryParams: IUpdateIncludedParams) => {
    try {
      this.setState({ popupProcessing: true });
      await SendCliIncluded(queryParams);
      this.Refresh();
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ popupProcessing: false });
    }
  };

  OnMobileShowActionsFromPreview = (
    dataItem: IDocumentItem,
    fileId?: number
  ) => {
    this.setState({
      popupActions: {
        dataItem,
        fromPreview: true,
        fileId,
      },
    });
  };

  OnItemClick = (dataItem: IDocumentItem) => {
    try {
      for (let item of this.documents) {
        item.Selected = dataItem ? item.id === dataItem.id : false;
        if (item.Selected) this.selectedDocument = dataItem;
      }

      let { next, prev } = this.GetResultSiblings(
        dataItem,
        this.state.documents
      );
      this.prevDocument = prev;
      this.nextDocument = next;

      this.setState({
        popupActions: { dataItem },
        ...this.GetDisabledArrows(),
        remountMobileListKey: +new Date(),
      });
    } finally {
    }
  };

  OnNavResults = (e: any) => {
    let selected = this.selectedDocument;
    if (!selected) return;
    let action: "next" | "prev" = e.currentTarget.dataset.action;

    this.NavResults(action);
  };

  NavResults = (action: "next" | "prev") => {
    let selected = this.state.popupPreview;
    if (!selected) return;

    let newSelected = action === "next" ? this.nextDocument : this.prevDocument;
    if (!newSelected) return;
    let newSelectedGroupName = newSelected.groupName;
    if (newSelectedGroupName)
      this.mobileExpandedSettings[newSelectedGroupName] = true;

    selected.Selected = false;
    this.selectedDocument = newSelected;
    this.selectedDocument.Selected = true;

    let { prev, next } = this.GetResultSiblings(
      newSelected,
      this.state.documents
    );
    this.nextDocument = next;
    this.prevDocument = prev;
    this.setState({
      disableNext: !next,
      disablePrev: !prev,
      remountMobileListKey: +new Date(),
      popupPreview: newSelected || null,
    });
  };

  GetDisabledArrows = () => {
    return {
      disableNext: !this.nextDocument,
      disablePrev: !this.prevDocument,
    };
  };

  GetResultSiblings = (
    selectedItem: IDocumentItem | undefined,
    documents: DataResult
  ) => {
    let selected = selectedItem;
    let next: IDocumentItem | undefined = undefined;
    let prev: IDocumentItem | undefined = undefined;

    if (!selected) return { next, prev };

    let selectedGroupName = selected.groupName;
    if (selectedGroupName && selectedGroupName.trim)
      selectedGroupName = selectedGroupName.trim();
    let groupedData = documents.data;
    for (let i = 0; i < groupedData.length; i++) {
      let group = groupedData[i];
      let groupName = group.value;
      if (groupName && groupName.trim) groupName = groupName.trim();
      if (groupName === selectedGroupName) {
        for (let j = 0; j < group.items.length; j++) {
          let item = group.items[j];
          if (item.id === selected.id) {
            let nextGroup = groupedData[i + 1];
            next = group.items[j + 1] || nextGroup?.items[0];
            let prevGroup = groupedData[i - 1];
            prev =
              group.items[j - 1] ||
              prevGroup?.items[prevGroup.items.length - 1];
            break;
          }
        }
      }
    }

    return { next, prev };
  };

  GetGroupedGridData = () => {
    let documents = process(this.documents, {
      group: this.group,
      filter: this.gridFilter,
    });
    documents.data.sort((ag, bg) => {
      let aGroupId = ag.items[0].groupId;
      let bGroupId = bg.items[0].groupId;
      return bGroupId - aGroupId;
    });
    let remountMobileListKey = +new Date();
    return { remountMobileListKey, documents };
  };

  ToggleExpandGroup = () => {
    this.expandAll = !this.expandAll;
    for (let id in this.mobileExpandedSettings) {
      this.mobileExpandedSettings[id] = this.expandAll;
    }
    if (!this.expandAll) this.setState({ collapsedMobileKey: +new Date() });
    else this.setState({ remountMobileListKey: +new Date() });
  };

  AutoRefresh = () => {
    if (this.props.isActive) this.BackgroundRefresh();
  };

  BackgroundRefresh = () => {
    this.LoadData("background");
  };

  Refresh = () => {
    this.LoadData();
  };

  DoAction = async (action: string, dataItem: IDocumentItem) => {
    try {
      if (action === "ShowDocument") {
        this.ShowPreviewDocument(dataItem);
      } else if (action === "ShowHistory") {
        this.setState({ popup: "History" });
      } else if (action === "Include" || action === "Exclude") {
        this.SendCliIncluded({
          Included: action === "Include",
          CLID: dataItem.clId,
          CLIID: dataItem.cliId,
        });
      } else if (action === "EditCLIComments") {
        this.setState({ popup: "CliComment" });
      } else if (action === "CommentsForMe") {
        this.setState({ popup: "CommentForMe" });
      } else if (action === "MyComments") {
        this.setState({ popup: "MyComments" });
      } else if (action === "ResultFromVersion") {
        this.CreateResultFromVersion();
      } else {
        let accept = "";
        if (action === "UploadResult" || action === "UploadVersion") {
          accept = dataItem.docCl
            ? ".doc,.docx,.xlsx,.xls,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            : "image/*,video/*";
        }
        const downLoadUrl = dataItem.sampleDocumentId
          ? await GetDocumentUrl(dataItem.sampleDocumentId, false)
          : "";
        switch (action) {
          case "DownloadSample":
            if (downLoadUrl) document.location.href = downLoadUrl;
            return;

          case "DeleteResult":
            this.OnDeleteResult(dataItem);
            return;

          case "UploadResult":
            await this.UploadResult(dataItem, accept!);
            return;

          case "UploadVersion":
            this.UploadNewVersion(dataItem, accept);
            return;

          /* case 'EditCLIComments':
                                this.OpenCliCommentEdit(dataItem)
                                return; */
        }

        this.HidePopupActions();
      }
    } catch (e) {
      showSomeError(e);
    }
  };

  ShowPreviewDocument = (dataItem: IDocumentItem) => {
    this.setState({
      popupPreview: dataItem,
      popupActions: null,
      remountMobilePreviewKey: +new Date(),
    });
  };

  HidePreviewDocument = () => {
    this.setState({ popupPreview: null });
  };

  UpdateCliComments = async (value: string, dataItem: IDocumentItem) => {
    try {
      this.setState({ popupProcessing: true });
      await RunScriptAsync("CLI_UpdateComments", {
        CLID: dataItem.clId,
        CLIID: dataItem.cliId,
        Comments: value,
      });
      dataItem.comments = value;
      this.Refresh();
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ popupProcessing: false });
    }
  };

  UploadNewVersion = (dataItem: IDocumentItem, accept: string) => {
    window.$("#uploadFile").remove();
    window
      .$("body")
      .append(
        '<input id="uploadFile" name="uploadFile" type="file" data-resultid="' +
          dataItem.resultId! +
          '" style="display:none;" accept="' +
          (accept || "") +
          '"/>'
      );
    const input = window.$("#uploadFile");
    input.on("change", async () => {
      try {
        const selectedFile = input[0].files[0];
        const resultId = window.$("#uploadFile").data("resultid");
        if (resultId) {
          this.setState({ popupProcessing: true });
          const documentId = await api.script.woCreateChecklistResultFile({
            ChecklistResultId: +resultId,
          });
          await UpdateDocument(+documentId!, selectedFile);
          this.setState({ popupProcessing: false });
          await this.LoadData();
          this.ShowPreviewDocument(this.selectedDocument!);
        }
      } catch (e) {
        showSomeError(e);
      }
    });
    input.trigger("click");
  };

  UploadResult = (dataItem: IDocumentItem, accept: string) => {
    const { clId, cliId, dsId } = dataItem;
    const $ = window.$;
    $("#uploadFile").remove();
    $("body").append(
      '<input id="uploadFile" name="uploadFile" type="file" data-checklistid="' +
        clId +
        '" data-dispatchid="' +
        (dsId || "") +
        '" data-itemid="' +
        cliId +
        '" style="display:none;" accept="' +
        (accept || "") +
        '"/>'
    );
    const input = $("#uploadFile");
    input.on("change", async () => {
      try {
        this.setState({ popupProcessing: true });
        var selectedFile = input[0].files[0];
        const checklistId = $("#uploadFile").data("checklistid");
        const itemId = $("#uploadFile").data("itemid");
        const dispatchId = $("#uploadFile").data("dispatchid");
        const resultId = await api.script.woCreateChecklistResult({
          ChecklistItemId: +itemId,
          DispatchId: dispatchId ? +dispatchId : undefined,
          ChecklistId: +checklistId,
        });
        const documentId = await api.script.woCreateChecklistResultFile({
          ChecklistResultId: +resultId!,
        });
        await UpdateDocument(+documentId!, selectedFile);
        this.setState({ popupProcessing: false });
        await this.LoadData();
        this.ShowPreviewDocument(this.selectedDocument!);
        return resultId;
      } catch (e) {
        showSomeError(e);
      }
    });
    input.trigger("click");
  };

  OnDeleteResult = async (dataItem: IDocumentItem) => {
    await new Promise((resolve, reject) => {
      ModalRef.showDialog({
        title: "Confirmation",
        text:
          "Are you sure that you want to delete result? Versions count to be deleted: " +
          dataItem.files.length,
        buttons: [
          {
            text: "Cancel",
            action: () => {
              resolve(1);
              ModalRef.hideDialog();
            },
          },
          {
            text: "Ok",
            color: "primary",
            action: async () => {
              try {
                let resultId = dataItem.resultId!;
                dataItem.resultId = null;
                await this.DeleteResult(resultId);
                this.HidePopupActions();
                this.HidePreviewDocument();
                resolve(1);
                ModalRef.hideDialog();
              } catch (e) {
                showSomeError(e);
                reject(e);
              }
            },
          },
        ],
      });
    });
  };

  DeleteResult = async (resultId: number) => {
    try {
      this.setState({ popupProcessing: true });
      await RunScriptAsync("CLPM_DeleteResult", { ID: resultId });
      this.Refresh();
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ popupProcessing: false });
    }
  };

  MobileDownloadResults = (e: any) => {
    const groupId = this.state.popup?.split("_")[1];
    if (groupId) this.DownloadGroupResults(e.currentTarget.id, +groupId);
    else this.DownloadResults(e.currentTarget.id);
  };

  DownloadGroupResults = (action: downloadCLResultsAction, clId: number) => {
    try {
      const includeAll = action === "All";
      const docIds: number[] = [];
      let zipName = "";
      for (let group of this.state.documents.data) {
        if (group.items[0].clId === clId) {
          zipName = group.items[0].groupName.replace(/\//g, ".");
          for (let item of group.items) {
            for (let file of item.files) {
              if (includeAll || file.id === item.approvedFileId) {
                docIds.push(file.docId);
              }
            }
          }
          break;
        }
      }

      this.DownloadResultsAction(zipName, docIds);
    } catch (e) {
      showSomeError(e);
    }
  };

  DownloadResults = async (action: downloadCLResultsAction) => {
    try {
      let includeAll = action === "All";
      let buildPlanId = this.bpId;
      if (!buildPlanId) return false;
      const docIds: number[] = [];
      for (let group of this.state.documents.data) {
        for (let item of group.items) {
          for (let file of item.files) {
            if (includeAll || file.id === item.approvedFileId) {
              docIds.push(file.docId);
            }
          }
        }
      }

      this.DownloadResultsAction("ReviewInterface", docIds);
    } catch (e) {
      showSomeError(e);
    }
  };

  DownloadResultsAction = async (zipFileName: string, docIds: number[]) => {
    if (!docIds.length) {
      ModalRef.showDialog({ text: "No files to download", type: "info" });
      return;
    }
    const url = await api.documents.getMultiDocZipUrl(
      {
        zipFileName,
      },
      docIds
    );
    const downloadUrl = config.API_URL.replace(/\/+$/, "") + url;
    window.open(downloadUrl, zipFileName);
  };

  OnMobileExpandChange = (id: string) => {
    this.mobileExpandedSettings[id] = !this.mobileExpandedSettings[id];
    let isExpandedAll = true;
    for (let id in this.mobileExpandedSettings) {
      if (isExpandedAll) isExpandedAll = this.mobileExpandedSettings[id];
    }
    if (this.expandAll !== isExpandedAll) {
      this.expandAll = isExpandedAll;
      // this.forceUpdate()
    }
  };

  OpenBPCard = () => {
    if (this.bpId) CardManagement.OpenBPCard(this.bpId);
  };

  OnResize = () => {
    if (this.initialHeight > window.innerHeight) {
      this.heightWasChanged = true;
    } else if (this.heightWasChanged) {
      this.heightWasChanged = false;
      this.setState({ remountMobileListKey: +new Date() });
    }
  };
}

export default MobileChecklistResults;
