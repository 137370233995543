import { IComboboxItem } from "../../../helpers/interfaces";

export type objectType = "Site" | "Location";

export interface IEditableSiteInfo {
  isCustomName: boolean;
  siteId: string;
  siteName: string;
  jurisdiction: string;
  siteType: IComboboxItem | null;
  market: IComboboxItem | null;
  region: IComboboxItem | null;
  responsibleName: string;
  carrier: IComboboxItem | null;
  address: IAddressInfo | null;
  directionsFromLocalOffice: string;
  specialAccessInstruction: string;
  notes: string;
}

export interface IAddressInfo {
  County?: string;
  City?: string;
  ZIP?: string;
  LineOne?: string;
  LineTwo?: string;
  Country?: string;
  State?: string;
  Lat: number;
  Lng: number;
  LocationAbbr: string | null;
  LocationColor: string | null;
  Radius?: number;
  Boundaries?: string | null;
  AddressId: number;
  AddressString: string;
  IsMainObject: boolean;
  ObjectId: number;
  ObjectName: string;
  ObjectType: "Location" | "Site";
}

export enum EFigureType {
  circle,
  polygon,
}
