import Loader from "../../Common/Loader";
import TCInfo from "./TCInfo";
import TCNonWages from "./TCNonWages";
import styles from "./timecard.module.scss";
import TCHistory from "./TCHistory";
import React, { useEffect, useRef } from "react";
import {
  useLatestNotEmptyValue,
  useLazyAsync,
} from "../../../core/tools/Hooks";
import api from "../../../core/api/api";
import TCDetailsGrid from "../DetailsGrid";
import { TRenderToolbar } from "../../TabsNew/interfaces";

const TCAllocation = (props: {
  tcId?: number;
  isActive: boolean;
  pageId?: string;
  renderToolbar?: TRenderToolbar;
  reloadDependency?: unknown;
  refreshTC: () => void;
}) => {
  const { tcId, isActive, renderToolbar, reloadDependency, refreshTC } = props;
  const [loadMethod, loadState] = useLazyAsync(api.sql.dbTkGetTcSummary);
  const currentTcId = useLatestNotEmptyValue(tcId);
  const lastAppliedDependency = useRef<unknown>();
  useEffect(() => {
    if (isActive && lastAppliedDependency.current !== reloadDependency) {
      lastAppliedDependency.current = reloadDependency;
      loadMethod({ TCId: currentTcId });
    }
  }, [loadMethod, reloadDependency, isActive]);

  const renderContent = () => {
    if (loadState.loading) return <Loader />;
    if (!currentTcId || !loadState.data) return null;

    const { timeCardInfo, defaultWOs, history, nonWageSummary, details } =
      loadState.data[0];
    return (
      <>
        <TCInfo
          tcId={currentTcId}
          info={timeCardInfo[0]}
          defaultWOs={defaultWOs}
          refreshTC={refreshTC}
        />
        <div
          style={{
            flex: 1,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ flex: 1 }}>
            <TCDetailsGrid data={details} />
          </div>
          {!!nonWageSummary.length && <TCNonWages data={nonWageSummary} />}
          {!!History.length && <TCHistory data={history} />}
        </div>
      </>
    );
  };

  return (
    <>
      {renderToolbar?.({})}
      <div
        className={styles.TCContainer}
        style={{ overflow: "auto", position: "relative" }}
      >
        {renderContent()}
      </div>
    </>
  );
};

export default TCAllocation;
